import React, { useContext, useEffect, useState } from 'react';
import { Button, Popover } from 'antd';

import flechaWhiteSvg from 'assets/images/flecha-white.png';
import flechadarckSvg from 'assets/images/flecha-darck.png';
import flechaGreenSvg from 'assets/images/arrow-green.png';

import homeWhiteEmptySvg from 'assets/images/home-white-empty.svg';
import homeBlackEmptySvg from 'assets/images/home-black-empty.svg';
import homeGreenSvg from 'assets/images/home-green-all.svg';
import homeErrorEmptySvg from 'assets/images/home-error-empty.svg';
import homeReviewEmptySvg from 'assets/images/home-review-empty.svg';

import { Loan, TaskState } from 'gql/graphql';
import { LoanContext } from '../LoanDetails';
import { LOAN_PHASES, LoanPhases, Phases } from '../LoanPhase.constanst';

interface ProgressProps {
  loan: any;
  rulesTasksCards: any[];
  phaseSelect: string;
  handlerSetPhaseSelect: any;
  handlerSetTaskOpenModal: any;
}

const Progress = ({
  loan,
  rulesTasksCards,
  handlerSetPhaseSelect,
  handlerSetTaskOpenModal,
}: ProgressProps) => {

  //const { value: contextHighlight, setHandler: setHighlights }: any = useContext(HighlightsActiveContext);
  //const { value: isCreateTaskResponse }: any = useContext(CreateTaskResponseContext);

  const getLoanPhases = () => {
    let phases: any = JSON.parse(JSON.stringify(LOAN_PHASES));

    if (!rulesTasksCards)
      return [];

    for (const _phase of phases) {
      
      const _rulesTaskCards = rulesTasksCards
      .filter(item => item.phaseProgress === _phase.key as Phases &&
        (!item.loanType || item.loanType?.includes(loan?.type)) &&
        (!item.loanPurposes || item.loanPurposes?.includes(loan?.purpose))
      );

      const isCompleted = _rulesTaskCards.length > 0 &&
        _rulesTaskCards.every(item => item.state === TaskState.Approved);
      const isReview = _rulesTaskCards.length > 0 &&
        _rulesTaskCards.every(item => (item.state === TaskState.Review || item.state === TaskState.Approved));
      const isError = _rulesTaskCards.find(item => item.state === TaskState.RequestError);
      const isStarted = _rulesTaskCards.find(item => item.state !== TaskState.Draft);

      if (_phase.key === Phases.PRE_APPROVAL) {
        _phase.state = 'complete';
        _phase.stateName = 'COMPLETE';
      }
      else {
        _phase.state = isCompleted ? 'complete' : isReview ? 'review' : isError ? 'error' : isStarted ? 'started' : 'not-started'
        _phase.stateName = isCompleted ? 'COMPLETE' : isReview ? 'IN REVIEW' : isError ? 'ERROR' : isStarted ? 'IN PROGRESS' : 'NOT STARTERD'
      }

      //console.log(_phase.key, _phase.state, rulesTasksCards);

      rulesTasksCards.forEach((taskCard, index) => {
        if (
          taskCard.phaseProgress === _phase.key as Phases &&
          (!taskCard.loanType || taskCard.loanType?.includes(loan.type)) &&
          (!taskCard.loanPurposes || taskCard.loanPurposes?.includes(loan?.purpose))
        ) {
          const buttonState = taskCard.progressButtons && taskCard.progressButtons[taskCard.state];

          _phase.tasksItems.push({
            id: index,
            key: taskCard.key,
            modalKey: taskCard.modalKey,
            icon: taskCard.state === TaskState.Approved ? homeGreenSvg :
              taskCard.state === TaskState.RequestError ? homeErrorEmptySvg :
                taskCard.state === TaskState.Review ? homeReviewEmptySvg :
                  _phase.state === 'not-started' ? homeBlackEmptySvg : homeWhiteEmptySvg,
            text: taskCard.name + (buttonState ? ' ' + buttonState.state : ''),
            titleButton: (buttonState ? buttonState.label : 'View'),
            state: taskCard.state
          });
        }
      })
    }

    return phases;
  }
  
  /*const isBorderHighlight = (phase: LoanPhases) => {
    if(!contextHighlight) return false;

    if (contextHighlight.phase === phase.key)
      return true;
    
    for (const taskHighlight of contextHighlight.tasks) {
      const taskCard = rulesTasksCards.find(task => task.key === taskHighlight.key);
      if (taskCard.phase.includes(phase.key))
        return true;
    }
  }*/

  const onClickPhaseProgress = (phase: LoanPhases) => {
    if (phase.key === Phases.APPROVAL || phase.key === Phases.DUE_DILIGENCE) {
      handlerSetPhaseSelect(phase.key);

      /*if (contextHighlight) {
        //Cambiar en tambien TabBarProgress
        const tasksHighlights = phase.tasksItems.filter(item =>
          item.state === TaskState.Incomplete ||
          item.state === TaskState.Draft ||
          item.state === TaskState.RequestError
        )
        setHighlights({ phase: phase.key, tasks: tasksHighlights });
      }*/
    }
  }

  const phases = getLoanPhases();

  /*
  useEffect(() => {
    handlerSetProgressData(
      phases.map((item: any) => ({
        key: item.key,
        state: item.state,
        stateName: item.stateName,
      })).filter((item: any) => item.key !== 'pre-approval')
    );
  }, [isCreateTaskResponse])
  */

  //className={`tab tab-${tab.state} ${isBorderHighlight(tab) && 'tab-highlight'}`} 
  
  return (
    <section className="Progress">
      {phases?.map((tab: any) => (
        <div 
          className={`tab tab-${tab.state}`} 
          key={tab.key} 
          onClick={() => onClickPhaseProgress(tab)}
        >
          <TabBarProgress
            tab={tab}
            handlerSetTaskOpenModal={handlerSetTaskOpenModal}
            handlerSetFilter={() => {}}
          />
        </div>
      ))}
    </section>
  );
};

interface TabBarProgressProps {
  tab: LoanPhases;
  handlerSetTaskOpenModal: any;
  handlerSetFilter: any;
}

const TabBarProgress = ({ tab, handlerSetTaskOpenModal, handlerSetFilter }: TabBarProgressProps) => {

  const loan: Loan | any = useContext(LoanContext);
  //const { value: contextHighlight, setHandler }: any = useContext(HighlightsActiveContext);

  const [visible, setVisible] = useState<boolean>();
  //const [isHoverHighlights, setHoverHighlights] = useState<boolean>();

  /*useEffect(() => {
    if (!visible)
      setHandler(undefined);
  }, [visible])*/

  const onClickViewTask = (item: any) => {
    if (item.key === "pre_approval_letter") {
      window.open(loan?.onboarding?.preApprovalLetterUrl, '_blank');
    } else {
      handlerSetTaskOpenModal(item.modalKey);
      setVisible(false);
    }
  }

  /*const isHighlights = () => {
    return !!tab.tasksItems.find(item =>
      item.state === TaskState.Incomplete ||
      item.state === TaskState.Draft ||
      item.state === TaskState.RequestError
    )
  }

  const onClickHighlights = () => {
    //Cambiar en tambien en el componente padre
    const tasksHighlights = tab.tasksItems.filter(item =>
      item.state === TaskState.Incomplete ||
      item.state === TaskState.Draft ||
      item.state === TaskState.RequestError
    )
    handlerSetFilter('all');
    setHandler({ phase: tab.key, tasks: tasksHighlights });
  }*/

  return (
    <Popover
      trigger="click"
      open={visible}
      onOpenChange={setVisible}
      placement="bottom"
      content={
        <div className={`popover-content popover-content-${tab.state}`}>
          <p className="body-small body-small-w6">{tab.stateName}</p>
          <p className="body-small body-small-w4">{tab.text}</p>
          <div className="divider" />
          {tab?.tasksItems?.map((item: any) => (
            <div
              key={item.id}
              className={`felx-between ${item.state === TaskState.Approved && 'green-all'}`}
            >
              <div className="flex">
                <img src={item.icon} alt="home" className="home" />
                <span className={`body-small body-small-w4 label-${item.state}`}>
                  {item.text}
                </span>
              </div>
              <Button
                className="button button-transparent body-small"
                onClick={() => onClickViewTask(item)}
              >
                <span>{item.titleButton}</span>
                {item.state === TaskState.Approved ? (
                  <img src={flechaGreenSvg} className="flecha" alt="arrow-green" />
                ) : (
                  <img
                    src={tab.state === 'not-started' ? flechadarckSvg : flechaWhiteSvg}
                    className="flecha"
                    alt="arrow-default"
                  />
                )}
              </Button>
            </div>
          ))}

          {/*isHighlights() &&
            <div className={`warning ${contextHighlight && 'warning-active'}`}>
              <svg>
                <g>
                  <path d="M5.00016 9.1686C7.30135 9.1686 9.16683 7.30312 9.16683 5.00193C9.16683 2.70075 7.30135 0.835266 5.00016 0.835266C2.69898 0.835266 0.833496 2.70075 0.833496 5.00193C0.833496 7.30312 2.69898 9.1686 5.00016 9.1686Z" />
                  <path d="M5 3.33527V5.00193" />
                  <path
                    d="M5 6.66864H5.004"
                    strokeWidth="0.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <span
                className="body-small body-small-w6"
                onClick={onClickHighlights}
              >
                {isHoverHighlights ? 'Tasks highlighted' : 'Highlight tasks that are blocking us'}
              </span>
            </div>
        */}
        </div>
      }
    >
      <div className='popover-label'>
        <p className="body-small body-small-w4">{tab.name}</p>
        <div className="bar-status" />
      </div>
    </Popover>
  );
};

export default Progress;
