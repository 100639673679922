import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import iconCortPng from 'assets/images/cort.png';
import Step2 from './Step2/Step2';
import { Task, TaskState } from '../../../gql/graphql';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import TaskProgress from '../components/Atoms/Progress/TaskProgress/TaskProgress';
import { ExpandIcon } from '../BorrowerTasks';
import StateTag from '../components/Atoms/Tags/StateTag/StateTag';
import { useGetTaskResponsesByTask } from '../../../services/task/querys';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';

export const TaskContext = createContext<Task | {}>({});

interface MinimumLiquidityProps {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}

const MinimumLiquidity = ({ task, visible, handlerClose }: MinimumLiquidityProps) => {
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [step, setStep] = useState(1);
  const [totalBalance, setTotalBalance] = useState<any>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  
  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (taskKey === 'minimum_liquidity' || taskKey === 'minimium_liquidity') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if(!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);

  useEffect(() => {
    const responsesStep2 = taskResponses?.filter((item) => item.step === 2);
    setIsReview(!!responsesStep2?.some((item: any) => item.state === TaskState.Review));
  }, []);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  if (!task) return null;

  return (
    <TaskContext.Provider value={task}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        header={{
          title: <span>PREAPPROVAL | MINIMUM LIQUIDITY</span>,
          actionLeft: 
            isReview? <StateTag state={TaskState.Review} size="large"/>: <img src={iconCortPng} alt="icon" className="paperPng" />,
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        dots={true}
        stepsTotal={1}
        currentStep={step}
        setStep={setStep}
        footer={
          <TaskProgress
            title={<h4 className="h4 h4-w4">Minimum liquidity</h4>}
            price={<h4 className="body-card body-card-w5">$2MM</h4>}
            totalBalance={2000000}
            balances={totalBalance}
            colorBackground="#DFE4EB)"
          />
        }
      >
        <div className="children MinimumLiquidity">
            <Step2
              totalBalance={totalBalance}
              limitBalance={2000000}
              handlerCloseModalTask={setModalTaskVisible}
              handlerSetTotalBalance={setTotalBalance}
            />
        </div>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default MinimumLiquidity;
