import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useLogin } from '../../../../services/auth/mutations';

const FormLogin = () => {
  const { data, fetching, error, execute: login } = useLogin();

  useEffect(() => {
    if (data) {
      localStorage.setItem('token', data.login.authToken);
      window.dispatchEvent(new Event('storage'));
      //authToken
      //user
    }
  }, [data]);

  const onFinish = (values: any) => {
    login({ loginUserInput: values });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form"
      autoComplete="off"
    >
      <Form.Item
        name="email"
        className="form-item"
        rules={[
          {
            required: true,
            message: 'Please input your username!'
          }
        ]}
      >
        <Input placeholder="Email address" className="form-item__input" />
      </Form.Item>

      <Form.Item
        name="password"
        className="form-item"
        rules={[
          {
            required: true,
            message: 'Please input your password!'
          }
        ]}
      >
        <Input.Password placeholder="Password" className="form-item__input" />
      </Form.Item>
      <Link className="body-regular link" to="./reset-password">
        Forgot password? <span className="body-regular-bold">Reset it</span>
      </Link>

      <div className="error-message">
        {error?.message === 'Unauthorized' &&
          'Invalid username or password. Please check your credentials and try again.'}
      </div>

      <Form.Item>
        <Button
          className="button button-primary button-txt-big"
          loading={fetching}
          htmlType="submit"
        >
          Sign in
        </Button>
      </Form.Item>
    </Form>
  );
};
export default FormLogin;
