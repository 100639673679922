import { Button } from 'antd';
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';

interface InfoExpandProps {
  title: string;
  text: React.ReactElement;
}
const InfoExpand = ({ title, text }: InfoExpandProps) => {
  const [isTextActive, setTextActive] = useState(false);

  return (
    <section>
      <Button
        className="button button-empty"
        onClick={() => setTextActive(!isTextActive)}
      >
        {title}
        <DownOutlined />
      </Button>
      {isTextActive && text}
    </section>
  );
};
export default InfoExpand;
