import { gql } from "urql";

export const MUTATION_CREATE_NEW_TASK: any = gql(`
    mutation CreateNewTask($createNewTaskInput: CreateNewTaskInput!) {
        CreateNewTask(createNewTaskInput: $createNewTaskInput) {
            _id
            name
        }
    }
`)

export const MUTATION_CREATE_LOAN_DOC: any = gql(`
    mutation CreateLoanDoc($createLoanDocInput: CreateLoanDocInput!) {
        CreateLoanDoc(createLoanDocInput: $createLoanDocInput) {
            _id
            name
        }
    }
`)

export const MUTATION_DELETE_FILE_LOAN_DOC: any = gql(`
    mutation DeleteFileLoanDoc($loanDocId: String!, $fileKey: String!) {
        DeleteFileLoanDoc(loanDocId: $loanDocId, fileKey: $fileKey) {
            _id
        }
    }
`)