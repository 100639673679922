import React, { useContext, useEffect, useState } from 'react';
import lienzoSvg from 'assets/images/guarantor-blue.svg';
import { Button, DatePicker, Divider, Form, Input, Space } from 'antd';
import {
  CheckOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';
import { File, Task, TaskState } from 'gql/graphql';
import {
  useCreateResponseTask,
  useRemoveTaskResponse
} from 'services/task/mutations';
import ModalCompleteProfile from './ModalComplete';
import ModalFinishTask from './ModalFinishTask';
import FormEdit from './FormEdit';
import { useGetTaskResponsesByTask } from '../../../../services/task/querys';
import StateTag from '../../components/Atoms/Tags/StateTag/StateTag';
import InputBorderNone from '../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import ActionAcceptAndReject from '../../../ActionAcceptAndReject/ActionAcceptAndReject';
import { TaskContext } from '../EntityGuarantorCreation';

export interface Ownership {
  key: string;
  name: string;
  lastname: string;
  ownership: number;
  dateBirth: string;
  email: string;
  phone: string;
  ssn: string;
  address: string;
  state: string;
  files: File[];
  taskResponse: any;
}

interface Props {
  ownership: Ownership;
  openEdit?: any;
  ownerEdits?: any[];
  hiddenSectionViewProfile?: boolean;
  hiddenGuarantorTag?: boolean;
  hiddenTextEdit?: boolean;
  disabledForm?: boolean;
  hiddenSubmitChanges?: boolean;
  showTagState?: boolean;
  hiddenColorsState?: boolean;
  disabledPercentage?: boolean;
  createGuarantorState?: TaskState;
  isModalSubmit?: boolean;
  ownerships?: Ownership[];
  closeTask?: Function;
  isActionRemoved?: boolean;
}

const CardOwnership = ({
  ownership,
  openEdit,
  ownerEdits,
  hiddenSectionViewProfile,
  hiddenGuarantorTag,
  hiddenTextEdit,
  disabledForm,
  hiddenSubmitChanges,
  showTagState,
  hiddenColorsState,
  createGuarantorState = TaskState.Draft,
  isModalSubmit,
  ownerships,
  closeTask,
  disabledPercentage,
  isActionRemoved
}: Props) => {
  const task: Task | any = useContext(TaskContext);
  const [form] = Form.useForm();
  const {
    data,
    fetching,
    error,
    execute: createResponseTask
  } = useCreateResponseTask();
  const {
    data: responseRemove,
    fetching: loadingRemove,
    execute: removeTaskResponse
  } = useRemoveTaskResponse();
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { taskId: ownership.taskResponse.task._id }
    },
    pause: true
  });

  const [modalComplete, setModalComplete] = useState<boolean>(false);
  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [saveEdit, setSaveEdit] = useState<boolean>();
  const [percentageOwner, setPercentageOwner] = useState<number>();

  useEffect(() => {
    if (responseRemove)
      getTaskResponses({
        requestPolicy: 'network-only',
        variables: { taskId: ownership.taskResponse.task._id }
      });
  }, [responseRemove]);

  useEffect(() => {
    if (ownership?.ownership) setPercentageOwner(ownership.ownership);
  }, [ownership]);

  useEffect(() => form.resetFields(), [ownership]);

  useEffect(() => {
    if (data) {
      setSaveEdit(true);
      const isDrafts = ownerships?.filter(
        (item) => item.state === TaskState.Draft
      );
      //El ownerships esta atrasado en 1, entonces cuando hay 1 draft, entonces ya todos estan revisados
      const isFinish = isDrafts?.length === 1;

      if (ownership.state !== TaskState.RequestError) {
        if (isModalSubmit && isFinish) setModalFinishTask(true);
        if (isModalSubmit && !isFinish) setModalComplete(true);
      }
    }
  }, [data]);

  const changeEdit = () => {
    setSaveEdit(false);
    openEdit();
  };

  const saveChanges = (values: any, files: any, type: string) => {
    const {
      files: ignoreFile,
      task,
      __typename,
      createdAt,
      ...taskResponse
    } = ownership.taskResponse;

    let changeState = taskResponse.state;
    
    if (
      changeState === TaskState.Incomplete &&
      (values.address || ownership.address) &&
      (values.dateBirth || ownership.dateBirth) &&
      (values.email || ownership.email) &&
      (values.phone || ownership.phone) &&
      (values.ssn || ownership.ssn) &&
      (files?.length > 0 || ownership?.files?.length > 0) &&
      (values.ownership || ownership.ownership)
    ) {
      changeState =
        values.ownership >= 20 || taskResponse.metadata.ownership >= 20
          ? createGuarantorState
          : TaskState.Approved;
    } else if (changeState !== TaskState.Incomplete) {
      changeState =
        values.ownership >= 20 || taskResponse.metadata.ownership >= 20
          ? createGuarantorState
          : TaskState.Approved;
    }

    createResponseTask({
      ...taskResponse,
      task: task._id,
      state: changeState,
      metadata: { ...taskResponse.metadata, ...values },
      files
    });
  };

  const deleteOwnership = () =>
    removeTaskResponse({ taskResponseId: ownership.key });

  const isEdit = () => ownerEdits?.includes(ownership.key);
  
  return (
    <div className="main">
      {isActionRemoved && (
        <CloseCircleFilled
          className="icon-delete"
          onClick={deleteOwnership}
          disabled={loadingRemove}
        />
      )}
      <ActionAcceptAndReject 
        taskResponseId={ownership.key} 
        task={{ id: task._id, key: task.key, name: 'Entity and Guarantors' }} 
        files={ownership?.files}
        currentState={ownership.state}
      />
      <div
        className={`content-card ${
          showTagState ? 'content-card-' + ownership.state : ''
        } ${!isActionRemoved ? 'content-card-hover-removed' : ''}`}
      >
        <div className={`card-ownership`}>
          <div className="section-1">
            {showTagState && <StateTag state={ownership.state as TaskState} isLabelLocked/>}
            <div className="names" style={{ marginTop: showTagState ? 7 : 0 }}>
              <div className="name">
                <label className="body-regular text-blue">First name</label>
                <span>{ownership.name}</span>
              </div>
              {ownership.lastname && (
                <div className="name">
                  <label className="body-regular text-blue">Last name</label>
                  <span>{ownership.lastname}</span>
                </div>
              )}
            </div>
            {!hiddenSectionViewProfile && (
              <div className="footer">
                {ownership.state !== TaskState.Incomplete && (
                  <>
                    <span className={`approved`}>
                      <CheckOutlined /> Profile complete
                    </span>
                    <span style={{ marginLeft: 5 }}>|</span>
                  </>
                )}
                {ownership.state === TaskState.Incomplete && (
                  <>
                    <span className={`error`}>
                      <CloseOutlined /> Incomplete profile
                    </span>
                    <span style={{ marginLeft: 5 }}>|</span>
                  </>
                )}
                <Button
                  type="link"
                  style={{ padding: 0, paddingLeft: ownership.state ? 5 : 0 }}
                  onClick={changeEdit}
                >
                  View profile
                  {!isEdit() && <DownOutlined />}
                  {isEdit() && <UpOutlined />}
                </Button>
              </div>
            )}
          </div>
          <div className="section-2">
            {percentageOwner && (
              <span
                className="body-regular text-blue"
                style={{ fontSize: 12, marginTop: showTagState ? 28 : 5 }}
              >
                Ownership
              </span>
            )}
            <InputBorderNone
              type="number"
              placeholder="Ownership"
              suffix={percentageOwner ? '%' : ' '}
              className={`input-disabled-color ${
                !percentageOwner && 'suffix-null'
              }`}
              color="warning"
              height={20}
              max={100}
              min={1}
              value={percentageOwner}
              disabled={disabledForm || disabledPercentage}
              onChange={(value: number) => {
                if (value > 0)
                  saveChanges({ ownership: value }, null, 'update');
                setPercentageOwner(value);
              }}
            />
            {!hiddenGuarantorTag && ownership.ownership >= 20 && (
              <span className="body-regular body-regular-w5 guarantor">
                <img src={lienzoSvg} className="lienzo" alt="lienzoSvg" />{' '}
                Guarantor
              </span>
            )}
          </div>
        </div>
        {(hiddenSectionViewProfile || isEdit()) && (
          <>
            {!hiddenSectionViewProfile && (
              <Divider style={{ margin: '2px 0 5px' }} />
            )}
            <FormEdit
              ownership={ownership}
              hiddenSectionViewProfile={hiddenSectionViewProfile}
              hiddenTextEdit={hiddenTextEdit}
              disabledForm={disabledForm}
              hiddenSubmitChanges={hiddenSubmitChanges}
              hiddenColorsState={hiddenColorsState}
              saveChanges={saveChanges}
              isModalSubmit={isModalSubmit}
              saveEdit={saveEdit}
              fetching={fetching}
            />
          </>
        )}
        <ModalCompleteProfile
          visible={modalComplete}
          setVisible={setModalComplete}
          closeTask={closeTask}
        />
        <ModalFinishTask
          visible={modalFinishTask}
          setVisible={setModalFinishTask}
          closeTask={closeTask}
        />
      </div>
    </div>
  );
};
export default CardOwnership;
