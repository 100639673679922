import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './Login/Login';
import NotFound from '../404';

const Public = () => {
  return (
    <div className="Public">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};
export default Public;
