import React, { useState, useEffect } from 'react';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import InputAmount from '../../../components/Atoms/Inputs/InputAmount/InputAmount';
import CircleTag from '../../../components/Atoms/Tags/CircleTag/CircleTag';
import UploadDocs from '../../../components/Atoms/UploadDocs/UploadDocs';
import ActionAcceptAndReject from '../../../../ActionAcceptAndReject/ActionAcceptAndReject';
import StateTag from '../../../components/Atoms/Tags/StateTag/StateTag';
import ButtonSubmit from '../../../components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';

interface CardProps {
  occupiedUnits: number;
  setOccupiedUnits: Function;
  totalUnits: number;
  setTotalUnits: Function
  ammountFiles: number;
  task: Task | any;
  onTotalUnitsChange: Function;
  saveResponse: Function;
}
const CardLeaseAgreements = ({
  occupiedUnits,
  setOccupiedUnits,
  totalUnits,
  setTotalUnits,
  ammountFiles,
  task,
  onTotalUnitsChange,
  saveResponse
}: CardProps) => {

  const [ListFileUpload, setListFileUpload] = useState([]);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [isReview, setIsReview] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  const { data: taskResponses, fetching, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { taskId: task._id }
    }
  });

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.key === 'lease-agreements');
    setResponse(_response);
    setIsReview(_response?.state === TaskState.Review);
    setTaskCompleted(_response?.state === TaskState.Approved);
    if (_response?.files) setListFileUpload(_response.files);
  }, [taskResponses]);

  const generateCircles: any = () => {
    return new Array(ammountFiles).fill(undefined).map((item, index) => {
      if (response?.files && response.files[index])
        return response.files[index].state;

      return 'draft';
    });
  }

  const handleOccupiedUnitsChange = (value: number) => {
    const newTotalUnits = Math.max(value, totalUnits);
    const newVacantUnits = newTotalUnits - value;
    setOccupiedUnits(value);
    setTotalUnits(newTotalUnits);

    onTotalUnitsChange(newTotalUnits, newVacantUnits);
  }

  const handleLeaseAgreementsSubmit = () => {
    saveResponse(
      'lease-agreements',
      'Lease agreements',
      {
        ammountFiles: ammountFiles,
        occupiedUnits: occupiedUnits
      },
      TaskState.Review,
      ListFileUpload
    )
    getTaskResponses({ requestPolicy: 'network-only' });
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={`CardLeaseAgreements CardLeaseAgreements-${response?.state}`}
    >

      <ActionAcceptAndReject
        taskResponseId={response?._id}
        task={{ id: task._id, key: task.key, name: task.name }}
        files={response?.files}
        currentState={response?.state}
      />

      {response?.state && response?.state !== TaskState.Review && <StateTag state={response?.state} />}
      {response?.state && response?.state === TaskState.Review && <StateTag state={response?.state} isLabelLocked />}
      <InputAmount
        title="How many units are occupied?"
        type="edit"
        text={null}
        value={ammountFiles}
        onChange={handleOccupiedUnitsChange}
        className="InputAmount-edit"
        disabled={response?.state === TaskState.Review || response?.state === TaskState.Approved}
      />
      <article className="flex-btw">
        <div>
          <p className="body-card" style={{ marginBottom: '17px' }}>
            Submit leases for each of the occupied units
          </p>
          <div className="flex">
            <p className="h4">Submitted leases for occupied units</p>
            <div style={{ display: 'flex', gap: '12px' }}>
              {generateCircles()?.map((state: string, index: number) => (
                <CircleTag key={index} state={state} tagKey={state} />
              ))}
            </div>
          </div>
        </div>
      </article>
      <UploadDocs
        numberOfFiles={ammountFiles}
        style={{}}
        listFileUpload={ListFileUpload}
        setListFileUpload={setListFileUpload}
        button={
          <ButtonSubmit
            loading={fetching}
            modalConfirm={{ show: true }}
            onClick={handleLeaseAgreementsSubmit}
            disabled={isReview || ListFileUpload.length === 0 || response?.state === TaskState.Review || response?.state === TaskState.Approved}
          >
            Add at least one lease agreement to submit
          </ButtonSubmit>
        }
      />
    </div>
  );
};
export default CardLeaseAgreements;
