import { gql } from "urql";

export const GET_TASKS_BY_LOAN: any = gql(`
    query GetTasksByLoan($loanId: String!){
        GetTasksByLoan(loanId: $loanId) {
            _id
            key
            name
            state
            metadata
            description
            difficulty
            phase
        }  
    }
`)

export const GET_TASK_RESPONSES_BY_TASK: any = gql(`
    query GetTaskResponsesByTask($findResponsesByTask: FindResponsesByTask!){
        GetTaskResponsesByTask(findResponsesByTask: $findResponsesByTask) {
            _id
            key
            label
            step
            state
            metadata
            createdAt
            task {
                _id
            }
            files {
                key
                name
                url
                state
            }
        }  
    }
`)

export const GET_TASK_RESPONSE_COMMENTS: any = gql(`
    query GetTaskResponseComments($taskId: String!){
        GetTaskResponseComments(taskId: $taskId) {
            _id
            title
            subtitle
            description
            metadata
            markdown
            rejectionCode {
                key
                label
            }
            createdAt
        }  
    }
`)