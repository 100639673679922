import React, { useState } from "react";
import calendarPng from 'assets/images/calendar.png';
import tag from 'assets/images/tag.png';
import { Button, ButtonProps } from "antd";
import ModalEndSubmit from "../../../Organisms/Modals/ModalEndSubmit/ModalEndSubmit";

interface Props extends ButtonProps{
  onClick: any;
  isModalToDelete?: boolean;
  isModalToDeleteDocument?: boolean;
  titleModal?: string;
  modalConfirm?: {
    show: boolean;
    confirmDescription?: string;
  }
}

const ButtonSubmit = ({ onClick, isModalToDelete, isModalToDeleteDocument, titleModal, modalConfirm, ...props }: Props) => {
  const [isModalSubmit, setModalSubmit] = useState(false);

  const submit = (e: any) => {
    if(modalConfirm) setModalSubmit(true);
    else if(onClick) onClick(e);
  }

  return (
    <>
      <Button className={`button button-submit ${isModalToDelete ? 'button button-submit-delete' : ''}`} onClick={submit} {...props}>
        {props.children}
      </Button>
      {modalConfirm && (
        <ModalEndSubmit
          visible={isModalSubmit}
          setVisible={setModalSubmit}
          buttonAction={{
            hidden: true
          }}
          modal={{ 
            title: titleModal ? titleModal : 'Ready to submit?',
            description: !isModalToDelete ? (
              <h4 className="h4 h4-w4">{modalConfirm.confirmDescription || "After submitting, you'll have to contact us to make any changes."}</h4>
            ): ''
          }}
          buttonSubmit={
            <Button
              className={`button button-modal-ok ${isModalToDelete ? 'button-modal-delete' : ''}`}
              onClick={(e) => {
                setModalSubmit(false);
                if(onClick) onClick(e);
              }}
              {...props}
            >
              {isModalToDelete ? `Yes, delete this ${isModalToDeleteDocument ? 'loan document':'task'}` : 'Yes, this is correct'}
            </Button>
          }
          buttonCancel={(actionCancel: any) => (
            <Button
              className={`button button-modal-cancel ${isModalToDelete ? 'button-modal-cancel-delete' : ''}`}
              onClick={actionCancel}
            >
              {isModalToDelete ? 'No, go back to loan': 'I want to make changes'}
            </Button>
          )}
          icon={isModalToDelete ? tag : calendarPng}
          iconClassName={isModalToDelete ? 'tag' : 'coin'}
          className="modal-end-submit--white"
        />
      )}
    </>
  );
};
export default ButtonSubmit;
