// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approval-section-title > .title {
  line-height: 135%;
  font-size: 24px;
  font-weight: 600;
}
.approval-section-title > .title > .bold {
  font-weight: 800;
}
.approval-section-title > .title > .blue-bold {
  font-size: 24px;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/ApprovalRejectSection/components/SectionTitle/SectionTitle.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,eAAA;EACA,0BAAA;AADN","sourcesContent":[".approval-section-title {\n  >.title {\n    line-height: 135%;\n    font-size: 24px;\n    font-weight: 600;\n    \n    >.bold {\n      font-weight: 800;\n    }\n  \n    >.blue-bold {\n      font-size: 24px;\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
