import { useContext, useEffect } from "react";
import DropdownBlue from "../../../../components/Dropdowns/Dropdown/Dropdown";
import { Borrower, Entity, Loan, TaskState } from "../../../../gql/graphql";
import { BorrowerDetailViewContext, LoanDetailContext } from "../../Private";
import { useNavigate } from "react-router-dom";

export type LoanStatus = 'review' | 'approval';
export interface IProps {
  onClick?: () => void;
}

export const BorrowerListItem: React.FC<IProps> = (props) => {
  const { ...borrower }: any = props;
  const navigate = useNavigate();

  const { setLoan: setLoanSelected }: any = useContext(LoanDetailContext);
  const { setHandlerVisible }: any = useContext(BorrowerDetailViewContext);

  const getEntitiesName = (entities: Entity[]) => {
    return (
      <>
        <span>{entities && entities[0]?.name}</span>
        {entities?.length > 1 &&
          <>
            {' & '}
            <DropdownBlue
              label={`${entities.length - 1} more`}
              items={
                entities
                  .filter((item, index) => index > 0)
                  .map((item) => ({ key: item._id, label: item.name }))
              }
              onClickSelect={() => { }}
              className={`dropdown-blue-bold`}
            />
          </>
        }
      </>
    );
  }

  const getLoansName = (loans: Loan[]) => {
    const loanFirst = loans && loans[0];
    const isReview = loanFirst?.tasks?.find(task => task.state === TaskState.Review)

    const selectLoan = (loanId: string) => {
      setLoanSelected(loans.find(item => item._id === loanId));
      navigate('/loans');
    }

    return (
      <>
        {isReview ?
          <DropdownBlue
            label={`${loanFirst.name}`}
            items={loans?.map((item) => ({ key: item._id, label: item.name }))}
            onClickSelect={selectLoan}
            className={`dropdown-review-bold`}
          />
          :
          <span>
            {loanFirst?.name}
          </span>
        }
        {loans?.length > 1 &&
          <>
            {' & '}
            <DropdownBlue
              label={`${loans.length - 1} more`}
              items={loans.map((item) => ({ key: item._id, label: item.name }))}
              onClickSelect={selectLoan}
              className={`dropdown-blue-bold`}
            />
          </>
        }
      </>
    );
  }

  const viewBorrower = () => setHandlerVisible(borrower._id);

  return (
    <div className='borrower-list-item'>
      <div className='borrower-summary-container'>
        <div className='borrower-summary'>
          <div className='caption'>Borrower</div>
          <div className='borrower-name'>{borrower.name + ' ' + borrower.lastName}</div>
        </div>
      </div>
      <div>
        <div className='entities-summary'>
          <div className='caption'>Entities</div>
          <div className='content'>{getEntitiesName(borrower.entities)}</div>
        </div>
      </div>
      <div>
        <div className='task-summary'>
          <div className='caption'>Loans</div>
          <div className='address'>{getLoansName(borrower.loans)}</div>
        </div>
      </div>
      <div>
        <div className='action-container' onClick={viewBorrower}>
          {"View borrower details -->"}
        </div>
      </div>
    </div>
  )
}
