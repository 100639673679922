import React, { createContext, useContext, useEffect, useState } from 'react';

import { BellIcon, EyeIcon, TrashIcon } from '../../../../../components/BorrowerTask/BorrowerTasks';
import { StatusBadge } from '../../LoanList';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext, SelectLoanDocumentContext } from '../LoanDetails';
import { Loan, LoanDoc, LoanDocState, Task, TaskState } from '../../../../../gql/graphql';
import CreateLoanDoc from './CreateLoanDoc';
import { ReactComponent as IconExport } from 'assets/images/icon-export.svg';
import { useCreateLoanDoc, useDeleteFileLoanDoc, useDeleteLoanDocument } from '../../../../../services/loan/mutations';
import moment from 'moment';
import ButtonSubmit from '../../../../../components/BorrowerTask/components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';

const LoanDocsPreloaded = [
  'term_sheet',
  'pre_approval'
];

const docKeyOnboarding: any = {
  'term_sheet': 'termSheetURL',
  'pre_approval': 'preApprovalLetterUrl'
};



interface ILoanDocumentsProps {
  tasks: Task[];
}

const LoanDocuments: React.FC<ILoanDocumentsProps> = (props) => {

  const loan: Loan | any = useContext(LoanContext);
  const { loanDocKey, setLoanDocKey }: any = useContext(SelectLoanDocumentContext);

  const { execute: createLoanDoc, fetching } = useCreateLoanDoc();
  const { execute: deleteLoanDocument } = useDeleteLoanDocument();
  const { data: loanDocs, reexecute: getLoanDocByLoan } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const [loanDocumentsStatic, setLoanDocumentsStatic] = useState([
    { key: 'term_sheet', label: 'Term Sheet', isDelete: false },
    { key: 'appraisal', label: 'Appraisal', isDelete: false },
    { key: 'pre_approval', label: 'Pre-approval letter', isDelete: false },
    { key: 'title', label: 'Title', isDelete: false },
    { key: 'insurance', label: 'Insurance Documents', isDelete: false },
    { key: 'closing_docs', label: 'Closing docs', isDelete: false },
  ]);

  useEffect(() => {
    const updatedLoanDocumentsStatic = loanDocumentsStatic.map((doc) => {
      const existingDoc = loanDocs?.find((item) => item.key === doc.key);
      if (existingDoc) {
        return {
          ...doc,
          isDelete: existingDoc.isDeleted || false,
        };
      }
      return doc;
    });
    setLoanDocumentsStatic(updatedLoanDocumentsStatic);
  }, [loanDocs]);

  const newLoanDocs = loanDocs?.filter((item) => !item.key);
  const [selectLoanDoc, setSelectLoanDoc] = useState<any>();

  useEffect(() => {
    if (loanDocKey) {
      const isLoanDoc = loanDocumentsStatic.find(item => item.key === loanDocKey && item.isDelete);
      if (isLoanDoc)
        setSelectLoanDoc({ key: isLoanDoc.key, label: isLoanDoc.label });
      setLoanDocKey();
    }
  }, [loanDocKey])

  const getExpirationLoanDoc = (loanDoc: LoanDoc) => {
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key);
    return isLoanDoc?.expiration;
  }

  const getStateLoanDoc = (loanDoc: LoanDoc) => {
    const keyRefLoanDoc = ['appraisal', 'title', 'insurance'];
    const keyRefTask = ['pay_appraisal', 'title_company', 'insurance'];
    let taskRef;

    if (keyRefLoanDoc.includes(loanDoc.key as string)) {
      const indexKey = keyRefLoanDoc.findIndex(item => item === loanDoc.key);
      taskRef = props.tasks.find(task => task.key === keyRefTask[indexKey]);
    }

    const isLoanDoc = loanDoc._id ? loanDoc : loanDocs?.find(item =>
      (loanDoc.ownership && item.name === loanDoc.name) ||
      (!loanDoc.ownership && item.key && item.key === loanDoc.key)
    );

    const isPreloadBorrower = ['term_sheet', 'pre_approval'];

    if (isPreloadBorrower.includes(loanDoc?.key || ''))
      return 'COMPLETE';

    if (
      (!isLoanDoc || isLoanDoc?.state === LoanDocState.Blocking) &&
      (taskRef?.state === TaskState.Approved || taskRef?.state === TaskState.Review) &&
      !isLoanDoc?.file
    ) {
      return 'UPLOAD DOCUMENT';
    } else if (isLoanDoc?.state === LoanDocState.Blocking) {
      return 'BLOCKED';
    } else if (
      isLoanDoc?.state === LoanDocState.Received ||
      isLoanDoc?.state === LoanDocState.Ordered
    ) {
      return 'VERIFY/UPDATE STATUS';
    } else if (isLoanDoc?.state === LoanDocState.Approved) {
      return 'COMPLETE';
    }
    return !isLoanDoc && taskRef?.state !== TaskState.Approved && taskRef?.state !== TaskState.Review ? 'AWAITING BORROWER'
      : !isLoanDoc?.file ? 'UPLOAD DOCUMENT' : 'COMPLETE';
  }

  const handlerOnBlocking = async (loanDoc: any) => {
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.docKey);

    await createLoanDoc({
      _id: isLoanDoc?._id,
      key: loanDoc.docKey,
      name: loanDoc.label,
      loan: loan._id,
      state: LoanDocState.Blocking
    });

    getLoanDocByLoan({ requestPolicy: 'network-only' });
  }

  const isFile = (loanDoc: LoanDoc) => {
    if (loanDoc && LoanDocsPreloaded.includes(loanDoc.key as any) && loan.onboarding) {

      const preUrl = loan.onboarding[docKeyOnboarding[loanDoc?.key as any]];

      if (preUrl) return true;
    }

    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key);
    return !!isLoanDoc?.file;
  }

  const downloadFile = async (loanDoc: LoanDoc | any) => {
    let isLoanDoc: any = loanDocs?.find(item => item.key === loanDoc.key);

    if (loanDoc && LoanDocsPreloaded.includes(loanDoc.key as any) && loan.onboarding) {

      const preUrl = loan.onboarding[docKeyOnboarding[loanDoc?.key as any]];

      if (preUrl)
        isLoanDoc = {
          name: loanDoc.label,
          file: { url: preUrl }
        };
    }

    if (isLoanDoc?.file) {
      try {
        const response = await fetch(isLoanDoc.file?.url, { mode: 'cors' });
        const data = await response.blob();
        const urlObject = window.URL.createObjectURL(data); // Crea un objeto URL desde el blob
        const link = document.createElement('a');
        link.href = urlObject;
        link.download = isLoanDoc.name.toLowerCase();
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Limpia la URL creada
        window.URL.revokeObjectURL(urlObject);

        return data;
      } catch (error) {
        console.error(`Error al descargar el archivo ${isLoanDoc.file.url}`, error);
        return null;
      }
    }
  }

  const loanGuarantors: any = loanDocs?.filter(item => item.ownership?._id) || [];

  const onDeleteFile = async (docId?: string, docKey?: string,) => {
    if (!docId && !docKey) return;
    if (docKey && loanDocumentsStatic.find(item => item.key === docKey)) {
      const existingDoc = loanDocs?.find((item) => item.key === docKey)
      if (existingDoc) {
        await createLoanDoc({
          _id: existingDoc?._id,
          key: existingDoc.key,
          name: existingDoc.name,
          loan: loan._id,
          isDeleted: true
        });
      } else {
        await createLoanDoc({
          key: docKey,
          name: loanDocumentsStatic.find(item => item.key === docKey)?.label,
          loan: loan._id,
          isDeleted: true,
          state: LoanDocState.Close
        });
      }
      setLoanDocumentsStatic((prev) =>
        prev.map((doc) => (doc.key === docKey ? { ...doc, isDelete: true } : doc))
      );
    } else if (docId) {
      await deleteLoanDocument({ loanDocId: docId, fileKey: docKey })
    }
    getLoanDocByLoan({ requestPolicy: 'network-only' });
  }

  return (
    <div className='loan-documents'>
      <div className='title'>
        Loan Documents
      </div>
      <CreateLoanDoc selectLoanDoc={selectLoanDoc} setSelectLoanDoc={setSelectLoanDoc} loanDocs={loanDocs || []} />
      <div className='loan-documents-list'>
        {loanDocumentsStatic.filter((doc) => !doc.isDelete).map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docKey={loanDoc.key}
            label={loanDoc.label}
            onSelect={setSelectLoanDoc}
            onDelete={onDeleteFile}
            state={getStateLoanDoc(loanDoc)}
            expiration={getExpirationLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
            handlerDownloadFile={() => downloadFile(loanDoc)}
            isDownload={isFile(loanDoc)}
          />
        ))}
        {newLoanDocs?.map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docId={loanDoc._id}
            label={loanDoc.name}
            onSelect={setSelectLoanDoc}
            onDelete={onDeleteFile}
            state={getStateLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
            handlerDownloadFile={() => downloadFile(loanDoc)}
            isDownload={isFile(loanDoc)}
            expiration={getExpirationLoanDoc(loanDoc)}
          />
        ))}
        {loanGuarantors.map((loanDoc: any, index: number) => (
          <DocumentItem
            key={index}
            docId={loanDoc._id}
            label={loanDoc.name}
            onSelect={setSelectLoanDoc}
            onDelete={onDeleteFile}
            state={getStateLoanDoc(loanDoc)}
            expiration={getExpirationLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
            handlerDownloadFile={() => downloadFile(loanDoc)}
            isDownload={isFile(loanDoc)}
          />
        ))}
      </div>
    </div>
  );
};

interface IDocumentItemProps {
  label: string;
  docId?: string;
  docKey?: string;
  onSelect: Function;
  onDelete: Function;
  state: "COMPLETE" | 'UPLOAD DOCUMENT' | 'AWAITING BORROWER' | "VERIFY/UPDATE STATUS" | "BLOCKED";
  handlerOnBlocking: Function;
  handlerDownloadFile: any;
  isDownload: boolean;
  expiration: any;
}

const DocumentItem: React.FC<IDocumentItemProps> = (props) => {
  const { label, docKey, docId, state, onSelect, onDelete, handlerDownloadFile, isDownload, expiration } = props;

  const isActionBlocked = state === 'AWAITING BORROWER' || state === 'BLOCKED';

  const onBlocking = () => {
    if (isActionBlocked)
      props.handlerOnBlocking(props);
  }

  const isExpired = moment().isAfter(moment(expiration));

  return (
    <div className={`document-item document-item-${state}`}>
      <div className='actions-container'>
        {/*<EyeIcon />*/}
        <IconExport className={!isDownload ? `disabled` : ''} onClick={handlerDownloadFile} />
      </div>
      <div className='label' onClick={() => onSelect({ _id: docId, key: docKey, label })}>
        {label}
        {expiration &&
          <span className={"expires-label expires-label-" + (isExpired ? 'error' : 'approved')}>
            Expires {isExpired ? 'TBD' : moment(expiration).format('DD/MM/YY')}
          </span>
        }
      </div>
      <div className='content-right'>
        <div className='feedback-actions-container'>
          <div
            onClick={onBlocking}
            className={`action-blocked ${!isActionBlocked && 'action-blocked-disabled'} ${state === 'BLOCKED' && 'action-blocked-blocked'}`}
            style={{ paddingRight: 5}}
          >
            <BellIcon />
          </div>
          <ButtonSubmit
            onClick={() => onDelete(docId, docKey)}
            isModalToDelete={true}
            isModalToDeleteDocument={true}
            titleModal='Are you sure you want to delete this loan document? This action can not be undone'
            modalConfirm={{
              show: true,
            }}>
            <TrashIcon />
          </ButtonSubmit>
          {/*<TrashIcon />*/}
        </div>
        <div className='document-state' onClick={() => onSelect({ _id: docId, key: docKey, label })}>
          <StatusBadge title={state === 'BLOCKED' ? 'AWAITING BORROWER' : state} />
        </div>
      </div>
    </div>
  );
};

export default LoanDocuments;