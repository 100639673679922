import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../TrackRecord';
import ModalFinishTask from './ModalFinishTask';
import { useGetTaskResponsesByTask } from '../../../../services/task/querys';
import { useCreateResponseTask } from '../../../../services/task/mutations';
import { Task, TaskResponse, TaskState } from '../../../../gql/graphql';
import CardUploadResponse from '../../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import ActionAcceptAndReject from '../../../ActionAcceptAndReject/ActionAcceptAndReject';
import SectionIssues from '../../../SectionIssues/SectionIssues';
import AddAnotherDoc from '../../components/Organisms/AddAnotherDoc/AddAnotherDoc';

interface Props {
  setStep: Function;
  closeTask: Function;
}
const Step2 = ({ setStep, closeTask }: Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();

  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  
  useEffect(() => {
    const _response = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const submit = async (files: any) => {
    if (task) {
      await createResponseTask({
        _id: response?._id,
        key: 'purchased/sold',
        label: 'Details of properties you’ve purchased/sold',
        step: 1,
        metadata: {},
        state: TaskState.Review,
        task: task?._id,
        files
      });

      getTaskResponses({ requestPolicy: 'network-only' });
      setModalFinishTask(true);
    }
  };

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          Give us some more info on the properties you’ve purchased/sold
        </h3>

        <SectionIssues task={task}/>

        <div className="mt-60">
          <CardUploadResponse
            title="Details of properties you’ve purchased/sold"
            description="Fill out our template and upload it here. We’ll use this to get preliminary verification of your experience."
            isButtonTemplate={{ show: true, link: '' }}
            state={response?.state}
            files={response?.files}
            submit={{
              title: 'Submit for review',
              loading: fetching,
              disabled: response,
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => submit(files)
            }}
            insertHtml={response && 
              <ActionAcceptAndReject
                taskResponseId={response?._id}
                task={{ id: task._id, key: task.key, name: task.name }}
                files={response?.files}
                currentState={response?.state}
              />
            }
          />
          <AddAnotherDoc task={task} />
        </div>
      </div>

      <ModalFinishTask
        visible={modalFinishTask}
        setVisible={setModalFinishTask}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step2;
