import React, { createContext, useContext, useEffect, useState } from 'react';
import { BorrowerListItem } from './components/BorrowerListItem';
import BorrowerDetail from './BorrowerDetail/BorrowerDetail';
import { useGetBorrowers } from '../../../services/borrower/querys';
import { useParams } from 'react-router-dom';
import { BorrowerDetailViewContext } from '../Private';

interface Props {
  children?: React.ReactNode;
}

const Borrowers: React.FC<Props> = ({ }) => {

  const { borrowerId } = useParams();

  const { setHandlerVisible }: any = useContext(BorrowerDetailViewContext);
  const { data: borrowers } = useGetBorrowers();

  useEffect(() => {
    if(borrowerId) 
      setHandlerVisible(borrowerId);
  }, [borrowerId])
  
  return (
    <div className="borrowers">
      <div className='top-container'>
        <div className='title'>
          Borrowers
        </div>
        <div>
        </div>
      </div>
      <div className='borrowers-container'>
        {borrowers?.map((borrower: any) => (
          <BorrowerListItem key={borrower._id} {...borrower}/>
        ))}
      </div>

      <BorrowerDetail/>
    </div>
  );
};

export default Borrowers;