import React, { createContext, useContext, useEffect, useState } from 'react';

import { BellIcon, EyeIcon, TrashIcon } from '../../../../../components/BorrowerTask/BorrowerTasks';
import { StatusBadge } from '../../LoanList';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext, SelectLoanDocumentContext } from '../LoanDetails';
import { Loan, LoanDoc, LoanDocState, Task, TaskState } from '../../../../../gql/graphql';
import CreateLoanDoc from './CreateLoanDoc';
import { ReactComponent as IconExport } from 'assets/images/icon-export.svg';
import { useCreateLoanDoc } from '../../../../../services/loan/mutations';
import moment from 'moment';

const LoanDocsPreloaded = [
  'term_sheet',
  'pre_approval'
];

const docKeyOnboarding: any = {
  'term_sheet': 'termSheetURL',
  'pre_approval': 'preApprovalLetterUrl'
};

const LoanDocumentsStatic = [
  { key: 'term_sheet', label: 'Term Sheet' },
  { key: 'appraisal', label: 'Appraisal' },
  { key: 'pre_approval', label: 'Pre-approval letter' },
  { key: 'title', label: 'Title' },
  { key: 'insurance', label: 'Insurance Documents' },
  { key: 'credit_report', label: 'Credit Report' },
  { key: 'background_check', label: 'Background Check' },
  { key: 'closing_docs', label: 'Closing docs' },
];

interface ILoanDocumentsProps {
  tasks: Task[];
}

const LoanDocuments: React.FC<ILoanDocumentsProps> = (props) => {

  const loan: Loan | any = useContext(LoanContext);
  const { loanDocKey, setLoanDocKey }: any = useContext(SelectLoanDocumentContext);

  const { execute: createLoanDoc, fetching } = useCreateLoanDoc();
  const { data: loanDocs, reexecute: getLoanDocByLoan } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const newLoanDocs = loanDocs?.filter((item) => !item.key);
  const [selectLoanDoc, setSelectLoanDoc] = useState<any>();

  useEffect(() => {
    if (loanDocKey) {
      const isLoanDoc = LoanDocumentsStatic.find(item => item.key === loanDocKey);
      if (isLoanDoc)
        setSelectLoanDoc({ key: isLoanDoc.key, label: isLoanDoc.label });
      setLoanDocKey();
    }
  }, [loanDocKey])

  const getExpirationLoanDoc = (loanDoc: LoanDoc) => {
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key);
    return isLoanDoc?.expiration;
  }

  const getStateLoanDoc = (loanDoc: LoanDoc) => {
    const keyRefLoanDoc = ['appraisal'];
    const keyRefTask = ['pay_appraisal'];
    let taskRef;

    if (keyRefLoanDoc.includes(loanDoc.key as string)) {
      const indexKey = keyRefLoanDoc.findIndex(item => item === loanDoc.key);
      taskRef = props.tasks.find(task => task.key === keyRefTask[indexKey]);
    }

    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key);
    const isPreloadBorrower = ['term_sheet', 'pre_approval'];

    if (isPreloadBorrower.includes(loanDoc?.key || ''))
      return 'COMPLETE';

    if ((
      !isLoanDoc || isLoanDoc?.state === LoanDocState.Blocking) &&
      (taskRef?.state === TaskState.Approved || taskRef?.state === TaskState.Review)
    ) {
      return 'UPLOAD DOCUMENT';
    } else if (isLoanDoc?.state === LoanDocState.Blocking) {
      return 'BLOCKED';
    } else if (
      isLoanDoc?.state === LoanDocState.Received ||
      isLoanDoc?.state === LoanDocState.Ordered
    ) {
      return 'VERIFY/UPDATE STATUS';
    } else if (isLoanDoc?.state === LoanDocState.Approved) {
      return 'COMPLETE';
    }
    return !isLoanDoc ? 'AWAITING BORROWER' : 'UPLOAD DOCUMENT';
  }

  const handlerOnBlocking = async (loanDoc: any) => {
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.docKey);

    await createLoanDoc({
      _id: isLoanDoc?._id,
      key: loanDoc.docKey,
      name: loanDoc.label,
      loan: loan._id,
      state: LoanDocState.Blocking
    });

    getLoanDocByLoan({ requestPolicy: 'network-only' });
  }

  const isFile = (loanDoc: LoanDoc) => {
    if (loanDoc && LoanDocsPreloaded.includes(loanDoc.key as any) && loan.onboarding) {
  
      const preUrl = loan.onboarding[docKeyOnboarding[loanDoc?.key as any]];
      
      if(preUrl) return true;
    }

    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key);
    return !!isLoanDoc?.file;
  }

  const downloadFile = async (loanDoc: LoanDoc | any) => {
    let isLoanDoc: any = loanDocs?.find(item => item.key === loanDoc.key);

    if (loanDoc && LoanDocsPreloaded.includes(loanDoc.key as any) && loan.onboarding) {
  
      const preUrl = loan.onboarding[docKeyOnboarding[loanDoc?.key as any]];
      
      if(preUrl)
        isLoanDoc = { 
          name: loanDoc.label,
          file: { url: preUrl } 
        };
    }

    if (isLoanDoc?.file) {
      try {
        const response = await fetch(isLoanDoc.file?.url, { mode: 'cors' });
        const data = await response.blob();
        const urlObject = window.URL.createObjectURL(data); // Crea un objeto URL desde el blob
        const link = document.createElement('a');
        link.href = urlObject;
        link.download = isLoanDoc.name.toLowerCase();
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Limpia la URL creada
        window.URL.revokeObjectURL(urlObject);

        return data;
      } catch (error) {
        console.error(`Error al descargar el archivo ${isLoanDoc.file.url}`, error);
        return null;
      }
    }
  }
  
  return (
    <div className='loan-documents'>
      <div className='title'>
        Loan Documents
      </div>
      <CreateLoanDoc selectLoanDoc={selectLoanDoc} setSelectLoanDoc={setSelectLoanDoc} loanDocs={loanDocs || []} />
      <div className='loan-documents-list'>
        {LoanDocumentsStatic.map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docKey={loanDoc.key}
            label={loanDoc.label}
            onSelect={setSelectLoanDoc}
            state={getStateLoanDoc(loanDoc)}
            expiration={getExpirationLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
            handlerDownloadFile={() => downloadFile(loanDoc)}
            isDownload={isFile(loanDoc)}
          />
        ))}
        {newLoanDocs?.map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docId={loanDoc._id}
            label={loanDoc.name}
            onSelect={setSelectLoanDoc}
            state={getStateLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
            handlerDownloadFile={() => downloadFile(loanDoc)}
            isDownload={isFile(loanDoc)}
            expiration={getExpirationLoanDoc(loanDoc)}
          />
        ))}
      </div>
    </div>
  );
};

interface IDocumentItemProps {
  label: string;
  docId?: string;
  docKey?: string;
  onSelect: Function;
  state: "COMPLETE" | 'UPLOAD DOCUMENT' | 'AWAITING BORROWER' | "VERIFY/UPDATE STATUS" | "BLOCKED";
  handlerOnBlocking: Function;
  handlerDownloadFile: any;
  isDownload: boolean;
  expiration: any;
}

const DocumentItem: React.FC<IDocumentItemProps> = (props) => {
  const { label, docKey, docId, state, onSelect, handlerDownloadFile, isDownload, expiration } = props;

  const isActionBlocked = state === 'AWAITING BORROWER' || state === 'BLOCKED';

  const onBlocking = () => {
    if (isActionBlocked)
      props.handlerOnBlocking(props);
  }

  const isExpired = moment().isAfter(moment(expiration));

  return (
    <div className={`document-item document-item-${state}`}>
      <div className='actions-container'>
        {/*<EyeIcon />*/}
        <IconExport className={!isDownload ? `disabled` : ''} onClick={handlerDownloadFile} />
      </div>
      <div className='label' onClick={() => onSelect({ _id: docId, key: docKey, label })}>
        {label}
        {expiration && 
          <span className={"expires-label expires-label-" + (isExpired? 'error': 'approved')}>
            Expires {isExpired? 'TBD': moment(expiration).format('DD/MM/YY')}
          </span>
        }
      </div>
      <div className='content-right'>
        <div className='feedback-actions-container'>
          <div
            onClick={onBlocking}
            className={`action-blocked ${!isActionBlocked && 'action-blocked-disabled'} ${state === 'BLOCKED' && 'action-blocked-blocked'}`}
          >
            <BellIcon />
          </div>
          {/*<TrashIcon />*/}
        </div>
        <div className='document-state' onClick={() => onSelect({ _id: docId, key: docKey, label })}>
          <StatusBadge title={state === 'BLOCKED' ? 'AWAITING BORROWER' : state} />
        </div>
      </div>
    </div>
  );
};

export default LoanDocuments;