import React, { createContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import SectionRight from './SectionRight/SectionRight';

interface Props {
  visible: boolean;
  handlerSetVisibile: Function;
  renderButtonTrigger?: Function;
  header?: {
    title: React.ReactElement;
    actionLeft?: React.ReactElement;
    actionRight?: React.ReactElement;
  };
  stepsTotal: number | 1;
  currentStep: number;
  setStep: Function | null;
  children: React.ReactElement;
  footer: React.ReactElement | null;
  animationDirection: string | 'onRight';
  dots: boolean;
  disabledDots?: boolean;
  dotsReview?: any[];
}
interface dotObjet {
  id: number;
}

export const ModalTaskRightContext = createContext<{ value: string; setHandler: any } | {}>({});

const ModalTask = (props: Props) => {
  const [openSectionRight, setOpenSectionRight] = useState<{ show: boolean, params?: any }>();
  const [isDots, setDots] = useState<dotObjet[]>();

  useEffect(() => {
    const arrayDots = [];
    for (let index = 0; index < props.stepsTotal; index++) {
      arrayDots.push({ id: index + 1 });
    }
    setDots(arrayDots);
  }, [props.stepsTotal]);

  const isStyleDots = (item: any, index: number) => {
    if (item.id === props.currentStep && props.dotsReview && props.dotsReview[index])
      return 'dots-button-review-active'
    if (item.id === props.currentStep) return 'dots-button-active';
    else if (props.currentStep > index)
      return 'dots-button-success';
  };

  const closeModal = () => {
    props.handlerSetVisibile(false);
    setOpenSectionRight({ show: false });
  }

  const handlerModalTaskRight = (show: boolean, params: any) =>
    setOpenSectionRight({ show, params });

  return (
    <div className="cont-modal">
      {props.renderButtonTrigger && props.renderButtonTrigger(props.handlerSetVisibile)}
      {props.visible && (
        <ModalTaskRightContext.Provider value={{ value: openSectionRight, setHandler: handlerModalTaskRight }}>
          <div className="ModalTask">
            <Button
              className="button-close-mask"
              style={{ width: openSectionRight?.show ? '5%' : '30%' }}
              onClick={closeModal}
            />
            <div
              className={`ModalTask-content animate__animated ${props.animationDirection === 'onRight' && 'animate__fadeInRight'}`}
              style={{ width: openSectionRight?.show ? '60%' : '70%' }}
            >
              <div className="content-modal">
                <div className="modal-review-task__content">
                  <div className="modal-title">
                    <div className="flex-btw">
                      <h3 className="body-card body-card-w6">
                        {props.header?.title}
                      </h3>
                      <Button
                        className="body-card"
                        onClick={() => props.handlerSetVisibile(false)}
                      >
                        Close X
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="modal-container">
                  {props.dots && (
                    <div className="dots">
                      {isDots?.map((item, index) => (
                        <Button
                          onClick={() => props.setStep && props.setStep(item.id)}
                          disabled={
                            props.disabledDots || props.currentStep <= index
                          }
                          className={`dots-button ${isStyleDots(item, index)}`}
                          key={item.id}
                        ></Button>
                      ))}
                    </div>
                  )}
                  <div className="modal-cards modal-cards--full">
                    <div className="modal-cards-mx-w">
                      <div className="flex-btw">
                        {props.header?.actionLeft}
                        {props.header?.actionRight}
                      </div>
                      {props.children}
                    </div>
                  </div>
                </div>
              </div>
              {props.footer && (
                <div className="modal-review-task__footer">
                  <div className="modal-container">
                    <div className="modal-cards">{props.footer}</div>
                  </div>
                </div>
              )}
            </div>
            {openSectionRight?.show &&
              <SectionRight
                params={openSectionRight.params}
                close={() => setOpenSectionRight({ show: false })}
              />
            }
          </div>
        </ModalTaskRightContext.Provider>
      )}
    </div>
  );
};
export default ModalTask;
