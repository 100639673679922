import React from "react";
import PropTypes from 'prop-types';


const Layout = ({ children }: any) => {
  return (
    <section className="Layout">
      <div className="BarLogin">
      </div>
      <div className="content">
        {children}
      </div>
    </section>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
