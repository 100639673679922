// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddNewTasks .form {
  margin-top: 30px;
}
.AddNewTasks .form-item-task-name .ant-form-item-control-input {
  min-height: 10px !important;
}
.AddNewTasks .form-task-name {
  font-size: 20px !important;
  color: black !important;
}
.AddNewTasks .form-task-name::placeholder {
  font-size: 20px !important;
}
.AddNewTasks .form-doc-name {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: black !important;
}
.AddNewTasks .form-doc-name::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.AddNewTasks .form-doc-description {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  color: black !important;
}
.AddNewTasks .form-doc-description::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic !important;
}
.AddNewTasks .form .doc-info .ant-form-item {
  margin-bottom: 0;
}
.AddNewTasks .form .doc-info .ant-form-item-control-input {
  min-height: 25px;
}
.AddNewTasks .form .placeholder {
  margin-top: 20px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/scenes/Private/LoanList/LoanDetails/AddNewTask/AddNewTask.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGM;EACE,2BAAA;AADR;AAKI;EACE,0BAAA;EACA,uBAAA;AAHN;AAKM;EACE,0BAAA;AAHR;AAOI;EACE,0BAAA;EACA,2BAAA;EACA,uBAAA;AALN;AAOM;EACE,0BAAA;EACA,2BAAA;AALR;AASI;EACE,0BAAA;EACA,2BAAA;EACA,6BAAA;EACA,uBAAA;AAPN;AASM;EACE,0BAAA;EACA,2BAAA;EACA,6BAAA;AAPR;AAYM;EACE,gBAAA;AAVR;AAYM;EACE,gBAAA;AAVR;AAcI;EACE,gBAAA;EACA,kBAAA;AAZN","sourcesContent":[".AddNewTasks {\n  .form {\n    margin-top: 30px;\n\n    &-item-task-name {\n      .ant-form-item-control-input {\n        min-height: 10px !important;\n      }\n    }\n\n    &-task-name {\n      font-size: 20px !important;\n      color: black !important;\n\n      &::placeholder {\n        font-size: 20px !important;\n      }\n    }\n\n    &-doc-name {\n      font-size: 16px !important;\n      font-weight: 400 !important;\n      color: black !important;\n      \n      &::placeholder {\n        font-size: 16px !important;\n        font-weight: 400 !important;\n      }\n    }\n\n    &-doc-description {\n      font-size: 14px !important;\n      font-weight: 400 !important;\n      font-style: italic !important;\n      color: black !important;\n      \n      &::placeholder {\n        font-size: 14px !important;\n        font-weight: 400 !important;\n        font-style: italic !important;\n      }\n    }\n\n    .doc-info {\n      .ant-form-item {\n        margin-bottom: 0;\n      }\n      .ant-form-item-control-input {\n        min-height: 25px;\n      }\n    }\n\n    .placeholder {\n      margin-top: 20px;\n      font-style: italic;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
