import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
  EditTwoTone,
  CheckCircleOutlined
} from '@ant-design/icons';

interface InputProps {
  title: string;
  type: string | null;
  text: string | null;
  value?: number;
  onChange?: Function;
  className?: string;
  disabled?: boolean;
}
const InputAmount = ({ title, type, text, value, onChange, className, disabled }: InputProps) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleOperation = (type: any) => {
    if (value !== undefined && onChange !== undefined) {
      if (type === 'addition') {
        onChange(value + 1);
      }
      if (type === 'subtraction') {
        if (value >= 1) {
          onChange(value - 1);
        }
      }
    }
  };

  const handleSave = () => {
    if(onChange){
      onChange(editValue);
    }
    setEditing(false);
  }
  
  return (
    <div className={className ? className : "InputAmount flex-btw"}>
      <p className="h4 h4-w4">{title}</p>
      <div className="flex">
        {type === 'operation' && (
          <>
            <Button
              className="button-operation"
              onClick={() => handleOperation('subtraction')}
            >
              <MinusCircleOutlined className="anticon anticon-empty" />
              <MinusCircleFilled className="anticon anticon-full" />
            </Button>
            <span className={`${Number(value) >= 1 && 'text-bold'}`}>
              {value}
            </span>
            <Button
              className="button-operation"
              onClick={() => handleOperation('addition')}
            >
              <PlusCircleOutlined className="anticon anticon-empty" />
              <PlusCircleFilled className="anticon anticon-full" />
            </Button>
          </>
        )}
        {type === 'number' && (
          <>
            <div
              className="button-operation"
              style={{ visibility: 'hidden' }}
            >
              <MinusCircleOutlined className="anticon anticon-empty" />
              <MinusCircleFilled className="anticon anticon-full" />
            </div>
            <span className={'text-bold'} style={{ fontStyle: "normal", color: "#2A2F3C"}}>
              {value}
            </span>
            <div
              className="button-operation"
              style={{ visibility: 'hidden' }}
            >
              <PlusCircleOutlined className="anticon anticon-empty" />
              <PlusCircleFilled className="anticon anticon-full" />
            </div>
          </>
        )}
        {type === 'text' && <p className="h4 h4-w4">{text}</p>}
        {type === 'edit' && (
          <div>
            {editing ? (
              <>
                <Input className="input-edit" value={editValue} 
                onChange={(e) => setEditValue(Number(e.target.value))} />
                <Button className="button-edit" onClick={handleSave}>
                  <CheckCircleOutlined style={{ color: "#5E8EB7" }} />
                  SAVE
                </Button>
              </>
            ) : (
              <>
                {/* <p className="h4 h4-w4">{value}</p> */}
                <span className="text-bold" style={{ color:"#000000" }}>{value}</span>
                <Button disabled={disabled} className={`button-edit ${disabled ? 'button-edit-disabled' : ''}`} onClick={() => setEditing(true)}>
                  <EditTwoTone twoToneColor={`${disabled ? '#6E7889' : '#5E8EB7'}`} />
                  EDIT
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default InputAmount;
