import { useMutation } from 'urql';
import { CreateLoanDocInput, CreateLoanDocMutation, CreateNewTaskInput, CreateNewTaskMutation, DeleteFileLoanDocMutation, DeleteLoanDocumentMutation, MutationDeleteFileLoanDocArgs, MutationDeleteLoanDocumentArgs } from 'gql/graphql';
import { MUTATION_CREATE_LOAN_DOC, MUTATION_CREATE_NEW_TASK, MUTATION_DELETE_FILE_LOAN_DOC, MUTATION_DELETE_LOAN_DOCUMENT } from './graphql';

export const useCreateNewTask = (query?: any) => {
  const [results, execute] = useMutation<CreateNewTaskMutation>(query || MUTATION_CREATE_NEW_TASK);

  return {
    execute: (data: CreateNewTaskInput) =>
      execute({ createNewTaskInput: data }),
    data: results.data?.CreateNewTask,
    ...results
  };
};

export const useCreateLoanDoc = (query?: any) => {
  const [results, execute] = useMutation<CreateLoanDocMutation>(query || MUTATION_CREATE_LOAN_DOC);

  return {
    execute: (data: CreateLoanDocInput) =>
      execute({ createLoanDocInput: data }),
    data: results.data?.CreateLoanDoc,
    ...results
  };
};

export const useDeleteFileLoanDoc = (query?: any) => {
  const [results, execute] = useMutation<DeleteFileLoanDocMutation>(
    query || MUTATION_DELETE_FILE_LOAN_DOC
  );

  return {
    ...results,
    execute: (data: MutationDeleteFileLoanDocArgs) => execute(data),
    data: results.data?.DeleteFileLoanDoc,
  };
};

export const useDeleteLoanDocument = (query?: any) => {
  const [results, execute] = useMutation<DeleteLoanDocumentMutation>(
    query || MUTATION_DELETE_LOAN_DOCUMENT
  );

  return {
    ...results,
    execute: (data: MutationDeleteLoanDocumentArgs) => execute(data),
    data: results.data?.DeleteLoanDocument,
  };
};