import React, { useEffect, useState } from 'react';
import uploadSvg from 'assets/images/upload.svg';
import uploadCircleSvg from 'assets/images/upload-svg.svg';
import { File } from '../../../../../utils/interfaces/file.interface';
import InputUploadedFile from '../Inputs/InputUploadedFile/InputUploadedFile';

interface Props {
  button?: React.ReactElement | null;
  numberOfFiles: number;
  listFileUpload: File[] | [];
  setListFileUpload: Function;
  style?: any;
  circles?: { label: string; states: string[] };
  insertHtml?: any;
}

const UploadDocs = ({
  button,
  numberOfFiles,
  listFileUpload,
  setListFileUpload,
  style,
  circles,
  insertHtml
}: Props) => {

  const handleChangeFile = (event: any) => {
    if(event.target.files[0])
      setListFileUpload([...listFileUpload, event.target.files[0]]);
  };

  const deleteFile = (file: any): void => {
    const arrayFiles = listFileUpload;

    const deleteFile = arrayFiles.findIndex((item) => item.name === file.name);
    if (deleteFile >= 0) {
      arrayFiles.splice(deleteFile, 1);
    }
    setListFileUpload([...arrayFiles]);
  };
  
  return (
    <div className="UploadDocs" style={style}>
      {circles && (
        <div className="flex-btw">
          <h4 className="body-card">{circles.label}</h4>
          <div className="circles">
            {circles.states.map((state, index) => (
              <div key={index} className={`circle circle-${state}`} />
            ))}
          </div>
        </div>
      )}

      <div className="contenedor-flex">
        <div className={`felx-btw UploadDocs--container`}>
          {listFileUpload.length < numberOfFiles && (
            <div className="input-content">
              <div className="white" />
              <input
                type="file"
                onChange={handleChangeFile}
                accept="image/png,image/jpg,image/jpeg,application/pdf"
              />

              <div className="empty-input">
                <img src={uploadSvg} alt="upload" className="upload" />
                <p className="body-small body-small-w6">
                  Drag and drop files, or <span className="blue">Browse</span>
                </p>
                <p className="small-detail">Supports png or jpeg files</p>
              </div>
            </div>
          )}
        </div>
        {listFileUpload.length >= 1 && (
          <div className="cont-files">
            {insertHtml}
            {listFileUpload?.map((file: any, index) => (
              <InputUploadedFile
                key={index}
                file={file}
                deleteFile={!file.state || file.state === 'draft'? deleteFile: undefined}
                isActionSeeFile={file.state && file.state !== 'draft'}
                icon={uploadCircleSvg}
                state={file.state}
              />
            ))}
          </div>
        )}
      </div>
      {button}
    </div>
  );
};
export default UploadDocs;
