import React, { createContext, lazy, useContext, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import './LoanDetails.scss';
import { ReactComponent as IconMinimize } from 'assets/images/minimize.svg';
import iconDonwloadSvg from 'assets/images/icon-download.svg';
import { OpenTaskContext, StatusBadge } from '../LoanList';
import BorrowerTask, { ExpandIcon, EyeIcon } from '../../../../components/BorrowerTask/BorrowerTasks';
import AddNewTask from './AddNewTask/AddNewTask';
import { Loan, Task, TaskState } from '../../../../gql/graphql';
import { useLoanDetails } from './useLoanDetails';
import Progress from './Progress/Progress';
import { RulesTasksCards } from './RulesTask.constanst';
import { useDownloadFilePending } from '../../../../services/loan/querys';
import LoanDocuments from './LoanDocuments/LoanDocuments';
import { BorrowerCard } from './BorrowerCard/BorrowerCard';
import LoanProgress from './Progress/Progress';
import { formatterCurrency } from '../../../../utils/formatters/formatter';
import { LoanOption } from './LoanOption/LoanOption';
import ReactQuill from 'react-quill';

const ViewInfo = ({ url }: { url: string|null }) => {
  if (!url) return null
  return (<span onClick={() => {window.open(url, '_blank');}}>{"View info ->"}</span>)
};

const contactData = (salesforceData: any) => ({
  label: 'Partners and contacts',
  content: [
    (
      salesforceData?.entity ?
      { field: 'Entity', value: <React.Fragment>{salesforceData.entity.name} <ViewInfo url={salesforceData.entity.url}/></React.Fragment> }
      : 
      { field: 'Entity', value: <React.Fragment><i>Waiting for borrower</i> <ViewInfo url={null}/></React.Fragment> }
    ),
    (
      salesforceData?.guarantors && salesforceData?.guarantors.length ?
      { field: 'Guarantors', value: <React.Fragment>{salesforceData.guarantors[0].name} <ViewInfo url={salesforceData.guarantors[0].url}/></React.Fragment> }
      :
      { field: 'Guarantors', value: <React.Fragment><i>Waiting for borrower</i> <ViewInfo url={null}/></React.Fragment> }
    ),
    { field: 'Main loan contact', value: <React.Fragment>{salesforceData?.mainLoanContact?.name} <ViewInfo url={salesforceData?.mainLoanContact?.url}/></React.Fragment> },
    { field: 'Title agent', value: <React.Fragment>{salesforceData?.titleAgent?.name} <ViewInfo url={salesforceData?.titleAgent?.url}/></React.Fragment> },
    { field: 'Insurance agent', value: <React.Fragment>{salesforceData?.insuranceAgent?.name} <ViewInfo url={salesforceData?.insuranceAgent?.url}/></React.Fragment> },
    { field: 'Payoff contact', value: <React.Fragment>{salesforceData?.payoffContact?.name} <ViewInfo url={salesforceData?.payoffContact?.url}/></React.Fragment> }
  ],
})

const contentBoxes = (loanSelected: any) => {
  const originationFeePercent = loanSelected?.salesforceData?.fees?.originationFeePercent || 0.02;
  const data = {
    /* Loan Details */
    purpose: loanSelected?.purpose,
    type: loanSelected?.type,
    amortization: loanSelected?.type === 'Rental' ? '30 year' : 'Interest Only',
    lien: '1st Position',

    /* Property Details */
    propertyType: loanSelected?.loanData['What kind of property is it?'],
    unitCount: 0,

    /* Budget And Valuation */
    purchasePrice: formatterCurrency(loanSelected?.salesforceData?.budget?.purchasePrice || loanSelected?.loanData?.price),

    asIsValue: formatterCurrency(loanSelected?.salesforceData?.budget?.asIsValue || loanSelected?.loanData?.price),
    appraiserAsIsValue: loanSelected?.salesforceData?.budget?.appraiserAIV ? formatterCurrency(loanSelected?.salesforceData?.budget?.appraiserAIV) : 'Awaiting appraisal',
    currentLoanBalance: formatterCurrency(loanSelected?.salesforceData?.budget?.currentLoanBalance || loanSelected?.loanData?.currentLoanBalance),

    estimatedARV: formatterCurrency(loanSelected?.salesforceData?.budget?.estimatedARV || loanSelected?.loanData?.afterRepairValue),
    appraiserARV: loanSelected?.salesforceData?.budget?.appraiserARV ? formatterCurrency(loanSelected?.salesforceData?.budget?.appraiserARV) : 'Awaiting appraisal',
    constructionReserve: formatterCurrency(loanSelected?.salesforceData?.budget?.constructionReserve || loanSelected?.loanData?.constructionBudget),
    rehabBudget: formatterCurrency(loanSelected?.salesforceData?.budget?.rehabBudget || loanSelected?.loanData?.constructionBudget),
    loanToCost: `${((loanSelected?.loanData?.selectedOption?.ltc) * 100).toFixed(2)}%`,
    advanceAmount: formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount - (loanSelected?.loanData?.constructionBudget ?? 0)),

    /* Rental */
    monthlyRent: formatterCurrency(loanSelected?.salesforceData?.budget?.monthlyRent || loanSelected?.loanData?.monthlyRent),
    annualTaxes: formatterCurrency(loanSelected?.salesforceData?.budget?.annualTaxes || loanSelected?.loanData?.annualTaxes),
    annualInsurance: formatterCurrency(loanSelected?.salesforceData?.budget?.annualInsurance || loanSelected?.loanData?.annualInsurance),
    annualHoa: formatterCurrency(loanSelected?.salesforceData?.budget?.annualHoa || loanSelected?.loanData?.annualHoa),
    dscr: loanSelected?.salesforceData?.budget?.dscr || loanSelected?.loanData?.selectedOption?.dscr,

    /* Fees */
    originationFee: `${formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount * (originationFeePercent))} (${(originationFeePercent*100).toFixed(2)}%)`,
    processingFee: formatterCurrency(loanSelected?.salesforceData?.fees?.processingFee || 0),
    proRatedInterest: formatterCurrency(loanSelected?.salesforceData?.fees?.proRatedInterest || 0),
    thirdPartyCosts: "Contact closing agent",
    prepay: loanSelected?.salesforceData?.fees?.prepay || "3 Months",
    assignmentFee: formatterCurrency(loanSelected?.salesforceData?.fees?.assignmentFee || 0),
    floodCertificationFee: formatterCurrency(loanSelected?.salesforceData?.fees?.floodCertificationFee || 0),
    creditAndBackgroundCheckFee: formatterCurrency(loanSelected?.salesforceData?.fees?.creditAndBackgroundCheckFee || 0),
    miscFees: formatterCurrency(loanSelected?.salesforceData?.fees?.miscFees || 0),
    overnightDeliveryFee: formatterCurrency(loanSelected?.salesforceData?.fees?.overnightDeliveryFee || 0),
    lenderLegalFee: formatterCurrency(loanSelected?.salesforceData?.fees?.lenderLegalFee || 0),
    insurancePremiumToBeCollected: formatterCurrency(loanSelected?.salesforceData?.fees?.insurancePremiumToBeCollected || 0),
    totalLoanFees: formatterCurrency(loanSelected?.salesforceData?.fees?.totalLoanFees || 0),
  }
  
  return ([
    {
      label: 'Loan Details',
      content: [
        { field: 'Loan Purpose', value: data.purpose },
        { field: 'Loan Type', value: data.type },
        { field: 'Amortization', value: data.amortization },
        { field: 'Lien', value: data.lien },
      ],
    },
    {
      label: 'Property Details',
      content: [
        { field: 'Property Type', value: data.propertyType },
        { field: 'Unit count', value: <React.Fragment>{data.unitCount} <span>{"View unit info ->"}</span></React.Fragment> },
      ],
    },
    {
      label: 'Budget and valuation',
      content: [
        ...(
          data.purpose === "Purchase" ?
          [
            { field: 'Purchase Price', value: data.purchasePrice },
          ]
          :
          [
            { field: 'As-is-value', value: data.asIsValue, extra: { field: 'Appraiser’s as-is-value', value: data.appraiserAsIsValue } },
            { field: 'Current Loan Balance', value: data.currentLoanBalance },
          ]
        ),
        ...(
          data.type === "Fix and Flip" ?
          [
            { field: 'Estimated ARV', value: data.estimatedARV, extra: { field: 'Appraiser’s ARV', value: data.appraiserARV } },
            { field: 'Construction reserve', value: data.constructionReserve },
            { field: 'Rehab Budget', value: data.rehabBudget },
          ]
          :
          []
        ),
        ...(
          data.type === "Fix and Flip" ?
          [
            { field: 'Loan-to-cost', value: data.loanToCost },
            { field: 'Advance amount', value: data.advanceAmount },
          ]
          :
          []
        ),
        ...(
          data.type === "Rental" ?
          [
            { field: 'Monthly Rent', value: data.monthlyRent },
            { field: 'Annual Taxes', value: data.annualTaxes },
            { field: 'Annual Insurance', value: data.annualInsurance },
            { field: 'Annual HOA', value: data.annualHoa },
            { field: 'DSCR', value: data.dscr },
          ]
          :
          []
        )
      ],
    },
    {
      label: 'Fees',
      content: [
        { field: 'Origination fee', value: data.originationFee },
        { field: 'Processing fee', value: data.processingFee },
        { field: 'Pro-rated interest', value: data.proRatedInterest },
        { field: 'Third-party costs', value: data.thirdPartyCosts },
        { field: 'Prepay', value: data.prepay },
        { field: 'Assignment fee', value: data.assignmentFee },
        { field: 'Flood certification fee', value: data.floodCertificationFee },
        { field: 'Credit and background check fee', value: data.creditAndBackgroundCheckFee },
        { field: 'Misc fees', value: data.miscFees },
        { field: 'Overnight delivery fee', value: data.overnightDeliveryFee },
        { field: 'Lender legal fee', value: data.lenderLegalFee },
        { field: 'Insurance premium to be collected', value: data.insurancePremiumToBeCollected },
        { field: 'Total loan fees', value: data.totalLoanFees },
      ],
    },
  ])
}

interface ILoanDetailsProps {
  onClose: () => void;
}

export const LoanContext = createContext<Loan | {}>({});
export const TasksContext = createContext<Task[] | []>([]);
export const SelectLoanDocumentContext = createContext<{ loanDocKey: string; setLoanDocKey: any } | {}>({});

const LoanDetails: React.FC<ILoanDetailsProps> = ({ onClose }) => {

  const { setTaskKey }: any = useContext(OpenTaskContext);

  const { tasks, loanSelected } = useLoanDetails();

  const [progressData, setProgressData] = useState();
  const [phaseSelect, setPhaseSelect] = useState<string>('approval');
  const [loanDocSelect, setLoanDocSelect] = useState<string>();

  const getTasks = () => {
    const newTasks = tasks?.filter(task => !task.key);
    newTasks?.map((newTask: any) => {
      let isCard = RulesTasksCards.find(item => item.key === newTask._id);
      let _newTask = {
        key: newTask._id,
        modalKey: newTask._id,
        name: newTask.name,
        data: newTask,
        phaseProgress: newTask?.phase,
        state: newTask?.state
      };

      if (!isCard)
        RulesTasksCards.push(_newTask as any);
      else
        isCard.state = _newTask.state;
    })

    for (const rules of RulesTasksCards) {
      const findTask = tasks?.find(item => item?.key === rules.key);
      const findTaskNew = tasks?.find(item => item?._id === rules.key);
      if (findTask || findTaskNew) {
        if (findTask) {
          rules.data = findTask as Task;
          rules.state = rules.state !== TaskState.Approved ? findTask?.state : rules.state;
        }
        if (findTaskNew) {
          rules.data = findTaskNew as Task;
          rules.state = rules.state !== TaskState.Approved ? findTaskNew?.state : rules.state;
        }
      }
    }
    const rulesTasksCardsNotHidden = RulesTasksCards.filter(item => !item.data?.hidden);
    return rulesTasksCardsNotHidden;
  }

  if (!loanSelected)
    return null;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offset);

    const optionsMonthYear = { year: 'numeric', month: 'short' };
    // @ts-ignore
    const monthYear = date.toLocaleDateString('en-US', optionsMonthYear);
  
    const optionsDay = { day: 'numeric' };
    // @ts-ignore
    const day = date.toLocaleDateString('en-US', optionsDay);
  
    return { monthYear, day };
  };
  const closingDate = loanSelected?.salesforceData?.closingDate ? formatDate(loanSelected?.salesforceData?.closingDate): null;
  const date = closingDate

  // @ts-ignore
  const momentDate = date ? moment(date) : date
  if (momentDate) {
    momentDate.add(1, 'month');
  }

  const closingScheduled = loanSelected?.salesforceData?.closingScheduled;


  return (
    <LoanContext.Provider value={loanSelected as Loan}>
      <TasksContext.Provider value={tasks as Task[]}>
        <SelectLoanDocumentContext.Provider value={{ loanDocKey: loanDocSelect, setLoanDocKey: setLoanDocSelect }}>
          <div className='loan-details'>
            <div className='loan-details-top-bar'>
              <div className='loan-info'>
                <span className='loan-borrower-name'>{`${loanSelected.owner.name + ' ' + loanSelected.owner.lastName}`} | </span>
                <span className='loan-address'>{loanSelected.name}</span>
              </div>
              <div className='close-button-container' onClick={onClose}>
                CLOSE X
              </div>
            </div>

            <div className='loan-details-first-row'>
              <div className='loan-progress'>
                <div className='loan-info'>
                  <span className='loan-status'>UNDERWRITING | </span>
                  <span className='loan-closing-date'>Est. Closing:
                    {
                      date ?
                        <b>
                          {
                            //@ts-ignore
                            momentDate.format('MM/DD/YY')
                          }
                        </b>
                        :
                        <b>
                          {' '}
                          PENDING
                        </b>
                    }
                  </span>
                </div>
                <div className='loan-address'>
                  {loanSelected.name}
                </div>
                <div className='loan-progress-section'>
                  <div className='loan-progress-items-container'>
                    <div className='loan-progress-item-caption'>
                      Loan Progress
                    </div>
                     <LoanProgress
                              loan={loanSelected}
                              phaseSelect={phaseSelect}
                              rulesTasksCards={getTasks()}
                              handlerSetPhaseSelect={setPhaseSelect}
                              handlerSetTaskOpenModal={setTaskKey}
                              handlerSetProgressData={setProgressData}
                            />
                  </div>
                </div>

                <div className='loan-values-summary'>
                  {loanSelected?.type}
                  {loanSelected?.purpose ? (' | ' + loanSelected?.purpose) : ''}
                  {loanSelected?.ltv ? (' | ' + loanSelected?.ltv + '% loan amount') : ''}
                  {' '}
                  <span onClick={()=> window.open(loanSelected?.loanData?.sheetUrl, "_blank")}>{"Go to checklist -->"}</span>
                </div>
                <div className='loan-values-summary'>
                  {"Loan Number: " + loanSelected?.salesforceData?.loanNumber}
                  {' '}
                  <span onClick={() => {window.open(loanSelected?.salesforceData?.loanURL, '_blank');}}>{"View in Jungo -->"}</span>
                </div>
              </div>
              <div className='placeholder'></div>
              {loanSelected.owner &&
                <BorrowerCard {...loanSelected.owner} />
              }
            </div>

            <div className='loan-details-main'>
              <div className='left-column'>
                <div className='loan-summary'>
                  <div className='closing'>
                    {
                      closingDate ?
                        <>
                          {
                            closingScheduled ?
                              <div className='label'>Sch. closing</div>
                              :
                              <div className='label'>Est. closing</div>
                          }
                          <div className='calendar'>
                            <div className='date'>
                              {closingDate.monthYear}
                            </div>
                            <div className='day'>
                              {closingDate.day}
                            </div>
                          </div>
                          {
                            closingScheduled ?
                              <div className='caption'>Closing <b>IS</b> scheduled</div>
                              :
                              <div className='caption'>Closing <b>NOT</b> scheduled</div>
                          }
                        </>
                        :
                        <>
                          <div className='label'>Est. closing</div>
                          <div className='calendar disabled'>
                            <div className='date'>
                              PENDING
                            </div>
                            <div className='day'>
                              00
                            </div>
                          </div>
                          <div className='caption'>Closing <b>NOT</b> scheduled</div>
                        </>
                    }
                  </div>
                  <ContentBox {...contactData(loanSelected.salesforceData)} />
                  </div>
                <LoanOption {...{
                  rate: loanSelected?.loanData?.selectedOption?.rate,
                  loanTerm: loanSelected?.loanData?.selectedOption?.loanTerm,
                  monthlyPayment: formatterCurrency(loanSelected?.loanData?.selectedOption?.monthlyPayment),
                  totalLoanAmount: formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount),
                  ltv: loanSelected?.loanData?.selectedOption?.ltv,
                }} />
                {contentBoxes(loanSelected).map((box) => (
                  <ContentBox
                    key={box.label}
                    label={box.label}
                    content={box.content}
                  />
                ))}
                <div className='content-box'>
                    <div className='label'>
                      Notes
                    </div>
                    <div className='content'>
                      <ReactQuill
                        value={loanSelected?.salesforceData?.notes || ''}
                        readOnly={true}
                        theme={'bubble'}
                        style={{ 
                          height: 420, 
                          display: loanSelected?.salesforceData?.notes ? 'block' : 'none' 
                        }}
                      />
                    </div>
                  </div>
              </div><div className='right-column'>
                <BorrowerTasks />
                {tasks &&
                  <LoanDocuments tasks={tasks as Task[]} />
                }
                <div className='approve-loan'>
                  Approve loan
                </div>
              </div>
            </div>
          </div>
        </SelectLoanDocumentContext.Provider>
      </TasksContext.Provider>
    </LoanContext.Provider>
  );
};

/* Content Box */
type Item = { field: string, value: string | React.ReactNode, extra?: Item }
interface IContentBoxProps {
  label: string;
  content: Item[]
}

const ContentBox: React.FC<IContentBoxProps> = (props) => {
  const { label, content } = props;

  return (
    <div className='content-box'>
      <div className='label'>
        {label}
      </div>
      <div className='content'>
        {
          content.map(({ field, value, extra }, index) => (
            <div key={index} className='item'>
              <div className='item-row'>
                <div className='field'>{field}</div>
                <div className='value'>{value}</div>
              </div>
              {
                extra &&
                <div className='item-row extra'>
                  <div className='field'>{extra.field}</div>
                  <div className='value'>{extra.value}</div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

/* Borrower Tasks */
interface IBorrowerTasksProps {
  // Define your props here
}

const BorrowerTasks: React.FC<IBorrowerTasksProps> = (props) => {

  const tasks: Task[] = useContext(TasksContext);
  const loan: any = useContext(LoanContext);

  const { data: filesPending } = useDownloadFilePending({
    variables: { loanId: loan._id }
  });

  const [filterSelected, setFilterSelected] = useState<string>('all');
  const [loadingDonwload, setLoadingDownload] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const createAndDownloadZip = async (taskId?: string, zipname?: string) => {
    if (!taskId)
      setLoadingDownload(true);

    const zip = new JSZip();

    if (filesPending) {
      const remoteFiles = filesPending?.map(async (file, index) => {
        if (!taskId || taskId === file.task._id) {
          try {
            const response = await fetch(file.url, { mode: 'cors' });
            const data = await response.blob();
            const fileName = `${index + 1}-${file.name}`; // Replace with a suitable file naming logic
            zip.file(fileName, data);
            return data;
          } catch (error) {
            console.error(`Failed to fetch ${file.url}`, error);
            return null;
          }
        }
      });

      const fetchedFiles = await Promise.all(remoteFiles);

      // Check if all fetches were successful
      if (fetchedFiles.every(file => file !== null)) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipname || 'files-in-review.zip');
          setLoadingDownload(false);
        });
      } else {
        console.error('Some files could not be fetched and were not added to the zip.');
        setLoadingDownload(false);
      }
    }
  };

  const downloadAll = () => createAndDownloadZip();

  const downloadFilesToTask = (taskId: string, taskName: string) => {
    createAndDownloadZip(taskId, `files-${taskName}.zip`);
  }

  const newTasks = tasks?.filter((item) => !item.key && !item.hidden);
  const getCountState = (state: TaskState) => tasks?.filter(task => 
    task.state === state && task.key !== 'property_access' && !task.hidden)?.length;

  const filters = [
    { label: 'All', key: 'all', count: tasks?.filter(task => task.key !== 'property_access' && !task.hidden)?.length },
    { label: 'In Progress', key: TaskState.Incomplete, count: getCountState(TaskState.Incomplete) },
    { label: 'Pending Review', key: TaskState.Review, count: getCountState(TaskState.Review) },
    { label: 'Rejected', key: TaskState.RequestError, count: getCountState(TaskState.RequestError) },
    { label: 'Approved', key: TaskState.Approved, count: getCountState(TaskState.Approved) },
  ];

  const TaskFilter = (loanSelected: any, tasks: any) => {
    const { type, purpose } = loanSelected;

    if (!tasks) return [];

    const filteredTasks = tasks.filter((task: any) => {
      if (purpose !== "Purchase" && task.key === "purchase") return false;
      if (type !== "Rental" && task.key === "dscr") return false;
      if (type === "Rental" && (task.key === "track_record" || task.key === "investment_experience" || task.key === "construction_budget")) return false;
      return true;
    })

    return filteredTasks;
  }

  //@ts-ignore
  const tasksAdmin = TaskFilter(loan, tasks)?.filter(item => !!item.key && item.key !== "property_access" && !item.hidden);
  // @ts-ignore
  tasksAdmin?.sort((a, b) => {
    const indexA = RulesTasksCards.findIndex(item => item.key === a.key);
    const indexB = RulesTasksCards.findIndex(item => item.key === b.key);
    return indexA - indexB;
  });

  return (
    <div className='borrower-tasks'>
      <div className='title'>
        Borrower Tasks
      </div>
      <AddNewTask />
      <div className='borrower-tasks-status-container'>
        {filters.map(filter => (
          <StatusBadge
            title={filter.label}
            count={filter.count}
            isSelected={filterSelected === filter.key}
            onClick={() => setFilterSelected(filter.key)}
          />
        ))}
      </div>
      <div className='borrower-tasks-actions'>
        {/*<div><EyeIcon /> VIEW DOC LIST</div>*/}
        <div onClick={downloadAll}>
          {loadingDonwload && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
          {!loadingDonwload && <img alt="download" src={iconDonwloadSvg} />}
          DOWNLOAD ALL
        </div>
        <div onClick={() => setExpandAll(!expandAll)}>
          {expandAll &&
            <>
              <IconMinimize />
              CLOSE EXPAND ALL
            </>
          }
          {!expandAll &&
            <>
              <ExpandIcon />
              EXPAND ALL
            </>
          }
        </div>
      </div>
      <div className='borrower-tasks-list'>
        {tasksAdmin?.map((task: any) => task.key && (
          <BorrowerTask
            taskKey={task.key}
            label={task.name}
            filterSelected={filterSelected}
            onClickDownloadFiles={downloadFilesToTask}
            expandAll={expandAll}
          />
        ))}
        {newTasks?.map((item, index) => (
          <BorrowerTask
            key={index}
            taskKey={item._id}
            label={item.name}
            filterSelected={filterSelected}
            onClickDownloadFiles={downloadFilesToTask}
            expandAll={expandAll}
            isTaskNew />
        ))}
      </div>
    </div>
  );
};

export default LoanDetails;