
import { Task } from '../../gql/graphql';
import { useGetTaskResponseComments } from '../../services/task/querys';
import SectionError from './SectionError/SectionError';

interface Props {
  task: Task;
  lengthIssuesTotal?: number;
}

const SectionIssues = ({
  task,
  lengthIssuesTotal
}: Props) => {
  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId: task._id }
  });
  return (
    <section>
      {taskComments && taskComments?.length > 0 && (
        <div className="mt-20">
          {lengthIssuesTotal && 
            <h4 className="h4 h4-error">
              {taskComments?.length}/{lengthIssuesTotal} accounts have issues
            </h4>
          }
          {taskComments?.map((comment: any) => (
            <div className="mt-12" key={comment._id}>
              <SectionError
                task={task}
                issue={comment}
                title={comment.title}
                subtitle={comment.subtitle}
                description={comment.description}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
export default SectionIssues;
