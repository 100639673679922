import { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'antd';

interface Props {
  label: string;
  onChange: any;
  disabled?: boolean;
  defaultChecked: any;
}

const CheckboxBasic = ({ label, onChange, disabled, defaultChecked }: Props) => {
  return (
    <div className='checkbox-basic'>
    <Checkbox 
      onChange={onChange} 
      disabled={disabled}
      defaultChecked={defaultChecked}
    >
      {label}
    </Checkbox>
    </div>
  );
};
export default CheckboxBasic;