import React, { useContext, useEffect, useState } from 'react';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import { Button, DatePicker, Divider, Form, Input, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { File, Task, TaskState } from 'gql/graphql';
import moment from 'moment';
import {
  formatterSsn as utilsFormatterSsn,
  formatterPhone as utilsFormatterPhone
} from '../../../../utils/formatters/formatter';
import ModalSubmitProfile from './ModalSubmitProfile';
import { Ownership } from './CardOwnership';
import { useDeleteFileResponse } from '../../../../services/task/mutations';
import InputBorderNone from '../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import InputUploadedFile from '../../components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import UploadDocs from '../../components/Atoms/UploadDocs/UploadDocs';
import { DATE_FORMAT } from '../../../../utils/constansts/dates.constanst';

interface Props {
  ownership: Ownership;
  hiddenSectionViewProfile?: boolean;
  hiddenTextEdit?: boolean;
  disabledForm?: boolean;
  hiddenSubmitChanges?: boolean;
  hiddenColorsState?: boolean;
  isModalSubmit?: boolean;
  saveEdit?: boolean;
  fetching: boolean;
  saveChanges: Function;
}

const FormEdit = ({
  ownership,
  hiddenSectionViewProfile,
  hiddenTextEdit,
  disabledForm,
  hiddenSubmitChanges,
  hiddenColorsState,
  saveChanges,
  isModalSubmit,
  saveEdit,
  fetching
}: Props) => {
  const [form] = Form.useForm();
  const {
    fetching: loadingDeleteFile,
    execute: _deleteFile
  } = useDeleteFileResponse();

  const [listFileUpload, setListFileUpload] = useState<any>([]);

  useEffect(() => {
    form.resetFields();
    setListFileUpload([]);
  }, [ownership]);

  const getInitialValues = () => ({
    ...ownership,
    dateBirth: ownership.dateBirth? moment(ownership.dateBirth): null,
    ssn: utilsFormatterSsn(ownership.ssn),
    phone: utilsFormatterPhone(ownership.phone)
  });

  const formatterSsn = (e: any) => {
    form.setFieldValue('ssn', utilsFormatterSsn(e.target.value));
  };

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const isFormDisabled = () => {
    const isFieldsValidate = Object.values(form.getFieldsValue()).every(
      (value) => !!value
    );
    
    return (
      !isFieldsValidate ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
      (listFileUpload.length === 0 && ownership.files.length === 0)
    );
  };

  const deleteFile = (file: File) => {
    _deleteFile({ fileKey: file.key, taskResponseId: ownership.key });
  };

  return (
    <Form
      form={form}
      style={{ marginTop: hiddenSectionViewProfile ? 10 : 0 }}
      name="edit"
      autoComplete="off"
      className="form-edit"
      requiredMark="optional"
      colon={false}
      initialValues={getInitialValues()}
    >
      <Form.Item
        label="SSN"
        name="ssn"
        className="ssn"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: '' },
          { min: 11, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="239-23-2323"
          height={20}
          className="bold-lith"
          disabled={disabledForm}
          onChange={formatterSsn}
        />
      </Form.Item>

      <Form.Item
        label="Date of birth"
        name="dateBirth"
        className="datebirth"
        validateTrigger="onBlur"
        rules={[{ required: true, message: '' }]}
      >
        <DatePicker
          placeholder="12/31/1997"
          className="input input-transparent"
          disabled={disabledForm}
          format={DATE_FORMAT}
        />
      </Form.Item>

      {!hiddenTextEdit && (
        <span className="textedit">
          {!saveEdit && (
            <>
              <EditOutlined />
              EDIT
            </>
          )}
          {saveEdit && (
            <>
              <img src={fileCheckSvg} alt="upload" className="upload" />
              <span style={{ color: '#6B956E' }}>EDITS SAVED</span>
            </>
          )}
        </span>
      )}

      <Form.Item
        label="Phone number"
        name="phone"
        className="phone"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: '' },
          { min: 12, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="329-230-2390"
          height={20}
          className="bold-lith"
          disabled={disabledForm}
          onChange={formatterPhone}
        />
      </Form.Item>

      <Form.Item
        label="Email address"
        name="email"
        className="email"
        validateTrigger="onBlur"
        rules={[{ required: true, type: 'email', message: '' }]}
      >
        <InputBorderNone
          type="email"
          placeholder="email@gmail.com"
          height={20}
          className="bold-lith"
          disabled={disabledForm}
        />
      </Form.Item>

      <Form.Item
        label="Home address"
        name="address"
        className="address"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: '' },
          { min: 3, message: '' },
          { max: 50, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="356 14th St. San Francisco, CA 93201"
          height={20}
          className="bold-lith"
          disabled={disabledForm}
        />
      </Form.Item>

      <div className="identification">
        <label>Proof of identification</label>
        {ownership.files?.map((file: File, index: number) => (
          <InputUploadedFile
            key={index}
            file={file}
            isActionSeeFile
            deleteFile={ownership.state === TaskState.RequestError? deleteFile: undefined}
            loadingDeleteFile={loadingDeleteFile}
            state={
              !hiddenColorsState
                ? (ownership.state.toLocaleLowerCase() as TaskState)
                : TaskState.Draft
            }
          />
        ))}
        {(!ownership.files || ownership.files.length === 0) && (
          <UploadDocs
            numberOfFiles={1}
            listFileUpload={listFileUpload}
            setListFileUpload={setListFileUpload}
          />
        )}
      </div>

      {!hiddenSubmitChanges && (
        <Form.Item className="submit" shouldUpdate>
          {() =>
            isModalSubmit ? (
              <ModalSubmitProfile
                fetching={fetching}
                disabled={isFormDisabled()}
                onClick={() => saveChanges(form.getFieldsValue(), listFileUpload, 'submit')}
              />
            ) : (
              <Button
                className="button button-submit"
                loading={fetching}
                disabled={isFormDisabled()}
                onClick={() => saveChanges(form.getFieldsValue(), listFileUpload,'submit')}
              >
                Save changes
              </Button>
            )
          }
        </Form.Item>
      )}
    </Form>
  );
};
export default FormEdit;
