export const ENTITY_DOCS_RESPONSES: any = {
    'EIN_LETTER': 'ein-letter',
    'CERTICIATE_ORGANITZATION': 'certificate-organization',
    'OPERATING_AGREEMENT': 'operating-agreement',
    'CERTIFICATE_GOOD': 'certificate-good-standing'
}

export const ENTITY_DOCS_LABEL: any = {
    'EIN_LETTER': 'EIN letter',
    'CERTICIATE_ORGANITZATION': 'Certificate of Good Standing',
    'OPERATING_AGREEMENT': 'Operating Agreement',
    'CERTIFICATE_GOOD': 'Certificate of Good Standing'
}