// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 24px;
  border: 1px solid var(--300-grey, #DFE4EB);
  background: var(--20, #EBF6FF);
}
.top-bar > * {
  display: inline-block;
}
.top-bar .logo {
  cursor: pointer;
}
.top-bar > .internal {
  color: var(--blue-80, #355C7D);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 18.4px */
  text-transform: capitalize;
}
.top-bar > .button-blue-outlined {
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #355C7D;
  padding: 9px 16px;
  color: var(--blue-80, #355C7D);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.constlending-logo > .constlending-logo-blue {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/TopBar/TopBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EAEA,WAAA;EACA,sBAAA;EAEA,kBAAA;EAEA,0CAAA;EACA,8BAAA;AAFF;AAIE;EACE,qBAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AAJJ;AAOE;EACE,eAAA;EAEA,kBAAA;EACA,yBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AANJ;;AAWE;EACE,iBAAA;AARJ","sourcesContent":[".top-bar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  width: 100%;\n  box-sizing: border-box;\n  \n  padding: 12px 24px;\n\n  border: 1px solid var(--300-grey, #DFE4EB);\n  background: var(--20, #EBF6FF);\n\n  > * {\n    display: inline-block;\n  }\n\n  .logo {\n    cursor: pointer;\n  }\n\n  >.internal {\n    color: var(--blue-80, #355C7D);\n    text-align: center;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 115%; /* 18.4px */\n    text-transform: capitalize;\n  }\n\n  >.button-blue-outlined {\n    cursor: pointer;\n    \n    border-radius: 2px;\n    border: 1px solid #355C7D;\n    padding: 9px 16px;\n    color: var(--blue-80, #355C7D);\n    text-align: center;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n  }\n}\n\n.constlending-logo {\n  > .constlending-logo-blue {\n    margin-right: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
