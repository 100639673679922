import React, { createContext, useContext, useEffect, useState } from 'react';
import iconCortPng from 'assets/images/cort.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Task } from 'gql/graphql';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import { ExpandIcon } from '../BorrowerTasks';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';

export const PayAppraisalTaskContext = createContext<Task | undefined>(undefined);

interface PayAppraisalProps {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}

const PayAppraisal = ({ task, visible, handlerClose }: PayAppraisalProps) => {
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (taskKey === 'pay_appraisal') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if(!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);
  
  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      header={{
        title: <span>APPROVAL | PAY APPRAISAL</span>,
        actionLeft: (
          <img
            src={iconCortPng}
            alt="icon"
            className="paperPng"
          />
        ),
        actionRight: (
          <Button
            className="button button-transparent"
            onClick={() => onHelpDocs()}
          >
            <QuestionCircleOutlined />
            help docs
          </Button>
        )
      }}
      animationDirection="onRight"
      dots={false}
      stepsTotal={1}
      currentStep={step}
      setStep={setStep}
      footer={
        <div className="text-footer mt-20">
          <p className="placeholder">
            My appraisal <span className="bold-label">is not paid</span>
          </p>
        </div>
      }
    >
      <PayAppraisalTaskContext.Provider value={task}>
        <section className="children PayAppraisal">
          <Step1
            task={task}
            closeTask={() => setModalTaskVisible(false)}
          />
        </section>
      </PayAppraisalTaskContext.Provider>
    </ModalTask>
  );
};
export default PayAppraisal;
