import { useContext } from "react";
import { ITaskItemProps, TaskItemList } from "./TaskItemList";
import { BorrowerDetailViewContext } from "../../Private";
import { useNavigate } from "react-router-dom";

export type LoanStatus = 'review' | 'approval';
export interface ILoanListItemProps {
  owner: any;
  progress: any;
  name: string;
  tasks: ITaskItemProps[];
  onClick?: () => void;
}

export const LoanListItem: React.FC<ILoanListItemProps> = (props) => {
  const navigate = useNavigate();
  const { setHandlerVisible: setBorrower }: any = useContext(BorrowerDetailViewContext);

  const { owner, name, progress, tasks, onClick } = props;

  const redirectBorrower = (ownerId: string) => {    
    setBorrower(ownerId);
    navigate('/borrowers');
  }

  const approval = progress?.find((item: any) => item.key === 'approval')
  const dueDiligence = progress?.find((item: any) => item.key === 'due-diligence')

  let stateNow = ''
  
  if(approval?.state === 'not-started')
    stateNow = 'Pre-approval'
  else if(approval?.state !== 'not-started') 
    stateNow = 'Approval Docs & Term Sheet';
  else if(approval?.state === 'complete')
    stateNow = 'Property Evaluation & Due Diligence';
  else if(dueDiligence?.state === 'complete')
    stateNow = 'Final Approval';
  
  return (
    <div className='loan-list-item'>
      <div className='loan-status-badge-container' onClick={onClick}>
        <div className='loan-status-badge'>
          {stateNow}
        </div>
      </div>
      <div className='borrower-summary-container' onClick={() => redirectBorrower(owner._id)}>
        <div className='borrower-summary'>
          <div className='caption'>Borrower</div>
          <div className='borrower-name'>{owner.name + ' ' + owner.lastName}</div>
        </div>
      </div>
      <div>
        <div className='address-summary' onClick={onClick}>
          <div className='caption'>Address</div>
          <div className='address'>{name}</div>
        </div>
      </div>
      <div>
        <div className='task-summary'>
          <div className='caption'>Task Completion</div>
          <div className='task-item-container'>
            {tasks?.map((task: any, index) => (
              task.key !== 'property_access' &&
              !task.hidden &&
                <TaskItemList {...task} taskKey={task.key} key={index} handlerOpenDetail={onClick}/>
            ))}
          </div>
        </div>
      </div>
      <div onClick={onClick}>
        <div className='action-container'>
          {"View loan details -->"}
        </div>
      </div>
    </div>
  )
}
