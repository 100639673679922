import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Input } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import calendarPng from 'assets/images/calendar.png';
import InnerApproveRejectSection from "./components/InnerApprovalRejectSection/InnerApprovalRejectSection";
import ConfirmationButton from "./components/ConfirmationButton/ConfirmationButton";
import SelectSection from "./components/SelectSection/SelectSection";
import ModalEndSubmit from "../BorrowerTask/components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit";
import { useCreateRejectResponse } from "../../services/task/mutations";
import { Loan, Task, TaskResponseComment } from "../../gql/graphql";
import { useGetTaskResponseComments, useGetTaskResponsesByTask, useGetTasksByLoan } from "../../services/task/querys";
import InputBorderNone from "../BorrowerTask/components/Atoms/Inputs/InputBorderNone/InputBorderNone";
import { LoanContext } from "../../scenes/Private/LoanList/LoanDetails/LoanDetails";

const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    [{ "color": ["#000000", "#e60000", "#ff9900", "#008a00", "#0066cc", "#9933ff", "#f06666", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", 'custom-color'] }],
  ]
};

interface RejectSectionProps {
  taskResponseId: string;
  taskId: string;
  borrowerName: string;
  taskName: string;
  dataOrDocuments: any[],
  rejectionOptions: { value: string, label: string }[],
  minimumLiquidity?: any;
  onSubmit?: any;
  issue?: TaskResponseComment;
}

const RejectSection: React.FC<RejectSectionProps> = (props) => {

  const loan: Loan | any = useContext(LoanContext);

  const {
    taskResponseId,
    taskId,
    issue,
    borrowerName,
    taskName,
    dataOrDocuments,
    rejectionOptions,
    minimumLiquidity,
    onSubmit: onSubmitRight
  } = props;

  const { execute: createRejectResponse } = useCreateRejectResponse();
  const { reexecute: getTasksByLoan } = useGetTasksByLoan({ variables: { loanId: loan._id }, pause: true });
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({ variables: { findResponsesByTask: { taskId } }, pause: true });
  const { reexecute: getTaskResponsesComments } = useGetTaskResponseComments({ variables: { taskId }, pause: true });

  const [rejectCodeSelect, setRejectCodeSelect] = useState<string | any>();
  const [dataOrDocumentSelected, setDataOrDocumentSelected] = useState<any[]>([]);
  const [content, setContent] = useState<string | undefined>("");
  const [showModal, setShowModal] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState<any>();
  const [title, setTitle] = useState<any>();
  const [rejectEntireSection, setRejectEntireSection] = useState<string[] | null>();
  
  const isSubmitDisabled = !rejectCodeSelect || !content || dataOrDocumentSelected.length === 0 || (minimumLiquidity && approvedAmount === null);

  useEffect(() => {
    if (issue)
      setApprovedAmount(issue.metadata.approvedAmount)
  }, [issue])

  const onSubmit = async () => {
    let documentsKeys = [];

    if (dataOrDocumentSelected.length === 1 && dataOrDocumentSelected[0] === 'Reject entire section')
      documentsKeys = dataOrDocuments.map(item => item.key);
    else
      for (const documentName of dataOrDocumentSelected) {
        if (documentName !== 'Text/data') {
          const document = dataOrDocuments.find(item => item.name === documentName);
          documentsKeys.push(document.key);
        }
      }

    setApprovalLoading(true);

    await createRejectResponse({
      taskResponseId,
      rejectionCodeKey: rejectCodeSelect,
      dataOrDocumentsKeys: documentsKeys,
      markdown: content || '',
      title: minimumLiquidity?.metadata?.nameAccount || title,
      description: getDescriptionMarkdown() || minimumLiquidity?.metadata?.nameAccount || title,
      subtitle: '',
      metadata: {
        approvedAmount,
        rejectedAmount: minimumLiquidity?.metadata?.balance - approvedAmount || 0,
        dataOrDocumentSelected,
        rejectCodeSelect
      }
    });
    setShowModal(false);
    getTaskResponses({ requestPolicy: 'network-only' });
    getTaskResponsesComments({ requestPolicy: 'network-only' });
    getTasksByLoan({ requestPolicy: 'network-only' });
    setApprovalLoading(false);
    setTimeout(() => onSubmitRight(), 100);
  }

  const getDescriptionMarkdown = () => {
    const tempElement: any = document.createElement('div');
    tempElement.innerHTML = content;
    const textoPlano = Array.from(tempElement.childNodes)
      .filter((node: any) =>
        node.nodeType === Node.TEXT_NODE || (node.nodeName.toLowerCase() === 'p' && node.textContent.trim())
      )
      .map((node: any) => node.textContent.trim())
      .join(' ')
      .trim();

    return textoPlano;
  }

  const optionsDataOrDocumentos = () => {
    let options: any = ['Text/data'];

    if (dataOrDocuments)
      options = [...options, ...dataOrDocuments?.map(item => item.name)];

    return options;
  }

  return (
    <InnerApproveRejectSection
      sectionAccount={
        <>
          {(minimumLiquidity || issue) &&
            <div className="header-section">
              <span className="body-regular">
                {minimumLiquidity && 'Account: '}
                <b className="body-regular-bold">{minimumLiquidity?.metadata?.nameAccount || issue?.title}</b>
              </span>

              {minimumLiquidity &&
                <InputBorderNone
                  type="number"
                  formatter={"currency"}
                  placeholder="Enter approved amount"
                  color={"bold-lith"}
                  value={approvedAmount}
                  onChange={(v: number) => setApprovedAmount(v)}
                  disabled={!!issue}
                />
              }
            </div>
          }

          {!minimumLiquidity && !issue &&
            <div style={{ marginBottom: 20 }}>
              <InputBorderNone
                placeholder="Enter doc name (optional)"
                color={"bold-lith"}
                onChange={(e: any) => setTitle(e.target.value)}
                disabled={!!issue}
              />
            </div>
          }
        </>
      }
      confirmationModal={
        <ModalEndSubmit
          visible={showModal}
          setVisible={setShowModal}
          modal={{
            title: 'Are you sure you want to reject these text sections and/or docs?',
            description: (
              <h4 className="h4 h4-w4">
                The borrower will be notified of the rejection immediately
              </h4>
            ),
          }}
          buttonSubmit={() =>
            <Button
              className="button button-modal-ok"
              onClick={onSubmit}
              loading={approvalLoading}
            >
              Reject
            </Button>
          }
          className="modal-end-submit--white"
          icon={calendarPng}
        />
      }
      title={
        <div className="approval-section-title">
          <h2 className="h2 title">
            How can <span className="bold">{borrowerName}</span> fix <span className="blue-bold">{taskName}</span>?
          </h2>
        </div>
      }
      selectSectionContent={
        <React.Fragment>
          <SelectSection
            label="Rejection code"
            placeholder="Select rejection reason(s)"
            options={rejectionOptions}
            onSelect={(select) => setRejectCodeSelect(select)}
            value={issue?.metadata.rejectCodeSelect}
            disabled={!!issue}
          />
          <SelectSection
            label="Data or document"
            placeholder="Select document(s) or data"
            options={optionsDataOrDocumentos()}
            onSelect={(v) => {
              setDataOrDocumentSelected(v);
              setRejectEntireSection(undefined);
            }}
            value={issue?.metadata.dataOrDocumentSelected || rejectEntireSection}
            isMultiple
            disabled={!!issue}
            optionEnd={{
              label: 'Reject entire section',
              onClick: () => {
                setDataOrDocumentSelected(optionsDataOrDocumentos());
                setRejectEntireSection(optionsDataOrDocumentos());
              }
            }}
          />
        </React.Fragment>
      }
      mdSectionContent={
        <div className='mt-10'>
          <ReactQuill
            modules={modules}
            value={content}
            onChange={setContent}
            theme={'snow'}
            style={{ 
              height: 340, 
              display: issue?.markdown ? 'none' : 'block' 
            }}
            placeholder="Pre-filled text based on rejection code"
          />
          <ReactQuill
            value={issue?.markdown || ''}
            readOnly={true}
            theme={'bubble'}
            style={{ 
              height: 420, 
              display: issue?.markdown ? 'block' : 'none' 
            }}
          />
        </div>
      }
      submitButton={
        <ConfirmationButton
          text={"Reject"}
          disabledText={"Fill out information to reject"}
          isDisabled={isSubmitDisabled}
          isFetching={false}
          onClick={() => setShowModal(true)}
        />
      }
    />
  )
}


export default RejectSection;