import React, { createContext, useEffect, useState } from 'react';
import { Button } from 'antd';

interface Props {
  visible: boolean;
  handlerSetVisibile: Function;
  children?: React.ReactElement;
}

const RightScenes = (props: Props) => {
  useEffect(() => {
    if (props.visible) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [props.visible])

  const closeModal = () => {
    props.handlerSetVisibile(false);
  }
  
  if(!props.visible)
    return null;

  return props.visible && (
    <div className="right-scenes">
      <div className={`right-scenes-content`} style={{ width: '70%' }}>
        <div className="content-modal">
          <div className="modal-review-task__content">
            <div className="modal-title">
              <div className="flex-btw">
                <h3 className="body-card body-card-w6">
                </h3>
                <Button
                  className="body-card"
                  onClick={closeModal}
                >
                  Close X
                </Button>
              </div>
            </div>
          </div>
          <div className="modal-container">
            <div className="modal-cards modal-cards--full">
              <div className="modal-cards-mx-w">
                <div className="flex-btw">
                </div>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="button-close-mask"
        style={{ width: '30%' }}
        onClick={closeModal}
      />
    </div>

  );
};
export default RightScenes;
