import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

interface Props {
  items: { key: string, label: string }[];
  label?: string | any;
  onClickSelect: Function;
  disabled?: boolean;
  className?: string;
  selected?: any;
}

const DropdownBasic = ({ items, label, onClickSelect, disabled, className, selected }: Props) => {

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onClickSelect(e.key);
  };

  const labelSelected = () => 
    items.find(item => item.key === selected)?.label || label;

  return (
    <Dropdown
      className={"DrodownBasic " + className}
      menu={{ items, onClick: handleMenuClick }}
      placement="bottom"
      overlayClassName="DrodownBlue-blue"
      disabled={disabled}
    >
        <Button type="link" className={`button`}>
          <span style={{ width: '92%', textAlign: 'left', fontWeight: selected? 600: 400 }}>
            {labelSelected()}
          </span>
          <DownOutlined />
        </Button>
    </Dropdown>
  );
};
export default DropdownBasic;
