import React, { useState } from 'react';
import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from '../../components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Props {
  fetching: boolean;
  disabled: boolean;
  onClick: any;
}

const ModalSubmitProfile = (props: Props) => {
  const [submitProfile, setSubmitProfile] = useState<boolean>(false);

  return (
    <ModalEndSubmit
      visible={submitProfile}
      setVisible={setSubmitProfile}
      buttonAction={{ label: 'Submit profile', loading: props.fetching }}
      modal={{
        title: 'Ready to submit this guarantor profile?',
        description: (
          <h4 className="h4 h4-w4">
            After submitting, you’ll have to contact us if you want to make
            changes to the property access details
          </h4>
        )
      }}
      className="modal-end-submit--white"
      icon={calendarPng}
      buttonSubmit={
        <Button
          className="button button-modal-ok"
          onClick={() => {
            props.onClick();
            setSubmitProfile(false);
          }}
          loading={props.fetching}
          disabled={props.disabled}
        >
          Yes, submit it
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => setSubmitProfile(false)}
        >
          I need to make some changes
        </Button>
      }
      isCheckBox={false}
    />
  );
};
export default ModalSubmitProfile;
