import React, { useContext, useEffect, useState } from 'react';

import editBlue from 'assets/images/edit-blue.png';
import { Button, Upload, UploadFile, UploadProps } from 'antd';
import InputBorderNone from '../../../../../components/BorrowerTask/components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { useCreateUserReport } from '../../../../../services/user/mutations';
import { useGetUserReports } from '../../../../../services/user/querys';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';

interface Props {
  reportKey: string;
  title: string;
  borrower: any;
  isCreditScore?: boolean;
  buttons: { download: string, upload: string };
}

const CardReport = ({ reportKey, title, borrower, isCreditScore, buttons }: Props) => {

  const { data: userReports, reexecute: getUserReports } = useGetUserReports({ 
    variables: { userId: borrower._id },
    pause: true
  });
  const { execute: createUserReport } = useCreateUserReport();
  
  const [report, setReport] = useState<UploadFile>();
  const [isEdit, setEdit] = useState(false);
  const [creditScore, setCreditScore] = useState();
  const [expire, setExpire] = useState();
  const [cardReport, setCardReport] = useState<any>();
  
  useEffect(() => {
    getUserReports({ requestPolicy: 'network-only' });
  }, []);

  useEffect(() => {
    const cardReport = userReports?.find(report => report.key === reportKey);

    if(cardReport){
      setCardReport(cardReport);
      setCreditScore(cardReport.metadata.creditScore);
      setExpire(cardReport.metadata.expire);
    } 
  }, [userReports]);

  const onClickEdit = async () => {
    if(isEdit) {
      await createUserReport({ 
        _id: cardReport?._id,
        key: reportKey, 
        metadata: { creditScore, expire },
        user: borrower._id,
        file: report
      });
      
      getUserReports({ requestPolicy: 'network-only' });
      setReport(undefined);
    }
    
    setEdit(!isEdit);
  }

  const props: UploadProps = {
    beforeUpload: (file) => {
      setReport(file);
      setEdit(true);
      return false;
    },
    maxCount: 1,
    showUploadList: false,
    accept: 'application/pdf,image/*',
  };

  return (
    <div className='card-profile-generic card-report' style={{ paddingTop: isCreditScore? 30: 16 }}>
      <div className='card-report-head'>
        <h1 className='card-title'>{title}</h1>
        {isCreditScore && 
          <>
            {isEdit && 
              <InputBorderNone 
                type="number" 
                placeholder="Credit score" 
                color="bold-lith" 
                className="credit-score-input" 
                defaultValue={cardReport?.metadata?.creditScore}
                onChange={(v: any) => setCreditScore(v)}
              />
            }
            {!isEdit && <h1 className='card-title'>{cardReport?.metadata?.creditScore}</h1> }
          </>
        }
      </div>
      <div className='flex-btw mt-10'>
        {cardReport?.file && 
          <Button className="button button-submit" style={{ height: 40 }} onClick={() => window.open(cardReport?.file?.url, '_blank')}>
            {buttons.download}
          </Button>
        }
      </div>
      <div className='flex-btw mt-10'>
        {isEdit && 
          <InputBorderNone 
            type="date" 
            placeholder="Expires" 
            color="bold-lith" 
            defaultValue={cardReport?.metadata?.expire}
            onChange={(v: any) => setExpire(v.target.value)}
          />
        }
        {!isEdit && <span className='date-expires'>Expires {cardReport?.metadata.expire}</span>}
        <Upload
            name="report"
            className="upload-report"
            {...props}
          >
            <span className='button button-link-blue upload-report-span'>{report?.name || buttons.upload}</span>
          </Upload>
      </div>

      <Button className='button button-transparent button-edit' onClick={onClickEdit}>
        <img src={editBlue} className='edit' alt='edit' />
        {isEdit ? 'SAVE' : 'EDIT'}
      </Button>
    </div>
  )
}

export default CardReport;