import React from "react";
import "./SectionTitle.scss";

export interface SectionTitle {
  borrowerName: string;
  taskName: string;
}

export const ApprovalSectionTitle: React.FC<SectionTitle> = (props) => {
  const {
    borrowerName,
    taskName
  } = props;
  
  return (
    <div className="approval-section-title">
      <h2 className="h2 title">
        Which parts of <span className="bold">{borrowerName}&apos;s</span> <span className="blue-bold">{taskName}</span> would you like to approve?
      </h2>
    </div>
  )
}