import { Button } from 'antd';
import { useContext } from 'react';
import { Task, TaskResponseComment } from '../../../gql/graphql';
import { ModalTaskRightContext } from '../../BorrowerTask/components/Organisms/Modals/ModalTask/ModalTask';

interface SectionErrorProps {
  task: Task;
  issue: TaskResponseComment;
  title: string;
  subtitle: string;
  description: string;
}

const SectionError = ({ task, issue, title, description, subtitle }: SectionErrorProps) => {
  const { setHandler }: any = useContext(ModalTaskRightContext);
  
  return (
    <div className="SectionError">
      <div className="flex-btw">
        <h4 className="body-regular body-regular-w6">{title}</h4>
        <h4 className="body-regular">{subtitle}</h4>
      </div>
      <div className='description'>
        <p className="body-regular">
          <span className='body-regular-w6' style={{ color: 'black' }}>
            {issue.rejectionCode.label}.{' '}
          </span>
          {description}
        </p>
      </div>
      <div className="flex-end">
        <Button 
          type="link" 
          className="button button-transparent" 
          style={{ width: 90 }}
          onClick={() => 
            setHandler(true, { 
              view: 'rejection', 
              task,
              files: issue?.metadata?.dataOrDocumentSelected?.map((item: string) => ({ name: item })),
              issue,
              minimumLiquidity: task.key === 'minimium_liquidity'? task: null
            })}
        >
          See details
        </Button>
      </div>
    </div>
  );
};
export default SectionError;
