import React, { useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import ButtonLink from '../../../components/Atoms/Buttons/ButtonLink/ButtonLink';

interface Props {
  title: string;
  accountKey: string;
  buttonLabel: string;
  text: React.ReactElement;
  infoExpand: React.ReactElement;
  handlerBalanceDraft: Function;
  handlerCreateAccount: Function;
  balancePercentage?: number;
}

const SectionNewBank = ({
  title,
  buttonLabel,
  text,
}: Props) => {
  
  const [isUpdateActive, setUpdateActive] = useState(false);

  const openLink = () => {
    setUpdateActive(!isUpdateActive);
  }

  return (
    <div className="SectionNewBank">
      <h4 className="h4">{title}</h4>
      {text}
      <ButtonLink
        title={buttonLabel}
        icon={<PlusCircleOutlined />}
        onClick={openLink}
      />
    </div>
  );
};

export default SectionNewBank;
