import { LoanDetailContext } from "../../scenes/Private/Private";
import { useGetOAuthToken } from "../../services/auth/mutations";
import React, { useContext, useEffect } from "react"

interface BorrowerRedirectWrapperProps {
  children: React.ReactNode;
}

export const BorrowerRedirectWrapper: React.FC<any> = ({ children }) => {
  const { loan }: any = useContext(LoanDetailContext);
  
  const { execute: getOAuthToken } = useGetOAuthToken();

  const onClickHandler = () => {
    const borrowerId = loan?.owner?._id;
    const loanId = loan?._id;

    getOAuthToken({
      userId: borrowerId
    })
      .then(({ data }) => {
        if (data) {
          const oAuthToken = data.getOAuthToken;
          window.open(`https://sbd.constlending.com/?oauth=${oAuthToken}&loanId=${loanId}`, '_blank');
        }
      })
  }

  return (
    <div onClick={onClickHandler} style={{ cursor: "pointer" }}>
      {children}
    </div>
  )
}