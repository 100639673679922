// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-section .header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.inner-section .header-section input {
  font-style: normal;
  font-weight: 400;
  text-align: end;
}
.inner-section .quill {
  height: 350px;
}
.inner-section .quill .ql-editor strong {
  font-weight: bold;
}
.inner-section .quill .ql-editor em {
  font-style: italic;
}
.inner-section .approval-section-title > .title {
  line-height: 135%;
  font-size: 24px;
  font-weight: 600;
}
.inner-section .approval-section-title > .title > .bold {
  font-weight: 800;
}
.inner-section .approval-section-title > .title > .blue-bold {
  font-size: 24px;
  text-decoration: underline;
}
.inner-section .title-box {
  margin-bottom: 24px;
}
.inner-section .content-box {
  border: 1px solid #919cb1;
  padding: 20px 16px;
  border-radius: 10px;
}
.inner-section .content-box .select-section {
  margin-bottom: 16px;
}
.inner-section .content-box .select-section:last-of-type {
  margin-bottom: 16px;
}
.inner-section .content-box .select-section span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #2a2d3c;
}`, "",{"version":3,"sources":["webpack://./src/components/ApprovalRejectSection/components/InnerApprovalRejectSection/InnerApprovalRejectSection.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;AADN;AAKE;EACE,aAAA;AAHJ;AAKI;EACE,iBAAA;AAHN;AAKI;EACE,kBAAA;AAHN;AAQI;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;AANN;AAQM;EACE,gBAAA;AANR;AASM;EACE,eAAA;EACA,0BAAA;AAPR;AAYE;EACE,mBAAA;AAVJ;AAaE;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;AAXJ;AAaI;EACE,mBAAA;AAXN;AAaM;EAEE,mBAAA;AAZR;AAeM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAbR","sourcesContent":[".inner-section {\n\n  .header-section {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 30px;\n\n    input {\n      font-style: normal;\n      font-weight: 400;\n      text-align: end;\n    }\n  }\n\n  .quill {\n    height: 350px;\n\n    .ql-editor strong{\n      font-weight: bold;\n    }\n    .ql-editor em{\n      font-style: italic;\n    }\n  }\n\n  .approval-section-title {\n    >.title {\n      line-height: 135%;\n      font-size: 24px;\n      font-weight: 600;\n      \n      >.bold {\n        font-weight: 800;\n      }\n    \n      >.blue-bold {\n        font-size: 24px;\n        text-decoration: underline;\n      }\n    }\n  }\n  \n  .title-box {\n    margin-bottom: 24px;\n  }\n\n  .content-box {\n    border: 1px solid #919cb1;\n    padding: 20px 16px;\n    border-radius: 10px;\n\n    .select-section {\n      margin-bottom: 16px;\n\n      &:last-of-type {\n        // TODO: Not for when there is more than one element\n        margin-bottom: 16px;\n      }\n\n      span {\n        display: block;\n        font-size: 14px;\n        font-weight: 500;\n        color: #2a2d3c;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
