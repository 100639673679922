import React, { useState } from "react";
import "./InnerApprovalRejectSection.scss";

const InnerApproveRejectSection = (props: any) => {
  const {
    confirmationModal,
    title,
    selectSectionContent,
    mdSectionContent,
    submitButton,
    sectionAccount,
    hiddenMarkdown
  } = props;

  return (
    <React.Fragment>
      {confirmationModal}
      <div className="inner-section">
        <div className="title-box">
          {title}
        </div>
        <div className="content-box">
          <div className="select-section-box">
            {sectionAccount}
            {selectSectionContent}
          </div>

          {!hiddenMarkdown &&
            <div style={{ height: "430px" }}>
              {mdSectionContent}
            </div>
          }
          <div>
            {submitButton}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InnerApproveRejectSection;