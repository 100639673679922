import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import SectionNewBank from './SectionNewBank/SectionNewBank';
import SectionError from './SectionError/SectionError';
import CardStateAccount from './CardStateAccount/CardStateAccount';
import { TaskContext } from '../MinimumLiquidity';
import ModalFinalReview from './ModalFinalReview';
import { useGetTaskResponseComments, useGetTaskResponsesByTask } from '../../../../services/task/querys';
import { Loan, Task, TaskResponse, TaskState } from '../../../../gql/graphql';
import InfoExpand from '../../components/Atoms/Dropdown/InfoExpand/InfoExpand';
import StateTag from '../../components/Atoms/Tags/StateTag/StateTag';
import { LoanContext } from '../../../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import { BorrowerRedirectWrapper } from '../../../BorrowerRedirectWrapper/BorrowerRedirectWrapper';
import CardBorrowerImpersonation from '../../../CardBorrowerImpersonation/CardBorrowerImpersonation';

const Step2 = ({
  totalBalance,
  limitBalance,
  handlerCloseModalTask,
  handlerSetTotalBalance
}: any) => {
  const monthBefore = moment().subtract(1, 'month');
  const monthNow = moment();
  const task: Task | any = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);
  
  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId: task._id }
  });

  const [bankAccounts, setBankAccounts] = useState<TaskResponse[]>();
  const [pensionAccounts, setPensionAccounts] = useState<TaskResponse[]>();
  const [cashAccounts, setCashAccounts] = useState<TaskResponse[]>();
  const [otherAccounts, setOtherAccounts] = useState<TaskResponse[]>();
  const [isModalFinalReview, setModalFinalReview] = useState<boolean>(false);

  useEffect(() => {    
    let totalBalance = { draft: 0, review: 0, error: 0, success: 0 };

    if (taskResponses && taskResponses?.length > 0) {
      taskResponses.map((account: any) => {
        if (account.step === 2) {
          if (account.state === TaskState.Approved)
            totalBalance.success += account.metadata.balance;
          else if (account.state === TaskState.RequestError)
            totalBalance.error += (account.metadata.balance - (account.metadata.rejectedAmount || 0));
          else if (account.state === TaskState.Review)
            totalBalance.review += account.metadata.balance;
        }

        return account;
      });

      setBankAccounts(
        taskResponses.filter(
          (response) => response.key === 'account_bank'
        ) as any
      );

      setPensionAccounts(
        taskResponses.filter(
          (response) => response.key === 'accounts_pension'
        ) as any
      );

      setCashAccounts(
        taskResponses.filter(
          (response) => response.key === 'accounts_cash'
        ) as any
      );

      setOtherAccounts(
        taskResponses.filter(
          (response) => response.key === 'accounts_others'
        ) as any
      );
    }

    handlerSetTotalBalance(totalBalance);
  }, [taskResponses]);

  const getTotalAccounts = () => {
    return (
      (bankAccounts?.length || 0) +
      (pensionAccounts?.length || 0) +
      (cashAccounts?.length || 0) +
      (otherAccounts?.length || 0)
    );
  };

  const handlerBalanceDraft = (balanceDraft: number) => {
    handlerSetTotalBalance({ ...totalBalance, draft: balanceDraft });
  };

  const handlerCreateAccount = () => {
    let sum = totalBalance.draft + totalBalance.review + totalBalance.success;
    if (sum >= limitBalance) setModalFinalReview(true);
  };

  const renderReviewAccount = (accounts: any) => {
    const parserState = (state: string) => {
      if (state === TaskState.Approved)
        return { key: 'approved', label: 'APPROVED' };
      else if (state === TaskState.RequestError)
        return { key: 'error', label: 'ERROR' };

      return { key: 'inReview', label: 'IN REVIEW' };
    };

    return (
      accounts &&
      accounts.length > 0 && (
        <div className="mt-10 divider-top">
          <h4 className="h4 h4-w5">Already added accounts</h4>
          {accounts?.map((account: TaskResponse, index: number) => (
            <div key={index} className="mt-20">
              <CardStateAccount
                account={account}
                state={parserState(account.state).key}
                subTitle={
                  <p className="body-card">
                    Closing balance in{' '}
                    {moment(account.createdAt).format('MMMM')}
                  </p>
                }
                text={null}
                label={<StateTag state={account.state} isLabelLocked margin='0 0 10px 0' />}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  const taskHasResponses = taskResponses && taskResponses?.length > 0;
  
  return (
    <section>
      <h2 className="h2">Summary for {' '}
        <b className='h2-w8'>{loan?.owner?.name + ' ' + loan?.owner?.lastName} Proof of Liquidity</b>
      </h2>

      <CardBorrowerImpersonation hasResponses={taskHasResponses}/>
      
      {taskComments && taskComments?.length > 0 && (
        <div className="mt-20">
          <h4 className="h4 h4-error">
            {taskComments?.length}/{getTotalAccounts()} accounts have issues
          </h4>
          {taskComments?.map((comment: any) => (
            <div className="mt-12" key={comment._id}>
              <SectionError
                task={task}
                issue={comment}
                title={comment.title}
                subtitle={comment.subtitle}
                description={comment.description}
              />
            </div>
          ))}
        </div>
      )}

      <div className="mt-50">
        <SectionNewBank
          accountKey="account_bank"
          title="Bank statements"
          buttonLabel="Add new bank account"
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.
            </p>
          }
        />
      </div>
      <div className="mt-20">{renderReviewAccount(bankAccounts)}</div>
      <div className="mt-50">
        <SectionNewBank
          accountKey="accounts_pension"
          title="Retirement/Pension statements"
          buttonLabel="Add new retirement/pension account"
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          balancePercentage={0.5}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.{' '}
              <span className="body-regular-w6">
                Only 50% of your balance will apply toward minimum liquidity
              </span>
            </p>
          }
        />
      </div>

      {renderReviewAccount(pensionAccounts)}

      <div className="mt-50">
        <SectionNewBank
          accountKey="accounts_cash"
          title="Cash-equivalent brokerage statements"
          buttonLabel="Add new brokerage account"
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.
            </p>
          }
        />
      </div>

      {renderReviewAccount(cashAccounts)}

      <div className="mt-50">
        <SectionNewBank
          accountKey="accounts_others"
          title="Other brokerage accounts"
          buttonLabel="Add new brokerage account"
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          balancePercentage={0.5}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.{' '}
              <span className="body-regular-w6">
                Only 50% of your balance will apply toward minimum liquidity
              </span>
            </p>
          }
        />
      </div>

      {renderReviewAccount(otherAccounts)}

      <ModalFinalReview
        visible={isModalFinalReview}
        setVisible={setModalFinalReview}
        submit={() => handlerCloseModalTask(false)}
      />
    </section>
  );
};
export default Step2;
