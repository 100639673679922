import homeWhiteSvg from 'assets/images/home-white.svg';

export enum Phases {
  PRE_APPROVAL = 'pre-approval',
  APPROVAL = 'approval',
  DUE_DILIGENCE = 'due-diligence',
  FINAL = 'final',
  CLOSING = 'closing',
}

export const PhasesLabel: any = {
  'pre-approval': 'PRE APPROVAL',
  'approval': 'APPROVAL',
  'due-diligence': 'DUE DILIGENCE',
  'final': 'FINAL',
  'closing': 'CLOSING'
}

export interface LoanPhases {
  id: number;
  name: string;
  description?: string;
  key: string;
  state?: string;
  stateName?: string;
  text: string;
  tasksItems: any[];
  lenderTasks?: any[];
}

export const LOAN_PHASES: LoanPhases[] = [
  {
    id: 1,
    name: 'Pre-Approval',
    key: Phases.PRE_APPROVAL,
    text: 'Answer a few questions to get pre-approved for a loan',
    description: 'We pre-qualify you based on provided information about the property and your financial situation',
    tasksItems: [
      {
        id: 1,
        key: 'initial_app',
        text: 'Initial application',
        state: 'APPROVED'
      }
    ],
    lenderTasks: [
      {
        id: 1,
        key: 'pre_approval_letter',
        text: 'Pre-approval letter',
        titleButton: 'View',
        state: 'APPROVED'
      },
      {
        id: 2,
        key: 'term_sheet',
        text: 'Term sheet generated',
        titleButton: 'View',
        state: 'APPROVED'
      }
    ]
  },
  {
    id: 2,
    name: 'Approval Docs & Term Sheet',
    key: Phases.APPROVAL,
    text: 'Submit a full application including required docs, which we’ll review',
    tasksItems: [],
    lenderTasks: [
      {
        id: 1,
        key: 'appraisal_ordered',
        text: 'Appraisal ordered',
      },
      {
        id: 2,
        key: 'title_requested',
        text: 'Title requested',
      },
      {
        id: 3,
        key: 'credit_check',
        text: 'Credit check done',
      },
      {
        id: 4,
        key: 'background_check',
        text: 'Background check done',
      },
    ]
  },
  {
    id: 3,
    name: 'Property Evaluation & Due Diligence',
    key: Phases.DUE_DILIGENCE,
    text: 'Submit any remaining docs, while we conduct due diligence to verify your info',
    tasksItems: [],
    lenderTasks: [
      {
        id: 1,
        key: 'appraisal_approved',
        text: 'Appraisal approved',
      },
      {
        id: 2,
        key: 'insurance_verified',
        text: 'Insurance verified',
      },
      {
        id: 3,
        key: 'track_record_verified',
        text: 'Track record verified',
      }
    ]
  },
  {
    id: 4,
    name: 'Final Approval',
    key: Phases.FINAL,
    text: 'After any last minute items are complete, your loan is approved and you get your loan agreement',
    tasksItems: [],
    lenderTasks: [
      {
        id: 1,
        key: 'title_verified',
        text: 'Title verified',
      }
    ]
  },
  {
    id: 5,
    name: 'Closing',
    key: Phases.CLOSING,
    text: 'Loan agreement signed, we deposit your funds in your account within X days',
    tasksItems: [],
    lenderTasks: [
      {
        id: 1,
        key: 'title_cleared',
        text: 'Title cleared to close',
      },
      {
        id: 2,
        key: 'lender_cleared_to_close',
        text: 'Lender cleared to close',
        state: 'DRAFT'
      }
    ]
  }
];
