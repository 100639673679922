import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import FormLogin from "./components/FormLogin";

const Login = () => {
  return (
    <Layout>
      <div className="Login">
        <div className="Login-form">
          <h1 className="h1">Welcome back!</h1>
          <FormLogin />
        </div>
        <div className="rigth">
          <div className="">
            <h2 className="h2">
              Testimonial goes here Testimonial goes here Testimonial goes here
              Testimonial goes here Testimonial goes here
            </h2>
            <p className="body-regular">
              <span className="body-regular-bold">— Ricardo Sims,</span> Title
              @company
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Login;
