import React, { createContext, useContext, useEffect, useState } from 'react';
import iconCortPng from 'assets/images/home.png';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Step2 from './Step2/Step2';
import { Entity, Task, TaskResponse, TaskState } from '../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../services/task/querys';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import StateTag from '../components/Atoms/Tags/StateTag/StateTag';
import { ExpandIcon } from '../BorrowerTasks';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}

const Insurance = ({ task, visible, handlerClose }: Props) => {
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep2, setResponseStep2] = useState<TaskResponse>();
  const [entity, setEntity] = useState<Entity | any>();

  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (taskKey === 'insurance') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if(!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);

  useEffect(() => {
    if (isModalTaskVisible)
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [isModalTaskVisible])

  useEffect(() => {
    const step1 = taskResponses?.find((item) => item.step === 1 && item.key === 'no');
    if (step1) setStep(2);

    const step2: any = taskResponses?.find((item) => item.step === 2);
    setResponseStep2(step2);
    setIsReview(step2?.state === TaskState.Review);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          dotsReview={[responseStep2?.state === TaskState.Review]}
          header={{
            title: <span>UNDERWRITING | MORTGAGE PAYMENTS</span>,
            actionLeft: (
              isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
            ),
            actionRight: (
              <Button
                className="button button-transparent"
                onClick={() => onHelpDocs()}
              >
                <QuestionCircleOutlined />
                Help docs
              </Button>
            )
          }}
          animationDirection="onRight"
          dots={true}
          stepsTotal={1}
          currentStep={1}
          setStep={setStep}
          footer={
            <div className="text-footer mt-20">
              <p className="placeholder">
                My insurance is{' '}
                {step === 2 &&
                  <span className="bold-label">
                    through an agent
                  </span>
                }
              </p>
            </div>
          }
        >
          <section className="children AchPaymentForm">
            <Step2
              setStep={setStep}
              closeTask={() => setModalTaskVisible(false)}
            />
          </section>
        </ModalTask>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default Insurance;
