import React, { useContext } from "react";
import './CardBorrowerImpersonation.scss';
import { LoanDetailContext } from "../../scenes/Private/Private";
import { useGetOAuthToken } from "../../services/auth/mutations";

const CardBorrowerImpersonation = ({ hasResponses }: any) => {
  const { loan }: any = useContext(LoanDetailContext);
  
  const { execute: getOAuthToken } = useGetOAuthToken();

  const onClickHandler = () => {
    const borrowerId = loan?.owner?._id;
    const loanId = loan?._id;

    getOAuthToken({
      userId: borrowerId
    })
      .then(({ data }) => {
        if (data) {
          const oAuthToken = data.getOAuthToken;
          window.open(`https://sbd.constlending.com/?oauth=${oAuthToken}&loanId=${loanId}`, '_blank');
        }
      })
  }
  
  return (
    <div className="card-borrower">
      <div>
        {
          !hasResponses &&
            <b>There are no items awaiting review. </b>
        }
        If the borrower has sent you information through other channels, you can input it from their account.
      </div>
      <div className="row">
        <span onClick={onClickHandler}>Impersonate the borrower</span>
      </div>
    </div>
  )
}

export default CardBorrowerImpersonation;