import React, { createContext, useEffect } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoanList from './LoanList/LoanList';
import { Route, Routes } from 'react-router-dom';
import EntitiesList from './EntitiesList/EntitiesList';
import Borrowers from './Borrowers/Borrowers';
import NotFound from '../404';
import { useGetUserLoggin } from '../../services/user/querys';

export const BorrowerDetailViewContext = createContext<{ visible: boolean; setHandlerVisible: any } | {}>({});
export const LoanDetailContext = createContext<{ loan: any; setLoan: any } | {}>({});

const Private = () => {
  
  const { error } = useGetUserLoggin();

  const [borrowerDetailView, setBorrowerDetailView] = React.useState();
  const [loanSelected, setLoanSelected] = React.useState();

  useEffect(() => {
    if(error?.message.includes('Unauthorized'))
      logout();
  }, [error]);

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
  }

  return (
    <BorrowerDetailViewContext.Provider value={{ visible: borrowerDetailView, setHandlerVisible: setBorrowerDetailView }}>
      <LoanDetailContext.Provider value={{ loan: loanSelected, setLoan: setLoanSelected }}>
        <PageLayout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<LoanList/>}/>
            <Route path="/loans" element={<LoanList/>}/>
            <Route path="/borrowers" element={<Borrowers />} />
            <Route path="/borrowers/:borrowerId" element={<Borrowers />} />
            <Route path="/entities" element={<EntitiesList />} />
          </Routes>
        </PageLayout>
      </LoanDetailContext.Provider>
    </BorrowerDetailViewContext.Provider>
  );
};
export default Private;
