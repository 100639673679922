import { useContext, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { OpenTaskContext } from "../LoanList";

export interface ITaskItemProps {
  _id: string;
  name: string;
  taskKey: string;
  state: string;
  handlerOpenDetail?: any;
}

const TOOLTIP_STATES: any = {
  'APPROVED': 'Approved',
  'REVIEW': 'Need to review',
  'REQUEST_ERROR': 'Error',
  'DRAFT': 'Not Started',
  'INCOMPLETE': 'In Progress'
};

export const TaskItemList: React.FC<ITaskItemProps> = ({ _id, name, taskKey, state, handlerOpenDetail }) => {

  const { setTaskKey }: any = useContext(OpenTaskContext);

  const [isOpen, setIsOpen] = useState<boolean>();

  const openTask = () => {
    setTaskKey(taskKey);
    handlerOpenDetail();
  }

  return (
    <div className="task-item-list">
      <div
        data-tooltip-id={`circle-state-${_id}`}
        className={`circle circle-${state} cursor ${isOpen && ('circle-' + state + '-active')}`}
      />
      <Tooltip
        setIsOpen={setIsOpen}
        id={`circle-state-${_id}`}
        className={`circle-state-tooltip circle-state-tooltip-${state}`}
        openOnClick
        clickable
      >
        <div onClick={openTask}>
          <b>{TOOLTIP_STATES[state]}</b>
          <br />
          <span>{name}</span>
          <br /><br />
          <b style={{ cursor: 'pointer' }}>Get info &#8594;</b>
        </div>
      </Tooltip>
    </div>
  );
};