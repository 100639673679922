import React from 'react';
import './LoanOption.scss';

export const LoanOption = (props: any) => {
  const {
    rate,
    loanTerm,
    monthlyPayment,
    totalLoanAmount = 0,
    ltv,
  } = props;

  return (
    <div className='loan-option'>
      <div className='loan-option-values'>
        <div>
          <span>LTV</span>
          <span>{Number.isInteger(ltv * 100) ? (ltv * 100) : (ltv * 100).toFixed(1)}%</span>
        </div>
        <div>
          <span>Loan Term</span>
          <span>{loanTerm}</span>
        </div>
        <div>
          <span>Rate</span>
          <span>{rate}%</span>
        </div>
        <div>
          <span>Monthly Payment</span>
          <span>{monthlyPayment}</span>
        </div>
        <div>
          <span>Total Loan Amount</span>
          <span>{totalLoanAmount}</span>
        </div>
      </div>
      {/* <div className='loan-option-values'>
        <div>
          <span>ARV LTV:</span>
          <span>80%</span>
        </div>
        <div>
          <span>Maturity Date:</span>
          <span>8/27/2025</span>
        </div>
        <div>
        </div>
        <div>
          <span>First Paymeny Due</span>
          <span>8/27/2024</span>
        </div>
        <div>
        </div>
      </div> */}
    </div>
  );
}