import { gql } from "urql";

export const GET_BORROWERS_LOANS: any = gql(`
    query GetBorrowersLoans($search: String) {
    getBorrowers(search: $search) {
        _id
        name
        lastName
        email
        phone
        avatar 
        loans {
        _id
        name
        progress
        type
        purpose
        ltv
        investmentExperience
        tasks {
            _id
            key
            name
            state
        }
        owner {
            _id
            name
            lastName
        }
        }
        entities {
        _id
        name
        }
    }
    }
`)

export const GET_BORROWER: any = gql(`
    query GetBorrower($borrowerId: String) {
        getBorrower(borrowerId: $borrowerId) {
            _id
            name
            lastName
            email
            phone
            avatar 
            loans {
            _id
            name
            progress
            type
            purpose
            ltv
            investmentExperience
            tasks {
                _id
                key
                name
                state
            }
            owner {
                _id
                name
                lastName
            }
            }
            entities {
                _id
                name
            }
        }
    }
`)