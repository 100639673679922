import React, { useContext, useEffect, useState } from 'react';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import CardUploadResponse from '../../Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { Task, TaskResponse } from '../../../../../gql/graphql';
import ActionAcceptAndReject from '../../../../ActionAcceptAndReject/ActionAcceptAndReject';

interface Props {
  task: Task;
}

const AddAnotherDoc = ({ task }: Props) => {

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  const [docs, setDocs] = useState<any>();

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, []);

  useEffect(() => {
    setDocs(taskResponses?.filter(item => item.key === 'doc-another'));
  }, [taskResponses]);

  return (
    <section>
      {docs?.length > 0 && docs.map((doc: TaskResponse) => (
        <div className='mt-20'>
          <CardUploadResponse
            title={doc.label}
            description={doc.metadata?.description}
            state={doc?.state}
            files={doc?.files}
            submit={{
              title: 'Submit for review',
              loading: false,
              disabled: !!doc,
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => { }
            }}
            insertHtml={doc &&
              <ActionAcceptAndReject
                taskResponseId={doc?._id}
                task={{ id: task._id, key: task.key, name: task.name }}
                files={doc?.files}
                currentState={doc?.state}
              />
            }
          />
        </div>
      ))}
    </section>
  );
};
export default AddAnotherDoc;