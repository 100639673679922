import React from 'react';
import { TaskState } from 'gql/graphql';
import { Tooltip } from 'react-tooltip';
import { ENTITY_DOCS_LABEL } from '../../../../EntityDocs/responses.constants';

const tooltipsTexts: any = {
  "APPROVED": 'Approved',
  "REVIEW": 'In Review',
  "REQUEST_ERROR": 'Rejected',
  "DRAFT": 'Not submmitted',
}

const tooltipTexts = (state: string) => tooltipsTexts[state];

interface Props {
  tagKey: string;
  entityDocsKey?: string;
  state: TaskState | string;
}

const CircleTag = ({
  tagKey,
  state,
  entityDocsKey
}: Props) => {
  state = state.toUpperCase();

  return (
    <div className={`circle-tag circle-tag-${state}`}>
      <div
        data-tooltip-id={`circle-state-${tagKey}`}
        data-tooltip-html={`<b>${tooltipTexts(state)}</b> ${entityDocsKey? `<br/><span>${ENTITY_DOCS_LABEL[entityDocsKey]}</span>`: ''}`}
        className={`circle circle-${state}`}
      />
      <Tooltip
        id={`circle-state-${tagKey}`}
        className={`circle-state-tooltip circle-state-tooltip-${state}`}
      />
    </div>
  );
};

export default CircleTag;
