// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-select-option > h3.h3 {
  margin-bottom: 48px;
}

.DifferentEmail input {
  background-color: transparent;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  color: #48759a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DifferentEmail input::placeholder {
  color: #9bbceb;
  font-family: Inter;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.DifferentEmail .space {
  height: 12px;
}
.DifferentEmail .buttons .button-modal-ok, .DifferentEmail .buttons #button-submit {
  font-size: 12px !important;
  text-transform: none !important;
  text-align: center !important;
  border: 1px solid #162e43 !important;
  background: #162e43 !important;
  color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/BorrowerTask/PayAppraisal/Step1/Step1.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;;AAKE;EACE,6BAAA;EACA,uBAAA;EACA,2BAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAII;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFN;AAME;EACE,YAAA;AAJJ;AAQI;EACE,0BAAA;EACA,+BAAA;EACA,6BAAA;EACA,oCAAA;EACA,8BAAA;EACA,yBAAA;AANN","sourcesContent":[".section-select-option {\n  > h3.h3 {\n    margin-bottom: 48px;\n  }\n}\n\n.DifferentEmail {\n  input {\n    background-color: transparent;\n    border: none !important;\n    box-shadow: none !important;\n    padding: 0;\n    color: #48759a;\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n\n    &::placeholder {\n      color: #9bbceb;\n      font-family: Inter;\n      font-size: 16px;\n      font-style: italic;\n      font-weight: 500;\n      line-height: normal;\n    }\n  }\n\n  .space {\n    height: 12px;\n  }\n\n  .buttons {\n    .button-modal-ok, #button-submit {\n      font-size: 12px !important;\n      text-transform: none !important;\n      text-align: center !important;\n      border: 1px solid #162e43 !important;\n      background: #162e43 !important;\n      color: #ffffff !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
