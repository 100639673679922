import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';

import leafPng from 'assets/images/leaf.png';
import { Entity, Loan, Task, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { ENTITY_DOCS_RESPONSES } from './responses.constants';
import DropdownEntities from '../EntityGuarantorCreation/DropdownEntities/DropdownEntities';
import Step2 from './Step2/Step2';
import { ExpandIcon, TaskOpenModalContext } from '../BorrowerTasks';
import { useGetEntitiesByOwner } from '../../../services/entity/querys';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import StateTag from '../components/Atoms/Tags/StateTag/StateTag';
import { LoanContext } from '../../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';
import CircleTag from '../components/Atoms/Tags/CircleTag/CircleTag';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}

const EntityDocs = ({ task, visible, handlerClose }: Props) => {
  const loan: Loan | any = useContext(LoanContext);
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const [ownerSelect, setOwnerSelect] = useState<string>('');

  const { data: taskResponsesEntityGuarantors, reexecute: getTaskGuarantors } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { loanId: loan._id, taskKey: 'entity_guarantors' }
    }
  });
  const { data: taskResponses, reexecute: getTaskResponsesByTask } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } },
      pause: false
    });
  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isConfirmDocs, setConfirmDocs] = useState(false);
  const [step, setStep] = useState(2);
  const [entity, setEntity] = useState<Entity | any>();
  const [notEntity, setNotEntity] = useState<boolean>(false);
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    if (!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (taskKey === 'entity_docs') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if (!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);

  useEffect(() => {
    const step1 = taskResponsesEntityGuarantors?.find(
      (item: any) => item.step === 1
    );

    setOwnerSelect(step1?.metadata.ownerId);

    if (!step1?.metadata.entity)
      setNotEntity(true);
  }, [taskResponsesEntityGuarantors]);

  useEffect(() => {
    if (entities) {
      const _entity = entities.find((item) => item.loans.find((item2) => item2._id === loan._id));

      setEntity(_entity);
      if (_entity?.name.includes('Entity created on'))
        setStep(1);
      setNotEntity(true);
    }
  }, [entities]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);

  useEffect(() => {
    if (openContext === 'entity_docs') {
      setModalTaskVisible(true);
      setHandler();
    }
  }, [openContext]);

  useEffect(() => {
    if (entity?.loans?.length > 1 && taskResponses?.some(item => item.metadata?.viewConfirm))
      setConfirmDocs(true);
    else if (taskResponses?.length === 0) setConfirmDocs(false);

    const responsesStep2 = taskResponses?.filter((item) => item.step === 2);
    setIsReview(!!responsesStep2?.some((item: any) => item.state === TaskState.Review));
  }, [entity, taskResponses]);

  useEffect(() => {
    if (isModalTaskVisible) {
      getTaskResponsesByTask({ requestPolicy: 'network-only' });
      getTaskGuarantors({ requestPolicy: 'network-only' });
      getEntitiesByOwner({ requestPolicy: 'network-only' });
    }
  }, [isModalTaskVisible]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const getTotalApproved = () =>
    taskResponses?.filter((item: any) => item.state === TaskState.Approved)?.length;

  const isConfirmedEntityDocs = () =>
    taskResponses?.some((item) => item.state === TaskState.Approved);

  const getResponse = (key: string) => {
    return taskResponses?.find((item) => item.key === key);
  };

  const onSubmitChangeEntity = (entity: Entity) => {
    if (entity?.loans?.length > 1) setConfirmDocs(true);

    getTaskResponsesByTask({ requestPolicy: 'network-only' });
  };

  const circlesOtherDocs: any = () => {
    const addAnotherDocs = taskResponses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || [];
    return docsStates;
  };

  if (!task) return null;

  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <section className="EntityDocs">
          <ModalTask
            visible={isModalTaskVisible}
            handlerSetVisibile={setModalTaskVisible}
            header={{
              title: <span>UNDERWRITING | ENTITY DOCS</span>,
              actionLeft:
                isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={leafPng} alt="icon" className="paperPng" />
            }}
            animationDirection="onRight"
            dots={true}
            stepsTotal={1}
            currentStep={1}
            setStep={setStep}
            footer={
              <div className="task-footer">
                {isConfirmDocs && entities && (
                  <p className="placeholder">
                    My entity is{' '}
                    <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
                    {' Its ownership is '}
                    <span className="bold" style={{ padding: 0 }}>
                      confirmed
                    </span>
                    {` and its ${taskResponses && taskResponses?.length >= 4
                      ? taskResponses?.length
                      : 4
                      } documents are `}
                    <span className="bold" style={{ padding: 0 }}>
                      {isConfirmedEntityDocs() ? 'confirmed' : 'unconfirmed'}
                    </span>
                  </p>
                )}
                {!isConfirmDocs && (
                  <>
                    {notEntity && (
                      <>
                        <p className="placeholder">
                          My entity is {' '}
                          {!entity?.name.includes('Entity created on') && (
                            <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
                          )}
                        </p>
                      </>
                    )}

                    <div className="owners-tags">
                      <p
                        className="h4"
                        style={{ marginTop: 15, fontWeight: 500 }}
                      >
                        Document status{' '}
                      </p>

                      <div className="circles-tags">
                        {Object.keys(ENTITY_DOCS_RESPONSES).map((key) => (
                          <CircleTag
                            key={key}
                            state={getResponse(ENTITY_DOCS_RESPONSES[key])?.state || 'DRAFT'}
                            tagKey={key}
                            entityDocsKey={key}
                          />
                        ))}
                        {circlesOtherDocs().map((state: string, index: number) => {
                          return (
                            <CircleTag
                              key={state}
                              state={state}
                              tagKey={state}
                            />
                          )
                        })}
                      </div>
                    </div>
                    {taskResponses && !notEntity && (
                      <div className="owners-tags">
                        <span>{getTotalApproved()}/4 submitted</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            }
          >
            <div className="children">
              <Step2
                closeTask={() => setModalTaskVisible(false)}
                isConfirmDocs={isConfirmDocs}
                onSubmitChangeEntity={onSubmitChangeEntity}
              />
            </div>
          </ModalTask>
        </section>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default EntityDocs;
