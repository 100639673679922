import React, { useState } from 'react';

interface listItems {
  id: number;
  text: string;
  subTitle: string;
}

export interface OptionsQuestionBasic {
  id: number;
  title: string;
  subtitle?: React.ReactElement | string;
  subtitleBold?: string;
  dropdown?: React.ReactElement | any;
  onClick: Function | null;
  textRight?: String | null;
  hidden?: Function;
  icon?: any;
}

interface Props {
  question: React.ReactElement;
  options: OptionsQuestionBasic[];
  listItems?: listItems[] | null;
  type: string | null;
  text?: React.ReactElement | null;
  optionSelected?: any;
  metadata?: any;
  insertHtml?: React.ReactElement;
}

const QuestionBasic = ({
  question,
  options,
  listItems,
  type,
  text,
  optionSelected,
  metadata,
  insertHtml
}: Props) => {
  const [isOptionSelected, setOptionSelected] = useState(0);
  const [isShown, setIsShown] = useState(false);

  const handleFInish = (option: any) => {
    setOptionSelected(option.id);
    option.onClick(option, metadata);
  };

  return (
    <div className="QuestionBasic">
      {question}
      <div className="mt-10">{text && text}</div>
      {listItems && (
        <ol className="list">
          {listItems?.map((item) => (
            <li className="h4" key={item.id}>
              <span>{item.subTitle}</span>{' '}
              <span className="h4-w4">{item.text}</span>
            </li>
          ))}
        </ol>
      )}
      <div className="options" style={{ position: 'relative' }}>
        {insertHtml}
        {options?.map((option) =>
          !option.hidden || (option.hidden && !option.hidden(optionSelected)) ? (
            <div
              className={`Button-option ${
                isOptionSelected === option.id && 'Button-option-active'
              }`}
              onClick={() => handleFInish(option)}
              key={option.id}
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              <div className="flex-btw">
                <div className="content-left">
                  <label className='h4 Button-option-text'>
                    {option.title}
                  </label>
                  
                    <span className="Button-option-active-description">
                      { option.icon && 
                        <>
                          <img src={option.icon} className="lienzo" alt="lienzoSvg" />
                          {' '}
                        </>
                      }
                      <span className='bold-little'>{option.subtitleBold}{' '}</span>
                      <span className='body-regular'>{option.subtitle}</span>
                    </span>
                </div>
                {option.textRight && (
                  <p className="body-regular body-regular-w5">
                    {option.textRight}
                  </p>
                )}
              </div>
              {type === 'dropdown' &&
                option.dropdown &&
                option.id === isOptionSelected &&
                option.dropdown(metadata)}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
export default QuestionBasic;
