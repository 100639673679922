import React from 'react';
import { Button } from 'antd';

interface ButtonLinkProps {
  title: string;
  icon: React.ReactElement;
  onClick: Function;
  className?: string;
}

const ButtonLink = ({ title, icon, onClick, className }: ButtonLinkProps) => {
  return (
    <Button className={`button ${className || 'button-transparent'}`} onClick={() => onClick()}>
      {icon}
      {title}
    </Button>
  );
};

export default ButtonLink;
