import React, { useEffect, useState } from 'react';
import "./Step1.scss";
import QuestionBasic from '../../components/Organisms/QuestionBasic/QuestionBasic';
import SectionIssues from '../../../SectionIssues/SectionIssues';
import { useGetTaskResponsesByTask } from '../../../../services/task/querys';
import ActionAcceptAndReject from '../../../ActionAcceptAndReject/ActionAcceptAndReject';
import CardBorrowerImpersonation from '../../../CardBorrowerImpersonation/CardBorrowerImpersonation';

interface Step1Props {
  task: any;
  closeTask: Function;
}

const Step1 = ({ task, closeTask }: Step1Props) => {

  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [responseEmail, setResponseEmail] = useState<string>('');
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    const response = taskResponses?.find(item => item.metadata.userEmail)
    setResponseEmail(response?.metadata.userEmail);
    setResponse(response);
  }, [taskResponses])

  return (
    <section>
      <article className="section-select-option">

        <CardBorrowerImpersonation hasResponses={taskResponses && taskResponses.length > 0} />

        <SectionIssues task={task} />

        <QuestionBasic
          type="buttons"
          text={null}
          question={<h3 className="h2"></h3>}
          listItems={null}
          options={[
            {
              id: 1,
              title: 'Approve this when the appraisal is paid',
              dropdown: undefined,
              onClick: () => { }
            },
          ]}
          insertHtml={
            <ActionAcceptAndReject
              taskResponseId={response?._id}
              task={{ id: task._id, key: task.key, name: task.name }}
              files={response?.files}
              currentState={response?.state}
            />
          }
        />
      </article>

    </section>
  );
};
export default Step1;