import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import arrowBlue from 'assets/images/arrow-blue.png';
import { Entity, Loan } from '../../../../../gql/graphql';
import { BorrowerDetailViewContext } from '../../../Private';

interface Props {
  loans: any[],
  entities: any[],
}

const CardEntitiesAndLoans = ({ loans, entities }: Props) => {

  const navigate = useNavigate();

  const { setHandlerVisible }: any = useContext(BorrowerDetailViewContext);

  const openLink = () => {
    navigate('/loans');
    setHandlerVisible();
  }

  return (
    <div className='card-profile-generic'>
      <h1 className='card-title'>Entities and loans</h1>
      <div className='flex-btw mt-10'>
        <span className='title-bold'>
          <span className='bold'>{loans?.length}</span> {loans?.length === 1? 'loan': 'loans'} | {' '}
          <span className='bold'>{loans?.length}</span> open
          {/*| <span className='bold'>2</span> closed*/}
        </span>
        <Link className="body-regular link" to="/loans" onClick={openLink}>
          See all loans <img src={arrowBlue} alt='arrow' className='arrow' />
        </Link>
      </div>
      <div className='flex-btw mt-10'>
        <span className='title-bold'>
          <span className='bold'>{entities?.length}</span> {entities?.length === 1? 'entity': 'entities'}
        </span>
        {/*
          <Link className="body-regular link" to="./" onClick={() => openLink('entities')}>
            See all entities <img src={arrowBlue} alt='arrow' className='arrow' />
          </Link>
        */}
      </div>
    </div>
  )
}

export default CardEntitiesAndLoans