// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-borrower {
  margin-top: 32px;
  border-radius: 6px;
  border: 1px solid var(--blue-80, #355C7D);
  background: var(--10, #F5FAFF);
  padding: 12px 16px;
  color: var(--900-grey, #2A2F3C);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.card-borrower b {
  font-weight: 600;
}
.card-borrower .row {
  margin-top: 16px;
  text-align: right;
}
.card-borrower .row span {
  cursor: pointer;
  color: var(--blue-80, #355C7D);
  text-align: right;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}`, "",{"version":3,"sources":["webpack://./src/components/CardBorrowerImpersonation/CardBorrowerImpersonation.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EAEA,kBAAA;EACA,yCAAA;EACA,8BAAA;EACA,kBAAA;EAEA,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AADJ;AAGI;EACI,gBAAA;AADR;AAII;EACI,gBAAA;EACA,iBAAA;AAFR;AAIQ;EACI,eAAA;EAEA,8BAAA;EACA,iBAAA;EACA,kBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,+BAAA;EACA,4BAAA;EACA,8BAAA;EACA,+BAAA;EACA,2BAAA;EACA,kCAAA;AAHZ","sourcesContent":[".card-borrower {\n    margin-top: 32px;\n\n    border-radius: 6px;\n    border: 1px solid var(--blue-80, #355C7D);\n    background: var(--10, #F5FAFF);\n    padding: 12px 16px;\n\n    color: var(--900-grey, #2A2F3C);\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 140%; /* 19.6px */\n\n    b {\n        font-weight: 600;\n    }\n\n    .row {\n        margin-top: 16px;\n        text-align: right;\n\n        span {\n            cursor: pointer;\n            \n            color: var(--blue-80, #355C7D);\n            text-align: right;\n            font-family: Inter;\n            font-size: 14px !important;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 140%; /* 19.6px */\n            text-decoration-line: underline;\n            text-decoration-style: solid;\n            text-decoration-skip-ink: none;\n            text-decoration-thickness: auto;\n            text-underline-offset: auto;\n            text-underline-position: from-font;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
