import React, { createContext, useState, useEffect, useContext } from 'react';
import calcPng from 'assets/images/calculator.png';
import Step3 from './Step3/Step3';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import { LoanContext } from '../../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';

const DSCRContext = {
  totalUnits: 0,
  setTotalUnits: () => { },
  vacantUnits: 0,
  setVacantUnits: () => { },
  occupiedUnits: 0,
  setOccupiedUnits: () => { }
}

export const TaskContext = createContext<Task | typeof DSCRContext>(DSCRContext);

interface DscrQuestionsAgreementsProps {
  task?: Task;
  currentStep?: number | any;
  visible?: boolean;
  handlerClose?: Function;
}
const DscrQuestionsAgreements = ({
  // classButton,
  // @ts-ignore
  task = {},
  currentStep,
  visible,
  handlerClose
}: DscrQuestionsAgreementsProps) => {
  const loan: Loan | any = useContext(LoanContext);
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(3);

  const [responseStep, setResponseStep] = useState<TaskResponse>();
  const [totalUnits, setTotalUnits] = useState<number>(0);
  const [vacantUnits, setVacantUnits] = useState<number>(0);
  const [occupiedUnits, setOccupiedUnits] = useState<number>(0);
  const [dscrValues, setDSCRValues] = useState<any>({
    monthlyRentalIncome: loan.loanData.monthlyRent,
    annualPropertyInsurance: loan.loanData.annualInsurance,
    annualPropertyTaxes: loan.loanData.annualTaxes,
    annualHOAFee: loan.loanData.annualHoa,
    estimatedDSCR: loan.loanData.selectedOption.dscr
  });
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    if (taskKey === 'dscr') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if (!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);


  useEffect(() => {
    setOccupiedUnits(totalUnits - vacantUnits);
  }, [totalUnits, vacantUnits])

  useEffect(() => {
    if (taskResponses && taskResponses.length > 0) {
      const response: any = taskResponses.find((item) => item.key === 'market-rent');
      setResponseStep(response);

      if (response?.metadata) {
        const { totalUnits, vacantUnits } = response.metadata;

        if (totalUnits !== undefined) setTotalUnits(totalUnits);
        if (vacantUnits !== undefined) setVacantUnits(vacantUnits);
        if (totalUnits !== undefined && vacantUnits !== undefined) {
          setOccupiedUnits(totalUnits - vacantUnits);
        }
      }
    }
  }, [taskResponses]);

  useEffect(() => {
    if (task?.metadata) {
      setDSCRValues({
        ...dscrValues,
        estimatedDSCR: task?.metadata.estimatedDSCR
      });
    }
    setTotalUnits(task?.metadata?.totalUnits || 0);
    setVacantUnits(task?.metadata?.vacantUnits || 0);
    setOccupiedUnits(task?.metadata?.occupiedUnits || 0);

  }, [task])

  const dscrContextValue = {
    totalUnits,
    setTotalUnits,
    vacantUnits,
    setVacantUnits,
    occupiedUnits,
    setOccupiedUnits,
    dscrValues,
    setDSCRValues
  };

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  if (!task) return null;

  return (
    <TaskContext.Provider value={{ ...task, ...dscrContextValue }}>
      <ModalTask
        visible={isModalTaskVisible}
        dots={true}
        handlerSetVisibile={setModalTaskVisible}
        dotsReview={[responseStep?.state === TaskState.Review]}
        header={{
          title: <span>APPROVAL | DSCR & LEASE AGREEMENTS</span>,
          actionLeft: (
            <img
              src={calcPng}
              alt="icon"
              className="paperPng"
            />
          ),
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              Help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        stepsTotal={3}
        currentStep={step}
        setStep={setStep}
        footer={
          <div className="text-footer mt-20">
            <p className="placeholder">
              The DSCR for my property is <span className="bold-label">{task?.metadata ? task?.metadata.estimatedDSCR : loan?.loanData?.selectedOption?.dscr}</span>
            </p>
          </div>
        }
      >
        <section className="children DscrQuestionsAgreements">
          <Step3 task={task} />
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default DscrQuestionsAgreements;
