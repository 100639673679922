import { useContext, useEffect, useState } from 'react';
import { useGetTasksByLoan } from '../../../../services/task/querys';
import { LoanDetailContext } from '../../Private';

interface Props {
}

export const useLoanDetails = () => {
	const { loan: loanSelected }: any = useContext(LoanDetailContext);

	const { data: tasks, reexecute: getTasksByLoan } = useGetTasksByLoan({
		variables: { loanId: loanSelected?._id },
		pause: true
	});

	useEffect(() => {
		if(loanSelected) 
			getTasksByLoan()
	}, [loanSelected])

	return {
		loanSelected,
		tasks
	};
};
