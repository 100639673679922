import React, { useEffect, useState } from 'react';
import './App.scss';

import { Client, Provider, cacheExchange, fetchExchange } from 'urql';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import Private from './scenes/Private/Private';
import Public from './scenes/Public/Public';

function App() {

  const [isLogged, setIsLogged] = useState<boolean>(false);

  useEffect(() => {
    setIsLogged(!!localStorage.getItem('token'));

    window.addEventListener("storage", () => {
      setIsLogged(!!localStorage.getItem('token'));
    });

    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
  }

  const streamToString = (stream: any) => {
    const chunks: any = [];
    return new Promise((resolve, reject) => {
      stream.on('data', (chunk: any) => chunks.push(chunk));
      stream.on('error', reject);
      stream.on('end', () => resolve(Buffer.concat(chunks).toString('utf8')));
    });
  };

  const client = new Client({
    url: 'https://sbb.constlending.com/graphql',
    // url: 'https://vigilant-spork-j767wg5gjxpc5g74-5000.app.github.dev/graphql',
    exchanges: [
      cacheExchange,
      authExchange(async (utils: any) => {
        let token = await localStorage.getItem('token');

        const config: AuthConfig = {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
              'Apollo-Require-Preflight': 'true'
            });
          },
          didAuthError(error, _operation) { 
            return error.response?.status === 401 
          },
          async refreshAuth() { 
            logout()
          },
          willAuthError(_operation) { return false }
        }

        return config;
      }),
      fetchExchange,
    ]
  });
  
  return (
    <Provider value={client}>
      { isLogged && <Private /> }
      { !isLogged && <Public /> }
    </Provider>
  );
}

export default App;
