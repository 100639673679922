import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from '../../components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Props {
  visible: boolean;
  setVisible: Function;
  closeTask?: Function;
}

const ModalFinish = ({ visible, setVisible, closeTask }: Props) => {

  return (
    <ModalEndSubmit
      visible={visible}
      setVisible={setVisible}
      buttonAction={{ hidden: true }}
      modal={{
        title: 'Great job!',
        description: (
          <h4 className="h4 h4-w4">
            {`You just completed a task. We’ll review the information you submitted and contact you if we need any changes.`}
          </h4>
        )
      }}
      className=""
      icon={calendarPng}
      buttonSubmit={
        <Button
          htmlType="submit"
          className="button button-modal-ok"
          onClick={() => {
            setVisible(false);
            if(closeTask)
              closeTask();
          }}
        >
          Back to dashboard
        </Button>
      }
      isCheckBox={false}
    />
  );
};
export default ModalFinish;
