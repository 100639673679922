import { gql } from "urql";

export const GET_ENTITIES_BY_OWNER: any = gql(`
    query GetEntitiesByOwner($ownerId: String!){
        GetEntitiesByOwner(ownerId: $ownerId){
            _id
            name
            loans {
                _id
            }
            referenceEntity {
                _id
            }
            ownerships {
                _id
                name
                lastname
                stakes
                ssn
                phone
                email
                address
                dateBirth
                files {
                    key
                    name
                    url
                }
            }
        }  
    }
`)


export const GET_ENTITY_BY_LOAN: any = gql(`
    query GetEntityByLoan($loanId: String!){
        GetEntityByLoan(loanId: $loanId){
            _id
            name
            loans {
                _id
            }
            referenceEntity {
                _id
            }
            ownerships {
                _id
                name
                lastname
                stakes
                ssn
                phone
                email
                address
                dateBirth
                files {
                    key
                    name
                    url
                }
            }
        }  
    }
`)

export const GET_DOCS_BY_ENTITY: any = gql(`
    query GetEntityDocsByEntity($entityId: String!){
        GetEntityDocsByEntity(entityId: $entityId){
            _id
            key
            label
            description
            files {
                key
                name
                url
                state
            }
            entity {
                _id
            }
        }  
    }
`)