import { useContext, useEffect, useState } from 'react';
import { Task } from '../../gql/graphql';
import { TasksContext } from '../../scenes/Private/LoanList/LoanDetails/LoanDetails';

interface Props {
  taskKey: string;
}

export const useBorrowerTasks = ({ taskKey }: Props) => {

	const tasks: any = useContext(TasksContext);

	const [dataTask, setDataTask] = useState<Task>();

	useEffect(() => {
		if (tasks) {
			const _data: any = tasks.find((item: any) => item.key === taskKey || item._id === taskKey);
			setDataTask(_data);
		}
	}, [tasks]);

	return {
		dataTask
	};
};
