import React, { useEffect, useState } from 'react';
import {
  useCreateResponseTask,
  useRemoveTaskResponse
} from 'services/task/mutations';
import { Button } from 'antd';
import coinPng from 'assets/images/coin-shadow.png';
import { TaskState } from '../../../../gql/graphql';
import ModalEndSubmit from '../../components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Props {
  entity?: string;
  ein?: any;
  agreement?: any;
}
const ButtonEditDoc = ({ entity, ein, agreement }: Props) => {
  const {
    data,
    fetching,
    error,
    execute: createResponseTask
  } = useCreateResponseTask();

  const [isModalEdit, setModalEdit] = useState<boolean>(false);

  useEffect(() => {
    if (data) setModalEdit(false);
  }, [data]);

  const confirm = () => {
    const { createdAt: _a, __typename: _b, files: _c, task: _d, ..._ein } = ein;
    createResponseTask({
      ..._ein,
      task: _d._id,
      metadata: _ein.metadata || {},
      state: TaskState.Draft
    });

    const { createdAt, __typename, files, task, ..._agreement } = agreement;
    createResponseTask({
      ..._agreement,
      task: task._id,
      metadata: _agreement.metadata || {},
      state: TaskState.Draft
    });
  };

  return (
    <ModalEndSubmit
      visible={isModalEdit}
      setVisible={setModalEdit}
      buttonAction={{
        label: 'Edit documents',
        loading: fetching,
        className: 'button-link-border'
      }}
      modal={{
        title: `Do you want to edit the documents for ${entity}?`,
        description: (
          <h4 className="h4 h4-w4">
            This won’t affect any closed loan applications, but the profile will
            be updated on any active or in progress loans
          </h4>
        )
      }}
      className="modal-end-submit--white"
      icon={coinPng}
      buttonSubmit={
        <Button className="button button-modal-ok" onClick={confirm}>
          Yes, I want to edit
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => setModalEdit(false)}
        >
          No, leave it as is
        </Button>
      }
    />
  );
};
export default ButtonEditDoc;
