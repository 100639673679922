import { gql } from "urql";

export const GET_USER_LOGGIN: any = gql(`
    query GetUserLoggin{
        GetUserLoggin {
            _id
            name
            lastName
            email
            phone
            avatar
        }  
    }
`)

export const GET_USER_REPORTS: any = gql(`
    query GetUserReports($userId: String!){
        GetUserReports(userId: $userId) {
            _id
            key
            metadata
            file {
                key
                name
                url
            }
        }  
    }
`)