import { useMutation } from 'urql';
import { LOGIN, GET_OAUTH_TOKEN } from './graphql';

export const useLogin = (query?: any) => {
    const [{ data, fetching, error }, execute] = useMutation((query || LOGIN));
    return { execute, data, fetching, error };
}

export const useGetOAuthToken = () => {
    const [{ data, fetching, error }, execute] = useMutation((GET_OAUTH_TOKEN));
    return { execute, data, fetching, error };
}