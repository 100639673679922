import React, { useState } from 'react';

interface Props {
}

const EntitiesList: React.FC<Props> = ({  }) => {

  return (
    <>
      <div className="entities-list">
        <div className='top-container'>
          <div className='title'>
            Entities
          </div>
          <div>
          </div>
        </div>
        <div className='entities-container'>
        </div>
      </div>
    </>
  );
};

/* List Item */
type LoanStatus = 'review' | 'approval';
interface ILoanListItemProps {
  status: LoanStatus;
  borrowerName: string;
  address: string;
  tasks: ITaskItemProps[];
  onClick?: () => void;
}

const LoanListItem: React.FC<ILoanListItemProps> = (props) => {
  const { status, borrowerName, address, tasks, onClick } = props;
  
  return (
    <div className='loan-list-item' onClick={onClick}>
      <div className='loan-status-badge-container'>
        <div className='loan-status-badge'>
          {status}
        </div>
      </div>
      <div className='borrower-summary-container'>
        <div className='borrower-summary'>
          <div className='caption'>Borrower</div>
          <div className='borrower-name'>{borrowerName}</div>
        </div>
      </div>
      <div>
        <div className='address-summary'>
          <div className='caption'>Address</div>
          <div className='address'>{address}</div>
        </div>
      </div>
      <div>
        <div className='task-summary'>
          <div className='caption'>Task Completion</div>
          <div className='task-item-container'>
            {tasks.map((task, index) => (
              <TaskItem {...task} key={index}/>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className='action-container'>
          {"View loan details -->"}
        </div>
      </div>
    </div>
  )
}

interface ITaskItemProps {
  label: string;
  status: 'pending' | 'review' | 'completed';
}

const TaskItem: React.FC<ITaskItemProps> = ({ status }) => {
  return (
    <div className={`task-item ${status}`}>
    </div>
  );
};

/* Exports */
export default EntitiesList;