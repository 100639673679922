import { useQuery } from 'urql';
import { DOWNLOAD_FILES_PENDING, GET_LOANS, GET_LOAN_BY_ID, GET_LOAN_BY_OWNER, GET_LOAN_DOCUMENTS } from './graphql';
import { DownloadFilesPendingQuery, GetLoanByIdQuery, GetLoanByOwnerQuery, GetLoanDocByLoanQuery, GetLoansQuery, QueryDownloadFilesPendingArgs, QueryGetLoanByIdArgs, QueryGetLoanDocByLoanArgs } from 'gql/graphql';
import { UseQueryInterface } from '../../../utils/interfaces/use-query.interface';

export const useGetLoanById = (variables: QueryGetLoanByIdArgs, query?: any) => {
    const [{ data, fetching, error }] = useQuery<GetLoanByIdQuery>({ 
        query: query || GET_LOAN_BY_ID,
        variables
    });
    return { data: data?.GetLoanById, fetching, error };
}

export const useGetLoanByOwner = (variables?: {}, query?: any) => {
    const [{ data, fetching, error }] = useQuery<GetLoanByOwnerQuery>({ 
        query: query || GET_LOAN_BY_OWNER,
        variables
    });
    return { data: data?.GetLoanByOwner, fetching, error };
}

export const useGetLoans = (variables?: {}, query?: any) => {
    const [{ data, fetching, error }] = useQuery<GetLoansQuery>({ 
        query: query || GET_LOANS,
        variables
    });
    return { data: data?.GetLoans, fetching, error };
}

export const useGetLoanDocByLoan = (vars?: UseQueryInterface<QueryGetLoanDocByLoanArgs>) => {
    const [{ data, ...others }, reexecute] = useQuery<GetLoanDocByLoanQuery>({
        query: vars?.query || GET_LOAN_DOCUMENTS,
        ...vars
    });
    return {
        data: data?.GetLoanDocByLoan,
        reexecute,
        ...others
    };
};

export const useDownloadFilePending = (vars?: UseQueryInterface<QueryDownloadFilesPendingArgs>) => {
    const [{ data, ...others }, reexecute] = useQuery<DownloadFilesPendingQuery>({
        query: vars?.query || DOWNLOAD_FILES_PENDING,
        ...vars
    });
    return {
        data: data?.DownloadFilesPending,
        reexecute,
        ...others
    };
};