import { gql, useQuery } from "urql";
import { GET_BORROWER, GET_BORROWERS_LOANS } from "./graphql";
import { GetBorrowerQuery, QueryGetBorrowerArgs } from "../../../gql/graphql";
import { UseQueryInterface } from "../../../utils/interfaces/use-query.interface";

export const useGetBorrowers = (query?: any) => {
  const [{ data, fetching, error }] = useQuery({ query: query || GET_BORROWERS_LOANS });
  return { data: data?.getBorrowers, fetching, error };
}

export const useGetBorrower = (
  vars?: UseQueryInterface<QueryGetBorrowerArgs>
) => {
  const [{ data, ...others }, reexecute] = useQuery<GetBorrowerQuery>({
    query: vars?.query || GET_BORROWER,
    ...vars
  });
  return {
    data: data?.getBorrower,
    reexecute,
    ...others
  };
};