import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";

/* Borrower Card */
interface IBorrowerCardProps {
  _id: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: { key: string, name: string, url: string };
}

export const BorrowerCard: React.FC<IBorrowerCardProps> = (props) => {

  const navigate = useNavigate();
  
  const viewBorrower = () => { 
    navigate('/borrowers/' + props._id);
  }

  return (
    <div className='borrower-card'>
      <div className='borrower-summary'>
        <div className='photo-container'>
          <Avatar src={props.avatar?.url} size={24} className='avatar'/>
        </div>
        <div className='borrower-caption'>
          BORROWER
        </div>
        <div className='borrower-name'>
          {props.name + ' ' + props.lastName}
        </div>
      </div>
      <div className='contact-info-container'>
        <div>{props.email}</div>
        <div>{props.phone}</div>
      </div>
      <div className='buttons-container'>
        {/*<div className='button-blue'>Message Borrower</div>*/}
        <div className='button-blue outlined' onClick={viewBorrower}>
          See Profile
        </div>
      </div>
    </div>
  )
};