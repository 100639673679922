import { gql } from "urql";

export const MUTATION_UPDATE_USER: any = gql(`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
        UpdateUser(updateUserInput: $updateUserInput) {
            _id
        }
    }
`)

export const MUTATION_CREATE_USER_REPORT: any = gql(`
    mutation CreateUserReport($createUserReportInput: CreateUserReportInput!) {
        createUserReport(createUserReportInput: $createUserReportInput) {
            _id
        }
    }
`)