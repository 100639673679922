// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-layout {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  padding-top: 61.82px;
}
.page-layout .top-bar {
  position: fixed;
  top: 0px;
  z-index: 3;
}
.page-layout .content-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  padding-left: 64px;
}
.page-layout .content-container .left-bar {
  position: absolute;
  left: 0px;
  height: 100%;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;EACA,aAAA;EACA,YAAA;EAEA,oBARe;AAKjB;AAKE;EACE,eAAA;EACA,QAAA;EACA,UAAA;AAHJ;AAME;EACE,sBAAA;EACA,YAAA;EACA,WAAA;EAEA,kBAAA;EAEA,kBAtBa;AAgBjB;AAQI;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;AANN","sourcesContent":["$top-bar-height: 61.82px;\n$left-bar-width: 64px;\n\n.page-layout {\n  box-sizing: border-box;\n  height: 100vh;\n  width: 100vw;\n\n  padding-top: $top-bar-height;\n\n  .top-bar {\n    position: fixed;\n    top: 0px;\n    z-index: 3;\n  }\n\n  .content-container {\n    box-sizing: border-box;\n    height: 100%;\n    width: 100%;\n\n    position: relative;\n\n    padding-left: $left-bar-width;\n\n    .left-bar {\n      position: absolute;\n      left: 0px;\n      height: 100%;\n      z-index: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
