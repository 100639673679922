import React from 'react';
import { Button, Modal, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

interface ModalEndSubmitProps {
  visible: boolean;
  setVisible: Function;
  buttonAction?: {
    label?: string;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    hidden?: boolean;
  };
  modal: {
    title: string;
    subtitle?: React.ReactElement | null;
    description?: React.ReactElement | string;
    subdescription?: React.ReactElement;
  };
  buttonSubmit: React.ReactElement | Function;
  buttonCancel?: React.ReactElement | Function;

  icon: string;
  iconClassName?: string;
  className: string;
  textCheckbox?: string;
  isCheckBox?: boolean | false;
}

const ModalEndSubmit = ({
  visible,
  setVisible,
  modal,
  buttonAction,
  buttonSubmit,
  buttonCancel,
  icon,
  iconClassName,
  className,
  isCheckBox,
  textCheckbox
}: ModalEndSubmitProps) => {
  const showModal = () => {
    setVisible(true);
  };

  const handleModalOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <>
      {buttonAction && !buttonAction.hidden && (
        <Button
          className={`ModalEndSubmit_button-open-modal button ${
            buttonAction?.className || 'button-submit'
          }`}
          disabled={buttonAction.disabled}
          loading={buttonAction.loading}
          onClick={showModal}
        >
          {buttonAction?.label}
        </Button>
      )}
      <Modal
        open={visible}
        onOk={handleModalOk}
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.45)',
          backdropFilter: 'blur(2px)'
        }}
        onCancel={handleCancel}
        className={`modal-end-submit ${className}`}
      >
        <div className="modal-end-submit__content">
          <img className={iconClassName} alt="coin" src={icon} />
          <h2 className="h2">{modal.title}</h2>
          {modal.description}
          {modal.subtitle && (
            <div className="divider">
              {modal.subtitle}
              {modal.subdescription}
            </div>
          )}
          <div className="buttons">
            {typeof buttonSubmit === 'object' && buttonSubmit}
            {typeof buttonSubmit === 'function' &&
              buttonSubmit(() => setVisible(false))}
            {typeof buttonCancel === 'object' && buttonCancel}
            {typeof buttonCancel === 'function' &&
              buttonCancel(() => setVisible(false))}
          </div>
          {isCheckBox && (
            <Checkbox onChange={onChange}>{textCheckbox}</Checkbox>
          )}
        </div>
      </Modal>
    </>
  );
};
export default ModalEndSubmit;
