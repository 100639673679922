import { useMutation } from 'urql';
import { CreateEntityInput, CreateEntityMutation, UpdateEntityInput, UpdateEntityMutation } from 'gql/graphql';
import { MUTATION_CREATE_ENTITY, MUTATION_UPDATE_ENTITY } from './graphql';

export const useCreateEntity = (query?: any) => {
  const [results, execute] = useMutation<CreateEntityMutation>(query || MUTATION_CREATE_ENTITY);

  return {
    execute: (data: CreateEntityInput) =>
      execute({ createEntityInput: data }),
    data: results.data?.CreateEntity,
    ...results
  };
};

export const useUpdateEntity = (query?: any) => {
  const [results, execute] = useMutation<UpdateEntityMutation>(query || MUTATION_UPDATE_ENTITY);

  return {
    execute: (data: UpdateEntityInput) =>
      execute({ updateEntityInput: data }),
    data: results.data?.UpdateEntity,
    ...results
  };
};