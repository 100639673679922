import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import clockCircleSvg from 'assets/images/clock-background.svg';
import calendarPng from 'assets/images/calendar.png';
import errorSvg from 'assets/images/error-red.svg';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import { TaskContext } from '../../MinimumLiquidity';
import { Task, TaskResponse, TaskState } from '../../../../../gql/graphql';
import { useCreateResponseTask } from '../../../../../services/task/mutations';
import { File } from '../../../../../utils/interfaces/file.interface';
import InputUploadedFile from '../../../components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import ModalEndSubmit from '../../../components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import UploadDocs from '../../../components/Atoms/UploadDocs/UploadDocs';
import ActionAcceptAndReject from '../../../../ActionAcceptAndReject/ActionAcceptAndReject';

interface CardStateAccountProps {
  account: TaskResponse;
  state: string;
  label: React.ReactElement;
  subTitle: React.ReactElement;
  text: React.ReactElement | null;
}

const CardStateAccount = ({
  account,
  state,
  label,
  subTitle,
  text
}: CardStateAccountProps) => {
  const task: Task | any = useContext(TaskContext);
  const {
    data: dataCreateResponse,
    fetching,
    execute: createResponseTask
  } = useCreateResponseTask();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [numberOfFiles] = useState(1);

  useEffect(() => {
    if (dataCreateResponse) {
      setIsModalConfirm(false);
      setListFileUpload([]);
    }
  }, [dataCreateResponse]);

  const deleteFile = (file: string): void => {
    console.log('delete');
  };

  const seeFile = (file: File) => window.open(file.url, '_blank');

  const addFilesForReview = () => {
    if (task)
      createResponseTask({
        _id: account._id,
        key: account.key,
        label: account.label,
        step: 2,
        metadata: account.metadata,
        state: TaskState.Review,
        task: task?._id,
        files: listFileUpload
      });
  };

  return (
    <div className={`CardStateAccount CardStateAccount--${state}`}>
      <ActionAcceptAndReject
        taskResponseId={account._id}
        task={{ id: task._id, key: task.key, name: 'Proof of Liquidity' }}
        files={account?.files}
        minimumLiquidity={account}
        currentState={account.state}
      />
      {label}
      {(state === 'inReview' || state === 'approved') && (
        <div className="CardStateAccount_head">
          <div className="box">
            <h3 className="body-regular body-regular-w6">
              {account.metadata.nameAccount}
            </h3>
            {subTitle}
            <p className="body-card body-card-dark">
              $
              <span className="fz-14">
                {account.metadata.balance?.toLocaleString('en')}
              </span>
            </p>
          </div>
          <div className="box" style={{ width: 300 }}>
            {account.files?.filter(file => file.state.toUpperCase() !== TaskState.RequestError || state === 'inReview')
              .map((file, index) => (
                <InputUploadedFile
                  key={index}
                  file={file}
                  seeFile={seeFile}
                  state={file.state as TaskState}
                  icon={
                    file.state.toUpperCase() === TaskState.Approved
                      ? fileCheckSvg
                      : file.state.toUpperCase() === TaskState.Review
                        ? clockCircleSvg
                        : errorSvg
                  }
                />
              ))}
          </div>
        </div>
      )}
      {state === 'error' && (
        <div>
          <div className="row">
            {text}
            <div className="box"></div>
          </div>
          <div className="section-upload">
            <UploadDocs
              numberOfFiles={numberOfFiles}
              button={null}
              listFileUpload={listFileUpload}
              setListFileUpload={setListFileUpload}
            />
          </div>
          <div className="divider">
            <div className="row row-btw">
              <div className="box">
                <h3 className="body-regular body-regular-w6">
                  {account.metadata.nameAccount}
                </h3>
                {subTitle}
                <p className="body-card body-card-dark">
                  $
                  <span className="fz-14">
                    {account.metadata.balance?.toLocaleString('en')}
                  </span>
                </p>
              </div>
              <div className={`box`}>
                {account.files?.map((file, index) => (
                  <InputUploadedFile
                    key={index}
                    file={file}
                    seeFile={seeFile}
                    state={file.state as TaskState}
                    icon={
                      file.state.toUpperCase() === TaskState.Approved
                        ? fileCheckSvg
                        : file.state.toUpperCase() === TaskState.Review
                          ? clockCircleSvg
                          : errorSvg
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          {listFileUpload.length >= numberOfFiles && (
            <ModalEndSubmit
              visible={isModalConfirm}
              setVisible={setIsModalConfirm}
              buttonAction={{
                label: 'Submit for review',
                className: 'button-block'
              }}
              modal={{
                title: 'Ready to submit?',
                description: (
                  <h4 className="h4 h4-w4">
                    After submitting, you’ll have to contact us if you want to
                    make changes
                  </h4>
                )
              }}
              buttonSubmit={
                <Button
                  className="button button-modal-ok"
                  onClick={addFilesForReview}
                  loading={fetching}
                >
                  Yes, this is correct
                </Button>
              }
              buttonCancel={(actionCancel: any) => (
                <Button
                  className="button button-modal-cancel"
                  onClick={actionCancel}
                >
                  I want to make changes
                </Button>
              )}
              icon={calendarPng}
              className="modal-end-submit--white"
              isCheckBox={true}
              textCheckbox="Don’t show this message again for this task"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default CardStateAccount;
