import React from 'react';
import './PageLayout.scss';
import TopBar from '../TopBar/TopBar';
import LeftBar from '../LeftBar/LeftBar';

interface IPageLayoutProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<IPageLayoutProps> = (props) => {
  const { children } = props;

  return (
    <div className="page-layout">
      <TopBar />
      <div className='content-container'>
        <LeftBar />
        {children}
      </div>
    </div>
  );
};

export default PageLayout;