import coinPng from 'assets/images/coin-shadow.png';
import { Button } from 'antd';
import ModalEndSubmit from '../../components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

const ModalFinalReview = ({ submit, visible, setVisible }: any) => {
  return (
    <ModalEndSubmit
      visible={visible}
      setVisible={setVisible}
      modal={{
        title: 'We’ll review your docs',
        description: (
          <h4 className="h4 h4-w4">
            Check back in X days to see if your liquidity docs have been
            approved
          </h4>
        )
      }}
      className="modal-normal"
      icon={coinPng}
      buttonSubmit={
        <Button className="button button-modal-ok" onClick={() => setVisible(false)}>
          Continue submitting liquidity docs
        </Button>
      }
      buttonCancel={
        <Button className="button button-modal-cancel" onClick={submit}>
          Back to dashboard
        </Button>
      }
      isCheckBox={false}
      textCheckbox=""
    />
  );
};
export default ModalFinalReview;
