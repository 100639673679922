import React, { useContext, useEffect, useState } from 'react';
import { Button, InputNumber } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import coinPng from 'assets/images/coin-shadow.png';
import ModalFinish from './ModalFinish';
import { Task, TaskResponse, TaskState } from '../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../services/task/querys';
import { useCreateResponseTask } from '../../../services/task/mutations';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import StateTag from '../components/Atoms/Tags/StateTag/StateTag';
import { ExpandIcon } from '../BorrowerTasks';
import { formatterCurrency } from '../../../utils/formatters/formatter';
import SectionIssues from '../../SectionIssues/SectionIssues';
import CardUploadResponse from '../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import ActionAcceptAndReject from '../../ActionAcceptAndReject/ActionAcceptAndReject';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';
import AddAnotherDoc from '../components/Organisms/AddAnotherDoc/AddAnotherDoc';
import CardBorrowerImpersonation from '../../CardBorrowerImpersonation/CardBorrowerImpersonation';

interface ConstructionBudgetProps {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}
const ConstructionBudget = ({ task, visible, handlerClose }: ConstructionBudgetProps) => {

  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();

  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [budgetValue, setBudgetValue] = useState<any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])
  
  useEffect(() => {
    if (taskKey === 'construction_budget') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if(!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setTaskCompleted(_response?.state === TaskState.Approved);
    setIsReview(_response?.state === TaskState.Review);
    if (_response?.metadata) setBudgetValue(_response.metadata.budgetValue);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const submit = async (files: any) => {
    let payload: any = {
      key: 'construction-budget',
      label: 'Itemized construction budget/SoW',
      step: 1,
      metadata: {
        budgetValue
      },
      state: TaskState.Review,
      task: task?._id,
      files
    };

    if (response) payload._id = response._id;

    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
  };

  const taskHasResponses = taskResponses && taskResponses.length > 0;

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      header={{
        title: <span>APPROVAL | CONSTRUCTION BUDGET</span>,
        actionLeft:
          isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={coinPng} alt="icon" className="paperPng" />,
        actionRight: (
          <Button
            className="button button-transparent"
            onClick={() => onHelpDocs()}
          >
            <QuestionCircleOutlined />
            Help docs
          </Button>
        )
      }}
      animationDirection="onRight"
      dots={true}
      stepsTotal={1}
      currentStep={step}
      setStep={setStep}
      footer={
        <div className="text-footer mt-20">
          <p className="placeholder">
            My estimated construction budget is{' '}
            {(response?.metadata?.budgetValue || budgetValue) &&
              <span className="bold-label">
                {formatterCurrency(response?.metadata?.budgetValue || budgetValue)}
              </span>
            }
          </p>
        </div>
      }
    >
      <div className="children ConstructionBudget">
        <h2 className="h3 mt-5">
          We need to know what work you plan to do on your property to order an
          appraisal
        </h2>

        <CardBorrowerImpersonation hasResponses={taskHasResponses} />

        <SectionIssues task={task} />

        <h2 className="h2 mt-30">What’s your total construction budget?</h2>
        <div className="item">
          <InputNumber
            placeholder="150,000"
            value={budgetValue}
            controls={false}
            disabled={!!response?.metadata?.budgetValue}
            className="input input-number mt-15"
            onChange={(v) => setBudgetValue(v)}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '') as any}
          />
        </div>
        <div className="mt-15">
          <CardUploadResponse
            title="Itemized construction budget/SoW"
            description="Use our template or upload your own itemized list of your planned construction budget"
            isButtonTemplate={{ show: true, link: '' }}
            files={response?.files}
            state={response?.state}
            limitFiles={1}
            submit={{
              title: 'Submit for review',
              disabled: !budgetValue || response,
              loading: fetching,
              location: 'outside',
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => submit(files)
            }}
            insertHtml={
              <ActionAcceptAndReject
                taskResponseId={response?._id}
                task={{ id: task._id, key: task.key, name: task.name }}
                files={response?.files}
                currentState={response?.state}
              />
            }
          />
        </div>

        <AddAnotherDoc task={task} />

        {modalFinish &&
          <ModalFinish
            visible={modalFinish}
            setVisible={setModalFinish}
            closeTask={() => setModalTaskVisible(false)}
          />
        }
      </div>
    </ModalTask>
  );
};
export default ConstructionBudget;
