import React from 'react';
import iconEntities from 'assets/images/entities-icon.svg';
import './LeftBar.scss';
import { useNavigate } from 'react-router-dom';

interface ILeftBarProps {
  // define your props here
}

const LoansIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

const BorrowersIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

const LogOutIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M7.66342 20.9903H3.22114C2.63206 20.9903 2.0671 20.7563 1.65056 20.3397C1.23401 19.9232 1 19.3582 1 18.7691V3.22114C1 2.63206 1.23401 2.0671 1.65056 1.65056C2.0671 1.23401 2.63206 1 3.22114 1H7.66342" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.4412 16.5466L20.994 10.9938L15.4412 5.4409" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20.999 10.9954H7.67212" stroke="#929FB0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

const LeftBar: React.FC<ILeftBarProps> = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
    navigate('/');
  }

  return (
    <div className="left-bar">
      <div className='placeholder'></div>
      <div className='icon-container'>
        <div>
          <div className='partial-border'>
          </div>
        </div>
        <div className='icon loans' onClick={() => navigate('/loans')}>
          <LoansIcon />
          <div>
            Loans
          </div>
        </div>
        <div className='icon borrower' onClick={() => navigate('/borrowers')}>
          <BorrowersIcon/>
          <div>
            Borrowers
          </div>
        </div>
        <div>
          <div className='partial-border'>
          </div>
        </div>
      </div>
      <div className='user-icon-container'>
        <div className='icon logout' onClick={logout}>
          <LogOutIcon />
          <div>
            Log Out
          </div>
        </div>
        <div className='icon profile'>
          <BorrowersIcon />
          <div>
            Profile
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;