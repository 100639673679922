import React, { MouseEventHandler } from "react";
import { Button } from "antd";

import "./ConfirmationButton.scss";

export interface ConfirmationButtonProps {
  text: string;
  disabledText: string;
  isDisabled?: boolean;
  isFetching?: boolean;
  onClick: MouseEventHandler<any>;
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = (props) => {
  const { text, disabledText, isDisabled, isFetching, onClick, ...others } = props;

  const className = !isDisabled ?  "button button-submit" : "button button-grey"
  
  return (
    <Button
      className={className}
      onClick={onClick}
      loading={isFetching}
      disabled={isDisabled}
      {...others}
    >
      {
        !isDisabled ? text : disabledText
      }
    </Button>
  )
}

export default ConfirmationButton;