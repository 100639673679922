import { useMutation } from 'urql';
import { CreateUserReportInput, CreateUserReportMutation, UpdateUserInput, UpdateUserMutation } from 'gql/graphql';
import { MUTATION_CREATE_USER_REPORT, MUTATION_UPDATE_USER } from './graphql';

export const useUpdateUser = (query?: any) => {
  const [results, execute] = useMutation<UpdateUserMutation>(query || MUTATION_UPDATE_USER);

  return {
    execute: (data: UpdateUserInput) =>
      execute({ updateUserInput: data }),
    data: results.data?.UpdateUser,
    ...results
  };
};

export const useCreateUserReport = (query?: any) => {
  const [results, execute] = useMutation<CreateUserReportMutation>(query || MUTATION_CREATE_USER_REPORT);

  return {
    execute: (data: CreateUserReportInput) =>
      execute({ createUserReportInput: data }),
    data: results.data?.createUserReport,
    ...results
  };
};