import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../Purchase';
import ModalFinish from '../ModalFinish';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { Task, TaskResponse, TaskState } from '../../../../gql/graphql';
import { useCreateResponseTask } from '../../../../services/task/mutations';
import SectionIssues from '../../../SectionIssues/SectionIssues';
import CardUploadResponse from '../../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import ActionAcceptAndReject from '../../../ActionAcceptAndReject/ActionAcceptAndReject';
import AddAnotherDoc from '../../components/Organisms/AddAnotherDoc/AddAnotherDoc';

interface Step1Props {
  setStep: Function;
  closeTask: Function;
}
const Step1 = ({ setStep, closeTask }: Step1Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { execute: createResponseTask, fetching } = useCreateResponseTask();

  const [response, setResponse] = useState<TaskResponse | any>();
  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);

    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const submit = async (files: any) => {
    let payload: any = {
      key: 'purchase-doc',
      label: 'Signed purchase agreement',
      step: 1,
      metadata: {},
      state: TaskState.Review,
      task: task?._id,
      files
    };

    if (response) payload._id = response._id;

    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
  };

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          Upload a purchase agreement signed by both the buyer and the seller
        </h3>

        <SectionIssues task={task} />

        <div className="mt-60">

          <CardUploadResponse
            title="Signed purchase agreement"
            isButtonSample={{ show: true, link: '' }}
            files={response?.files}
            state={response?.state}
            limitFiles={1}
            submit={{
              title: 'Submit for review',
              disabled: response,
              loading: fetching,
              location: 'outside',
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => submit(files)
            }}
            insertHtml={
              <ActionAcceptAndReject
                taskResponseId={response?._id}
                task={{ id: task._id, key: task.key, name: task.name }}
                files={response?.files}
                currentState={response?.state}
              />
            }
          />
        </div>
      </div>

      <AddAnotherDoc task={task} />

      <ModalFinish
        visible={modalFinish}
        setVisible={setModalFinish}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step1;
