import { useQuery } from 'urql';
import { GET_USER_LOGGIN, GET_USER_REPORTS } from './graphql';
import { GetUserLogginQuery, GetUserReportsQuery, QueryGetUserReportsArgs, User } from 'gql/graphql';
import { UseQueryInterface } from '../../../utils/interfaces/use-query.interface';

export const useGetUserLoggin = (query?: any) => {
    const [{ data, fetching, error }] = useQuery<GetUserLogginQuery>({ query: query || GET_USER_LOGGIN });
    return { data: data?.GetUserLoggin, fetching, error };
}

export const useGetUserReports = (
    vars?: UseQueryInterface<QueryGetUserReportsArgs>
  ) => {
    const [{ data, ...others }, reexecute] = useQuery<GetUserReportsQuery>({
      query: vars?.query || GET_USER_REPORTS,
      ...vars
    });
    return {
      data: data?.GetUserReports,
      reexecute,
      ...others
    };
  };

