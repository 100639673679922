import React, { useContext, useEffect, useState } from 'react';
import errorSvg from 'assets/images/error-red.svg';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import { ModalTaskRightContext } from '../BorrowerTask/components/Organisms/Modals/ModalTask/ModalTask';
import { TaskState } from '../../gql/graphql';

interface Props {
  taskResponseId: string;
  task: { id: string, key: any, name: string };
  files?: any;
  minimumLiquidity?: any;
  currentState: string;
  rightStyle?: number;
}

const ActionAcceptAndReject = ({
  taskResponseId,
  task,
  files,
  minimumLiquidity,
  currentState,
  rightStyle
}: Props) => {
  const { setHandler }: any = useContext(ModalTaskRightContext);

  return (
    <div className={`action-accept-reject`} style={{ right: rightStyle || 15 }}>
      <div className='content-actions'>
        {currentState !== TaskState.Approved &&
          <div
            className="circle-input"
            onClick={() => setHandler(true, { view: 'approved', task, files, taskResponseId, minimumLiquidity })}
          >
            <img src={fileCheckSvg} alt="check" width={20} />
          </div>
        }
        {currentState !== TaskState.Approved &&
          <div
            className="circle-input"
            onClick={() => setHandler(true, { view: 'rejection', task, files, taskResponseId, minimumLiquidity })}
          >
            <img src={errorSvg} alt="error" width={20} />
          </div>
        }
      </div>
    </div>
  );
};
export default ActionAcceptAndReject;
