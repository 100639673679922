import { useContext, useState } from 'react';

import leafPng from 'assets/images/leaf.png';
import ModalTask from '../../../../../components/BorrowerTask/components/Organisms/Modals/ModalTask/ModalTask';
import { LoanContext } from '../LoanDetails';
import './AddNewTask.scss';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import InputBorderNone from '../../../../../components/BorrowerTask/components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import SelectSection from '../../../../../components/ApprovalRejectSection/components/SelectSection/SelectSection';
import CardUploadResponse from '../../../../../components/BorrowerTask/components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { useCreateNewTask } from '../../../../../services/loan/mutations';

const difficulties = [
  { value: 'major_task', label: 'Major task' },
  { value: 'quick_task', label: 'Quick task' },
]

const loanPhases = [
  { value: 'approval', label: 'Approval Tasks' },
  { value: 'due-diligence', label: 'Due Diligence Tasks' },
  { value: 'final', label: 'Final Approval' },
]

interface Props {
}

const AddNewTask = ({ }: Props) => {
  const [form] = useForm();
  const loanSelected: any = useContext(LoanContext);

  const { execute: createNewTask, fetching } = useCreateNewTask();

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [files, setFiles] = useState<any>();

  const onFinish = async (values: any) => {
    await createNewTask({
      ...values,
      loan: loanSelected._id,
      files
    });
    form.resetFields();
    setFiles(null);
  }

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      renderButtonTrigger={(openModal: any) => (
        <div className='action-button' onClick={() => openModal(true)} style={{ cursor: 'pointer' }}>
          Add new borrower task
        </div>
      )}
      header={{
        title: <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastName} | {loanSelected.name}</span>,
        actionLeft: (<img src={leafPng} alt="icon" className="paperPng" />)
      }}
      animationDirection="onRight"
      dots={false}
      stepsTotal={1}
      currentStep={1}
      setStep={() => { }}
      footer={
        <></>
      }
    >
      <div className='AddNewTasks'>
        <section>
          <h2 className="h2">
            Create a task for{' '}
            <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastName} {loanSelected.type}</span>
            {' '}loan in {loanSelected.name}
          </h2>

          <Form
            form={form}
            name="basic"
            className='form'
            autoComplete="off"
            onFinish={(values) => onFinish(values, )}
          >
            <Form.Item
              name="name"
              className='form-item-task-name'
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your task name!'
                }
              ]}
            >
              <InputBorderNone
                placeholder="Enter task name"
                className="form-task-name"
              />
            </Form.Item>

            <Form.Item
              name="description"
              style={{ marginBottom: 15 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your task description!'
                }
              ]}
            >
              <InputBorderNone
                placeholder="Leave a note for the borrower that will show up in the task preview"
                className="form-doc-description"
              />
            </Form.Item>

            <Form.Item
              name="phase"
              rules={[
                {
                  required: true,
                  message: 'Please select your loan phase!'
                }
              ]}
            >
              <SelectSection
                label="Loan phase"
                placeholder="Select loan phase"
                options={loanPhases}
                onSelect={(select) => form.setFieldValue('phase', select)}
              />
            </Form.Item>

            <Form.Item
              name="difficulty"
              rules={[
                {
                  required: true,
                  message: 'Please select your difficulty!'
                }
              ]}
            >
              <SelectSection
                label="Difficulty"
                placeholder="Select difficulty"
                options={difficulties}
                onSelect={(select) => form.setFieldValue('difficulty', select)}
              />
            </Form.Item>

            <CardUploadResponse
              title="Docs"
              titleLocation='outside'
              limitFiles={1}
              onDeleteFile={!files}
              submit={{
                title: 'Add to task',
                location: 'outside',
                hidden: true,
                loading: false,
                onClick: (files) => { }
              }}
              onChangeFiles={(files: any) => setFiles(files)}
              insertHtml={
                <div className='doc-info'>
                  <Form.Item
                    name="docName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your document name!'
                      }
                    ]}
                  >
                    <InputBorderNone
                      placeholder="Enter doc name here"
                      className="form-doc-name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="docDescription"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your description!'
                      }
                    ]}
                  >
                    <InputBorderNone
                      placeholder="Write a note for the borrower about this document"
                      className="form-doc-description"
                    />
                  </Form.Item>
                </div>
              }
            />

            <Button
              className='mt-10 button button-submit'
              htmlType='submit'
              loading={fetching}
              disabled={fetching}
            >
              Create
            </Button>
          </Form>
        </section>
      </div>
    </ModalTask>
  );
};
export default AddNewTask;
