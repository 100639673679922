import { gql } from "urql";

export const MUTATION_CREATE_ENTITY: any = gql(`
    mutation CreateEntity($createEntityInput: CreateEntityInput!) {
        CreateEntity(createEntityInput: $createEntityInput) {
            _id
            name
        }
    }
`)

export const MUTATION_UPDATE_ENTITY: any = gql(`
    mutation UpdateEntity($updateEntityInput: UpdateEntityInput!) {
        UpdateEntity(updateEntityInput: $updateEntityInput) {
            _id
            name
        }
    }
`)