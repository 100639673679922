import React from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import fileSvg from 'assets/images/file.svg';
import deleteSvg from 'assets/images/delete.svg';
import { TaskState } from '../../../../../../gql/graphql';
import { File } from '../../../../../../utils/interfaces/file.interface';

interface InputUploadedFileProps {
  file: File;
  seeFile?: Function;
  deleteFile?: Function;
  loadingDeleteFile?: boolean;
  icon?: string;
  state?: TaskState | undefined;
  isActionSeeFile?: boolean;
}

const InputUploadedFile = ({
  file,
  seeFile,
  deleteFile,
  loadingDeleteFile,
  icon,
  state,
  isActionSeeFile
}: InputUploadedFileProps) => {
  const _seeFile = (file: File) => window.open(file.url, '_blank');

  return (
    <div className="InputUploadedFile">
      <div className="file-ok">
        <div className="file" style={{ paddingLeft: icon ? 7 : 0 }}>
          {icon && (
            <div className="circle-input">
              <img src={icon} alt="upload" className="upload" />
            </div>
          )}
          <div className={`file-content file-content-${state}`}>
            <div className="flex-btw">
              <div className="file-name">
                <img className="fileIcon" alt="fileIcon" src={fileSvg} />
                <span className="name">{file?.name}</span>
              </div>
              <div className="content-buttons">
                {seeFile && (
                  <Button
                    className="button button-transparent"
                    onClick={() => seeFile(file)}
                  >
                    <EyeOutlined />
                  </Button>
                )}
                {isActionSeeFile && (
                  <Button
                    className="button button-transparent"
                    onClick={() => _seeFile(file)}
                  >
                    <EyeOutlined />
                  </Button>
                )}
                {deleteFile && (
                  <Button
                    className="button button-transparent"
                    loading={loadingDeleteFile}
                    onClick={() => deleteFile(file)}
                  >
                    <img
                      src={deleteSvg}
                      alt="delete"
                      className="delete"
                      width="15"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputUploadedFile;
