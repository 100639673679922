import { OperationContext, useMutation } from 'urql';
import {
  APPROVED_RESPONSE,
  CREATE_REJECT_RESPONSE,
  CREATE_RESPONSE_TASK,
  DELETE_FILE_TASK_RESPONSE,
  DELETE_TASK,
  REMOVE_TASK_RESPONSE
} from './graphql';
import { 
  ApprovedResponseInput,
  ApprovedResponseMutation,
  ApprovedResponseMutationVariables,
  CreateRejectResponseCommentMutation,
  CreateResponseTaskInput,
  CreateResponseTaskMutation,
  DeleteFileTaskResponseMutation,
  DeleteTaskMutation,
  MutationApprovedResponseArgs,
  MutationCreateRejectResponseCommentArgs,
  MutationDeleteFileTaskResponseArgs,
  MutationDeleteTaskArgs,
  MutationRemoveTaskResponseArgs,
  RejectResponseInput,
  RemoveTaskResponseMutation
} from '../../../gql/graphql';

export const useCreateResponseTask = (query?: any) => {
  const [{ data, fetching, error }, execute] = useMutation<CreateResponseTaskMutation>(
    query || CREATE_RESPONSE_TASK
  );
  return {
    execute: (data: CreateResponseTaskInput) =>
      execute({ createResponseTaskInput: data }),
    data: data?.createResponseTask,
    fetching,
    error
  };
};

export const useDeleteFileResponse = (query?: any) => {
  const [results, execute] = useMutation<DeleteFileTaskResponseMutation>(
    query || DELETE_FILE_TASK_RESPONSE
  );

  return {
    execute: (data: MutationDeleteFileTaskResponseArgs) => execute(data),
    data: results.data?.DeleteFileTaskResponse,
    ...results
  };
};

export const useRemoveTaskResponse = (query?: any) => {
  const [results, execute] = useMutation<RemoveTaskResponseMutation>(
    query || REMOVE_TASK_RESPONSE
  );

  return {
    execute: (data: MutationRemoveTaskResponseArgs) => execute(data),
    data: results.data?.RemoveTaskResponse,
    ...results
  };
};

export const useCreateRejectResponse = (query?: any) => {
  const [results, execute] = useMutation<CreateRejectResponseCommentMutation>(
    query || CREATE_REJECT_RESPONSE
  );

  return {
    execute: (data: RejectResponseInput) => 
      execute(data ? { rejectResponseInput: data } : undefined),
    data: results.data?.CreateRejectResponseComment,
    ...results
  };
};

export const useApprovedResponse = (query?: any) => {
  const [results, execute] = useMutation<ApprovedResponseMutation>(
    query || APPROVED_RESPONSE
  );

  return {
    execute: (data: ApprovedResponseInput) => 
      execute(data ? { approvedResponseInput: data } : undefined),
    data: results.data?.ApprovedResponse,
    ...results
  };
};

export const useDeleteTask = (query?: any) => {
  const [results, execute] = useMutation<DeleteTaskMutation>(
    query || DELETE_TASK
  );

  return {
    execute: (data: MutationDeleteTaskArgs) => execute(data),
    data: results.data?.DeleteTask,
    ...results
  };
};

