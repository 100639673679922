import React, { useEffect, useState } from "react"
import { Divider, Select, Space } from "antd";

interface SelectSectionProps {
  label: string,
  placeholder: string,
  options: string[] | { value: string, label: string }[] | any,
  onSelect: (option: string[]) => void,
  isMultiple?: boolean,
  value?: any,
  disabled?: boolean,
  optionEnd?: { label: string, onClick: any }
}

const SelectSection: React.FC<SelectSectionProps> = ({
  label,
  placeholder,
  options,
  onSelect,
  isMultiple,
  value,
  disabled,
  optionEnd
}) => {

  const [isValue, setValue] = useState<any>();
  
  useEffect(() => {
    setValue(value);
  }, [value])
  
  const onChange = (e: any) => {
    onSelect(e);
    setValue(e);
  }

  return (
    <div className={`select-section ${isValue ? 'select-section-selected' : ''}`}>
      <span>{label}</span>
      {isMultiple &&
        <Select
          placeholder={placeholder}
          options={options.map((o: any) => ({ value: o, label: o }))}
          onChange={onChange}
          showSearch={false}
          mode={"multiple"}
          style={{ width: "100%" }}
          value={value}
          disabled={disabled}
          maxTagCount={'responsive'}
          maxTagTextLength={20}
          tagRender={(props) => (
            <span style={{ marginTop: 2, color: '#355C7D' }}>
              {isValue && isValue[0] !== props.value && ', '}{props.label}
            </span>
          )}
          optionRender={(option) => (
            <Space align="center">
              <div
                className="circle"
                style={{
                  background: isValue?.includes(option.value) ? '#5E8EB7' : 'white',
                  borderColor: isValue?.includes(option.value) ? '#5E8EB7' : '#CCD3DB'
                }}
              />
              <span>{option.value}</span>
            </Space>
          )}
          dropdownRender={(menu) => (
            <>
              {menu}
              {optionEnd &&
                <>
                  <Divider style={{ margin: '0 0 8px' }} />
                  <Space style={{ padding: '0 8px 4px', display: 'block' }}>
                    <span style={{ cursor: 'pointer', display: 'block' }} onClick={optionEnd.onClick}>
                      {optionEnd.label}
                    </span>
                  </Space>
                </>
              }
            </>
          )}
        />
      }

      {!isMultiple &&
        <Select
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      }
    </div>
  )
};

export default SelectSection;