import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import RejectSection from '../../../../../../ApprovalRejectSection/RejectSection';
import ApprovalSection from '../../../../../../ApprovalRejectSection/ApprovalSection';
import { LoanDetailContext } from '../../../../../../../scenes/Private/Private';

interface Props {
	params: any;
	close: any;
}

const rejectionCode = [
	{ value: 'wrong_document', label: 'Wrong document' },
	{ value: 'additional_documentation', label: 'Additional documentation needed' },
	{ value: 'issue_within_document', label: 'Issue within document' },
	{ value: 'no_action_required', label: 'Informational: No action required from borrower' },
	{ value: 'information_incorrect', label: 'Information is incorrect' },
	{ value: 'multiple_issues', label: 'Multiple issues' },
	{ value: 'other', label: 'Other' }
];

const SectionRight = ({ params, close }: Props) => {
	const { loan: loanSelected }: any = useContext(LoanDetailContext);
	
	return (
		<div className='ModalTask-content-right'>

			<Button type="link" className="button-close" onClick={close}>
				X
			</Button>

			{params.view === 'rejection' &&
				<RejectSection
					taskResponseId={params.taskResponseId}
					borrowerName={loanSelected?.owner?.name}
					taskName={params.task?.name}
					taskId={params.task?.id}
					dataOrDocuments={params.files}
					rejectionOptions={rejectionCode}
					minimumLiquidity={params.minimumLiquidity}
					onSubmit={close}
					issue={params.issue}
				/>
			}

			{params.view === 'approved' &&
				<ApprovalSection
					taskResponseId={params.taskResponseId}
					taskId={params.task?.id}
					borrowerName={loanSelected?.owner?.name}
					taskName={params.task?.label}
					dataOrDocuments={params.files}
					minimumLiquidity={params.minimumLiquidity}
					onSubmit={close}
				/>
			}
		</div>
	);
};

export default SectionRight;
