import homeWhiteSvg from 'assets/images/home-white.svg';

export enum Phases {
  PRE_APPROVAL = 'pre-approval',
  APPROVAL = 'approval',
  DUE_DILIGENCE = 'due-diligence',
  FINAL = 'final',
  CLOSING = 'closing',
}

export const PhasesLabel: any = {
  'pre-approval': 'PRE APPROVAL',
  'approval': 'APPROVAL',
  'due-diligence': 'DUE DILIGENCE',
  'final': 'FINAL',
  'closing': 'CLOSING'
}

export interface LoanPhases {
  id: number;
  name: string;
  key: string;
  state?: string;
  stateName?: string;
  text: string;
  tasksItems: any[];
}

export const LOAN_PHASES: LoanPhases[] = [
  {
    id: 1,
    name: 'Pre-Approval',
    key: Phases.PRE_APPROVAL,
    text: 'Answer a few questions to get pre-approved for a loan',
    tasksItems: [
      {
        id: 1,
        icon: homeWhiteSvg,
        key: 'pre_approval_letter',
        text: 'Pre-approval letter',
        titleButton: 'View',
      }
    ]
  },
  {
    id: 2,
    name: 'Approval Docs & Term Sheet',
    key: Phases.APPROVAL,
    text: 'Submit a full application including required docs, which we’ll review',
    tasksItems: []
  },
  {
    id: 3,
    name: 'Property Evaluation & Due Diligence',
    key: Phases.DUE_DILIGENCE,
    text: 'Submit any remaining docs, while we conduct due diligence to verify your info',
    tasksItems: []
  },
  {
    id: 4,
    name: 'Cleared to Close',
    key: Phases.FINAL,
    text: 'After any last minute items are complete, your loan is approved and you get your loan agreement',
    tasksItems: []
  },
  {
    id: 5,
    name: 'Closing',
    key: Phases.CLOSING,
    text: 'Loan agreement signed, we deposit your funds in your account within X days',
    tasksItems: []
  }
];
